import { addDays } from 'date-fns';
import React from 'react';
import { connect } from 'react-redux';
import { Actions } from '../../store/actions/organisationActions';
import { RowBtn } from '../../styledComponents/GlobalStyle';
import { capitalizeFirstLetter } from '../utils/GlobalFunctions';

const UsersListRow = ({ userDetails, toggleModal, reinviteUserBtn, adminUser }) => {
  return (
    <tr id='users-list-row' style={{ display: userDetails.enabled ? 'table-row' : 'none' }}>
      <td width='20%' style={{ textAlign: 'left' }}>
        {userDetails.name}
      </td>
      <td width='20%'>{userDetails.surname}</td>
      <td width='20%'>{userDetails.email}</td>
      {adminUser && <td width='20%'>{capitalizeFirstLetter(userDetails.status?.toLowerCase().replaceAll('_', ' '))}</td>}
      <td>
        <i onClick={() => toggleModal(true, userDetails, 'disableUser')} className='fa fa-trash-o fa-lg' />
        {reinviteUserBtn && (
          <RowBtn style={{ marginLeft: 20 }} to={'#'} onClick={() => toggleModal(true, userDetails, 'reinviteUser')}>
            <i className='fa-regular fa-paper-plane' style={{ marginRight: 5 }} />
            Re-Invite
          </RowBtn>
        )}
      </td>
    </tr>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { userDetails, adminUser } = ownProps;
  const today = new Date().toISOString();
  const expiryDate = addDays(new Date(userDetails?.createdDate), 7)?.toISOString();
  const isExpired = today > expiryDate;

  // Show reinvite button just for admin users and if user is expired and status is FORCE_CHANGE_PASSWORD.
  const reinviteUserBtn = adminUser && userDetails.status === 'FORCE_CHANGE_PASSWORD' && isExpired;
  return { reinviteUserBtn };
};

const mapDispatchToProps = (dispatch) => ({
  toggleModal: (status, item, action) => dispatch(Actions.toggleModal(status, item, action)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UsersListRow);
