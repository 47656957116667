import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsTreemap from 'highcharts/modules/treemap';
import _ from 'lodash';

// Initialize the treemap module
HighchartsTreemap(Highcharts);

const TreeMapChart = ({ colors, fromYear, toYear, data }) => {
  const [filteredData, setFilteredData] = useState([]);

  var getNode = function (name, value) {
    return {
      name: name + ': <br>' + value.toFixed(2) + ' tCO2e',
      show: name,
      value: Math.max(0.45, Math.cbrt(value)),
      realValue: value,
    };
  };

  let chartData = data?.map((item) => {
    return getNode(item.name, item.total);
  });

  chartData = _.sortBy(chartData, ['realValue'])?.reverse();

  //add colors to the data using the colors array starting from index 0
  chartData?.forEach((item, index) => {
    item.color = colors[index];
  });

  const getMaxDataPoints = () => {
    const screenWidth = window.innerWidth;

    if (screenWidth < 600) return 4;
    if (screenWidth < 1000) return 7;
    return 9; //data.length;
  };

  const createOtherDataPoint = (remainingData) => {
    const totalValue = remainingData?.reduce((sum, item) => sum + parseFloat(item.realValue), 0);
    return {
      name: 'Other: <br>' + totalValue?.toFixed(2) + ' tCO2e',
      show: 'Other',
      value: 0.44, // Ensure it's the smallest block
      realValue: totalValue,
      color: '#ccc', // Assign a color to the "Other" block
    };
  };

  const updateFilteredData = () => {
    const maxDataPoints = getMaxDataPoints();
    const displayedData = chartData?.slice(0, maxDataPoints);
    const remainingData = chartData?.slice(maxDataPoints);

    if (remainingData.length > 0) {
      displayedData.push(createOtherDataPoint(remainingData));
    }

    setFilteredData(displayedData);
  };

  useEffect(() => {
    updateFilteredData(); // Initial call to set the data

    const handleResize = () => {
      updateFilteredData();
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [data]);

  const options = {
    credits: false,
    chart: {
      height: 550,
      credits: false,
      borderRadius: 15,
      spacing: [20, 20, 20, 20],
    },
    series: [
      {
        type: 'treemap',
        layoutAlgorithm: 'squarified',
        data: filteredData,
        borderRadius: '5px',
        dataLabels: {
          //useHTML: true,
          style: {
            textOutline: 'none', // Remove text outline
            fontSize: '12px',
            fontFamily: 'Open Sans',
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textOverflow: 'ellipsis',
            align: 'center',
            verticalAlign: 'middle',
          },
        },
      },
    ],
    title: {
      text:
        fromYear && toYear && fromYear != toYear
          ? `${fromYear}-${toYear} Period Carbon Emissions By Activity`
          : `${fromYear} Carbon Emissions by Activity`,
      style: {
        fontSize: '18px',
        fontFamily: 'Open Sans',
        fontWeight: '600',
        color: '#130834',
      },
    },
    tooltip: {
      enabled: true,
      useHTML: true,
      shadow: false,
      borderWidth: 1,
      borderColor: '#777',
      style: {
        zIndex: 100,
        fontSize: '12px',
      },
      //pointFormat: '<b>{point.show}</b>: <b>{point.value}</b> tCO2e',
      formatter: function () {
        return `<p><b>${this.point.show}: <b>${this.point.realValue?.toFixed(2)}</b> tCO2e</p>`;
      },
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 800,
          },
          chartOptions: {
            chart: {
              height: 500,
            },
          },
        },
        {
          condition: {
            maxWidth: 600,
          },
          chartOptions: {
            chart: {
              height: 450,
            },
          },
        },
      ],
    },
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default TreeMapChart;
