import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Actions as FACILITYACTIONS } from '../../store/actions/facilityActions';
import { Actions as USAGEACTIONS } from '../../store/actions/usageActions';
import { AddButton, ImportLinks, ImportUsageData, UploadFileBtn } from '../../styledComponents/GlobalStyle';
import FacilitiesImportModal from './FacilitiesImportModal';
import * as Sentry from '@sentry/react';

let remove = false;
export const handleFileRemove = () => {
  remove = true;
};

const UploadFacilityData = ({
  getUploadLink,
  showFacilityImportModal,
  facilityImportModal,
  processFacilitiesUpload,
  processingFacilityImport,
  facilityAnalysisImportModal,
  importKey,
}) => {
  const [file, setFile] = useState(null);
  const [inputDisabled, setInputDisabled] = useState(false);
  const isInputDisabled = file || inputDisabled;

  if (remove) {
    setFile(null);
    remove = false;
  }
  const [key, setKey] = useState(null);

  useEffect(() => {
    if (importKey) {
      setKey(importKey);
    }
  }, [importKey]);

  const selectFile = async (e) => {
    const newFile = e.target.files && e.target.files[0];
    setFile(newFile);
    getUploadLink(newFile);
    e.target.value = '';
  };

  const scope = Sentry.getCurrentScope();
  scope.clearAttachments();
  scope.addAttachment({
    filename: 'TemplateKey.txt',
    data: String(key),
  });

  if (!facilityImportModal) return null;

  const closeModal = () => {
    showFacilityImportModal(false);
    setFile(null);
    importKey = undefined;
  };

  const handleFileRemove = () => {
    setInputDisabled(true); // disable the input
    setFile(null);
    importKey = undefined;
    setTimeout(() => {
      setInputDisabled(false); // re-enable the input
    }, 10); // wait for a brief delay
  };

  return (
    <div className='modal' style={{ display: 'block', background: 'rgba(0,0,0,.5)' }}>
      <div className='modal-dialog' style={{ height: '90vh', overflow: 'auto', fontSize: '12px' }}>
        <div className='modal-content'>
          <div className='modal-header'>
            <button onClick={() => closeModal()} className='close'>
              x
            </button>
            <h4 className='modal-title'>Import Facilities</h4>
          </div>
          <div className='modal-body'>
            <div className='form-group'>
              <div id='import-link' style={{ display: 'flex', placeItems: 'center' }}>
                <ImportUsageData>
                  <strong>Download template:</strong>
                </ImportUsageData>

                <ImportLinks
                  id='import-link-excel'
                  href={`https://s3.eu-west-2.amazonaws.com/downloads.compareyourfootprint.com/facilities_template.xlsx`}
                  className='btn'
                  type='button'>
                  Excel
                </ImportLinks>
              </div>
              <div id='file-name'>
                <strong>File name: </strong>
                <span>{file?.name}</span>
              </div>
            </div>

            <div className='row block buttons'>
              <div className='col-sm-3 col-sm-offset-3'>
                {file ? (
                  <UploadFileBtn
                    htmlFor='import_input'
                    className='custom-file-upload btn btn-success'
                    onClick={handleFileRemove}>
                    <i className='fa fa-trash' />
                    &nbsp;
                    <span>Remove File</span>
                  </UploadFileBtn>
                ) : (
                  <UploadFileBtn
                    htmlFor='import_input'
                    className='custom-file-upload btn btn-success'
                    id='Facility-Modal-uploadButton'>
                    <i className='fa fa-cloud-upload' />
                    &nbsp;
                    <span>Upload File</span>
                  </UploadFileBtn>
                )}
                <input
                  id='import_input'
                  disabled={isInputDisabled}
                  type='file'
                  accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                  onChange={selectFile}
                />
              </div>
              <div className='col-sm-4'>
                <AddButton
                  disabled={!file || importKey === undefined}
                  onClick={() => {
                    processFacilitiesUpload(file);
                  }}
                  className='btn btn-success'
                  id='Facility-Modal-startImportButton'>
                  {processingFacilityImport && <i className='fa fa-spin fa-spinner icon-spinner' />}
                  Start Import
                </AddButton>
              </div>
              <div className='clearfix'></div>
            </div>
          </div>
        </div>
      </div>
      {facilityAnalysisImportModal && <FacilitiesImportModal />}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    facilityImportModal: state.facility?.facilityImportModal,
    processingFacilityImport: state.facility?.processingFacilityImport,
    facilityAnalysisImportModal: state.facility.facilityAnalysisImportModal,
    importKey: state.importUploadKey,
  };
};

const mapDispatchToProps = (dispatch) => ({
  processFacilitiesUpload: (file) => dispatch(FACILITYACTIONS.processFacilitiesUpload(file)),
  getUploadLink: (file) => dispatch(USAGEACTIONS.getUploadLink(file)),
  showFacilityImportModal: (status) => dispatch(FACILITYACTIONS.showFacilityImportModal(status)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadFacilityData, handleFileRemove);
