import React, { useEffect, useRef } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';

// Initialize exporting modules
HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);

/* (function (H) { //Function Responsible for having a scrollable legend
  const { defined } = H;

  H.addEvent(H.Legend, 'afterRender', function () {
    const legend = this,
      chart = legend.chart,
      { custom, useHTML, layout } = legend.options,
      isHorizontal = layout === 'horizontal';

    if (defined(custom) && defined(custom.scrollableLegendArea) && useHTML && legend.group.div) {
      const { minHeight, minWidth } = custom.scrollableLegendArea;

      if (!legend.legendWrapper) {
        // Create additional SVG element to put inside additional div
        // after first render
        legend.legendWrapper = chart.renderer.createElement('svg').attr({
          version: '1.1',
          class: 'highcharts-scrollable-legend',
          height: legend.legendHeight,
          width: isHorizontal ? legend.contentGroup.getBox().width : legend.legendWidth,
        });
      }
      const { element } = legend.legendWrapper;
      // Move legend group to the new SVG element
      legend.group.add(legend.legendWrapper);

      // Add SVG element to div
      legend.group.div.appendChild(element);

      // Add style to use native browser scrollbar
      legend.group.div.style.overflow = 'auto';

      /* if (minHeight) {
        legend.group.div.style.height = minHeight + 'px';
        // Overwrite legend's height
        legend.legendHeight = minHeight;
      }
      if (minWidth) {
        legend.group.div.style.width = minWidth + 'px';
        // Overwrite legend's width
        legend.legendWidth = minWidth;
      } 

      const chartWidth = chart.chartWidth;
      const chartHeight = chart.chartHeight;
      const plotBox = chart.plotBox;
      const availableHeight = chartHeight - plotBox.y * 2;

      //      legend.group.div.style.width = Math.min(minWidth || availableWidth, availableWidth) + 'px';
      legend.group.div.style.height = Math.min(minHeight || availableHeight, availableHeight) + 'px';

      //    legend.legendWidth = parseInt(legend.group.div.style.width, 10);
      legend.legendHeight = parseInt(legend.group.div.style.height, 10);

      legend.align();
      legend.group.element.removeAttribute('transform');
    }
  });
})(Highcharts); */

const DonutChart = ({ fromYear, toYear, data }) => {
  const chartRef = useRef(null);
  const chartData = [
    {
      name: 'Scope 1',
      y: data?.totalScope1,
      color: '#33C2EA',
    },
    {
      name: 'Scope 2',
      y: data?.totalScope2,
      color: '#1F9B38',
    },
    {
      name: 'Scope 3',
      y: data?.totalScope3,
      color: '#130834',
    },
  ];

  const updateCenterLabel = (chart) => {
    const series = chart.series[0];
    const total = series.data.reduce((sum, point) => (point.visible ? sum + point.y : sum), 0).toFixed(2);
    let customLabel = chart.options.chart.custom.label;

    if (!customLabel) {
      customLabel = chart.options.chart.custom.label = chart.renderer
        .label(`<span style="font-size: 1.8em;"><strong>${total}</strong></span><br/><span>Total tCO2e</span>`)
        .css({
          color: '#000',
          textAnchor: 'middle',
          fontFamily: 'Open Sans',
        })
        .add();
    } else {
      customLabel.attr({
        text: `<span style="font-size: 1.8em;"><strong>${total}</strong></span><br/><span>Total tCO2e</span>`,
      });
    }

    const x = series.center[0] + chart.plotLeft;
    const y = series.center[1] + chart.plotTop - customLabel.attr('height') / 2;

    customLabel.attr({ x, y });
    customLabel.css({ fontSize: `${series.center[2] / 13}px` });
  };

  const options = {
    credits: false,
    chart: {
      type: 'pie',
      height: 450,
      borderRadius: 15,
      spacing: [20, 20, 20, 20],
      custom: {},
      events: {
        render() {
          const chart = this,
            series = chart.series[0];
          let customLabel = chart.options.chart.custom.label;

          if (!customLabel) {
            customLabel = chart.options.chart.custom.label = chart.renderer
              .label(`<span style="font-size: 1.8em;" ><strong>${series.total.toFixed(2)}</strong></span><br/>Total tCO2e`)
              .css({
                color: '#000',
                textAnchor: 'middle',
                fontFamily: 'Roboto, sans-serif',
              })
              .add();
          }

          const x = series.center[0] + chart.plotLeft,
            y = series.center[1] + chart.plotTop - customLabel.attr('height') / 2;

          customLabel.attr({
            x,
            y,
          });
          // Set font size based on chart diameter
          customLabel.css({
            fontSize: `${series.center[2] / 13}px`,
          });
        },
        redraw: function () {
          const chart = this;
          updateCenterLabel(chart);
        },
      },
    },
    title: {
      text:
        fromYear && toYear && fromYear != toYear
          ? `${fromYear}-${toYear} Period Carbon Emissions By Scope`
          : `${fromYear} Carbon Emissions by Scope`,
      style: {
        fontSize: '18px',
        fontFamily: 'Open Sans',
        fontWeight: '600',
        color: '#130834',
      },
    },
    plotOptions: {
      pie: {
        innerSize: '90%',
        depth: 0,
        dataLabels: {
          enabled: false,
          format: '{point.percentage:.1f} %',
          style: {
            fontSize: '12px', // Adjust font size here
          },
        },
        borderRadius: '15px',
        showInLegend: true,
      },
    },
    legend: {
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'middle',
      x: -5,
      y: 10,
      labelFormatter: function () {
        return (
          this.name +
          `<br/><span style="margin-top: 4;"><strong>${this.percentage?.toFixed(2)}% (${this.y?.toFixed(
            2
          )} tCO2e)</strong></span>`
        );
      },
      useHTML: true,
      custom: {
        scrollableLegendArea: {
          //minHeight: 250,
        },
      },
      navigation: {
        enabled: false, // Disable legend pagination
      },
      squareSymbol: false,
      symbolPadding: 5,
      symbolWidth: 18,
      symbolHeight: 4,
      symbolRadius: 0,
    },
    tooltip: {
      useHTML: true,
      formatter: function () {
        return `
        <strong>${this.point.name}</strong><br>
        <span style="font-size: 12px;"><span style="color:${this.color};">\u25CF</span> ${this.series.name}: 
        <strong>${this.point.y.toFixed(2)}</strong> tCO2e
      `;
      },
    },
    series: [
      {
        name: 'Emissions',
        data: chartData,
      },
    ],
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 600,
          },
          chartOptions: {
            legend: {
              layout: 'horizontal',
              align: 'center',
              verticalAlign: 'bottom',
            },
          },
        },
      ],
    },
    exporting: {
      enabled: true,
    },
  };

  return <HighchartsReact highcharts={Highcharts} options={options} ref={chartRef} />;
};

export default DonutChart;
