import React, { useRef } from 'react';
import { connect } from 'react-redux';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import HCMore from 'highcharts/highcharts-more';

// Initialize the 3D and More modules
HCMore(Highcharts);

const TotalGreenhouseEmissionsByScopeChart = ({ chartOptions, hasChartData }) => {
  const ref = useRef(null);
  if (!chartOptions || !hasChartData) {
    return null;
  }

  return (
    <div style={{ width: '100%' }}>
      <HighchartsReact ref={ref} highcharts={Highcharts} options={chartOptions} />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  // console.log('consumptionData',consumptionData)
  const year = state.report.details.year;
  const brandingColors = state.branding?.colors || {};
  const currentOrganisation = state.currentOrganisation;

  const { totalEmissionsByScope, withMarketFactors, filter } = state.consumption;

  const { subentities, entities, facility, metric } = state;
  const childOrgs = state.report?.includedOrgs || [];

  const orgs = [currentOrganisation, ...childOrgs];

  const data = {};
  const isMarket = withMarketFactors && !filter.includes('market');

  const years = state.consumption.years.sort().reverse();

  const { chart1, chart2 } = brandingColors;

  const getScopeTotal = (year, scope, market) => {
    let totalScope = 0;

    Object?.keys(totalEmissionsByScope[year])?.forEach((org) => {
      if (market) {
        totalScope += (totalEmissionsByScope[year][org][scope] && totalEmissionsByScope[year][org][scope]?.co2e_market) || 0;
      } else {
        totalScope += (totalEmissionsByScope[year][org][scope] && totalEmissionsByScope[year][org][scope]?.co2e) || 0;
      }
    });

    return totalScope;
  };

  years?.forEach((year, i) => {
    data[year] = [
      {
        name: 'Scope 1',
        y:
          isMarket &&
          totalEmissionsByScope[year] &&
          totalEmissionsByScope[year][currentOrganisation] &&
          totalEmissionsByScope[year][currentOrganisation]?.[1] &&
          !isNaN(totalEmissionsByScope[year][currentOrganisation]?.[1]?.co2e_market)
            ? parseFloat(getScopeTotal(year, 1, true).toFixed(2)) || 0
            : (totalEmissionsByScope[year][currentOrganisation]?.[1] &&
                totalEmissionsByScope[year][currentOrganisation]?.[1]?.co2e &&
                parseFloat(getScopeTotal(year, 1, false).toFixed(2))) ||
              0,
      },
      {
        name: 'Scope 2',
        y:
          isMarket &&
          totalEmissionsByScope[year] &&
          totalEmissionsByScope[year][currentOrganisation] &&
          totalEmissionsByScope[year][currentOrganisation]?.[2] &&
          typeof totalEmissionsByScope[year][currentOrganisation]?.[2]?.co2e_market === 'number'
            ? parseFloat(getScopeTotal(year, 2, true).toFixed(2)) || 0
            : (totalEmissionsByScope[year][currentOrganisation]?.[2] &&
                totalEmissionsByScope[year][currentOrganisation]?.[2]?.co2e &&
                parseFloat(getScopeTotal(year, 2, false).toFixed(2))) ||
              0,
      },
      {
        name: 'Scope 3',
        y:
          isMarket &&
          totalEmissionsByScope[year] &&
          totalEmissionsByScope[year][currentOrganisation] &&
          totalEmissionsByScope[year][currentOrganisation]?.[3] &&
          typeof totalEmissionsByScope[year][currentOrganisation]?.[3]?.co2e_market === 'number'
            ? parseFloat(getScopeTotal(year, 3, true).toFixed(2)) || 0
            : (totalEmissionsByScope[year][currentOrganisation]?.[3] &&
                totalEmissionsByScope[year][currentOrganisation]?.[3]?.co2e &&
                parseFloat(getScopeTotal(year, 3, false).toFixed(2))) ||
              0,
      },
    ];
  });

  // const chartData = []
  //console.log('data',data)

  const hasChartData = data && data[year] && data[year].length > 0;

  const chartOptions = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'column',
      options3d: {
        enabled: false,
      },
    },
    title: {
      text: `Total Greenhouse Gas Emissions by Scope`,
      style: {
        fontSize: '18px',
        fontFamily: 'Open Sans',
        fontWeight: '600',
        color: '#130834',
      },
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      shared: true,
      headerFormat: '<span style="font-size: 15px">{point.point.name}</span><br/>',
      pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y} tCO2e</b><br/>',
    },
    yAxis: [
      {
        title: {
          text: 'tCO2e',
          style: {
            fontWeight: 600,
          },
        },
        labels: {
          style: {
            fontWeight: 600,
          },
        },
        showFirstLabel: false,
      },
    ],

    xAxis: {
      type: 'category',
      //max: 2,
      labels: {
        useHTML: true,
        animate: true,
        style: {
          fontWeight: 600,
        },
      },
    },
    accessibility: {
      point: {
        valueSuffix: '%',
      },
    },
    legend: {
      enabled: true,
      layout: 'horizontal',
      itemStyle: {
        fontWeight: 600,
      },
    },
    plotOptions: {
      column: {
        pointWidth: 40,
      },
    },
    series: [
      {
        color: chart2,
        linkedTo: 'main',
        data: data[year - 1],
        name: year - 1,
        showInLegend: true,
      },
      {
        color: chart1,
        name: year,
        id: 'main',
        dataLabels: [
          {
            enabled: false,
            inside: false,
            style: {
              fontSize: '16px',
            },
          },
        ],
        data: data[year],
        showInLegend: true,
      },
    ],
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            plotOptions: {
              column: {
                pointWidth: 20, // Adjust pointWidth for smaller screens
              },
            },
            legend: {
              layout: 'horizontal',
              align: 'center',
              verticalAlign: 'bottom',
            },
          },
        },
      ],
    },
  };

  return {
    chartOptions,
    brandingColors,
    hasChartData,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(TotalGreenhouseEmissionsByScopeChart);
