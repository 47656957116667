import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Highcharts3d from 'highcharts/highcharts-3d';
import HCMore from 'highcharts/highcharts-more';
import { connect } from 'react-redux';

// Initialize the 3D and More modules
HCMore(Highcharts);
Highcharts3d(Highcharts);

const ColoumnChart = ({ multiYearData }) => {
  let currentYear = new Date().getFullYear();
  //Creating an array of years from the current year to 8 years ago
  const categories = Array.from({ length: 8 }, (_, i) => currentYear - i)?.reverse();

  // Generate series data for Scope 1, Scope 2, and Scope 3 in the format: [1.069, 1.065, 1.065, 0, 5.85]
  const scope1Data = categories?.map((year) => multiYearData?.[year]?.scope1 || 0);
  const scope2Data = categories?.map((year) => multiYearData?.[year]?.scope2 || 0);
  const scope3Data = categories?.map((year) => multiYearData?.[year]?.scope3 || 0);

  // Filter out years with no data
  const scope1Identifier = scope1Data?.filter((data) => data > 0);
  const scope2Identifier = scope2Data?.filter((data) => data > 0);
  const scope3Identifier = scope3Data?.filter((data) => data > 0);

  const allIdentifiers = [scope1Identifier, scope2Identifier, scope3Identifier];

  // Find the largest length of data to determine which chart type to use
  const largestLength = allIdentifiers.reduce((maxLength, currentArray) => {
    return currentArray.length > maxLength ? currentArray.length : maxLength;
  }, 0);

  const chartIdentified = largestLength <= 3 ? 'bar' : 'column';

  const combinedData = { ...scope1Data, ...scope2Data, ...scope3Data };
  // Filter the years to keep only those with data
  const yearsWithData = Object.keys(combinedData).filter((year) => {
    return (
      (scope1Data[year] && scope1Data[year] > 0) ||
      (scope2Data[year] && scope2Data[year] > 0) ||
      (scope3Data[year] && scope3Data[year] > 0)
    );
  });

  const years = yearsWithData.map((year) => categories[year]);

  const options = {
    credits: false,
    chart: {
      type: chartIdentified,
      spacing: [20, 20, 20, 20],
      height: 550,
      options3d: {
        enabled: false,
        alpha: 20,
        beta: 10,
        viewDistance: 50,
        depth: 50,
        frame: {
          bottom: {
            size: 1,
            color: 'transparent',
          },
        },
      },
      borderRadius: 15,
    },
    title: {
      text: 'Annual Carbon Emissions by Scope',
      style: {
        fontSize: '18px',
        fontFamily: 'Open Sans',
        fontWeight: '600',
        color: '#130834',
      },
    },
    xAxis: {
      categories: largestLength <= 3 ? years : categories, //['2019', '2020', '2021', '2022', '2023'],
    },
    yAxis: {
      allowDecimals: false,
      min: 0,
      title: {
        text: 'Tonnes of CO2e',
      },
    },
    tooltip: {
      headerFormat: '<b>{point.key}</b><br>',
      pointFormat: '{series.name}: {point.y}<br>Total: {point.stackTotal}',
      enabled: true,
      style: {
        fontSize: '12px',
      },
    },
    legend: {
      align: 'center',
      verticalAlign: 'bottom',
      layout: 'horizontal',
      //margin: 50,
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        borderWidth: 0.1,
        pointWidth: 45, // 50 when bigger screens and 20 when smaller screen
      },
      bar: {
        stacking: 'normal',
        borderWidth: 0.1,
        pointWidth: 30,
      },
    },
    series: [
      {
        name: 'Scope 1',
        data: largestLength <= 3 ? scope1Identifier : scope1Data, //[1.069, 1.065, 1.065, 0, 5.85],
        color: '#33C2EA',
      },
      {
        name: 'Scope 2',
        data: largestLength <= 3 ? scope2Identifier : scope2Data, //[11.101, 10.065, 4.554, 0, 17.612],
        color: '#1F9B38', //'#544FC5',
      },
      {
        name: 'Scope 3',
        data: largestLength <= 3 ? scope3Identifier : scope3Data, //[53.158, 20.706, 6.482, 39.799, 40.981],
        color: '#130834',
      },
    ],
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 600,
          },
          chartOptions: {
            plotOptions: {
              column: {
                pointWidth: 20, // Adjust pointWidth for smaller screens
              },
            },
            legend: {
              layout: 'horizontal',
              align: 'center',
              verticalAlign: 'bottom',
            },
          },
        },
        {
          condition: {
            maxWidth: 800,
          },
          chartOptions: {
            chart: {
              height: 500,
            },
          },
        },
        {
          condition: {
            maxWidth: 600,
          },
          chartOptions: {
            chart: {
              height: 450,
            },
          },
        },
        {
          condition: {
            callback: function () {
              return largestLength <= 3; // Responsive rule when 3 or fewer points exist
            },
          },
          chartOptions: {
            chart: {
              type: 'bar',
            },
            plotOptions: {
              bar: {
                stacking: 'normal',
                borderWidth: 0.1,
                pointWidth: 50,
              },
            },
          },
        },
      ],
    },
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

const mapStateToProps = (state) => {
  return {
    multiYearData: state.dashboard.data.multiYear,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ColoumnChart);
