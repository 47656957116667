import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import { Checkbox, FormControlLabel } from '@mui/material';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import { isArray } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { delay } from '../../components/utils/GlobalFunctions';
import ACTIONS from '../../store/action';
import { Actions as ADMINACTIONS } from '../../store/actions/adminActions';
import { Actions as METRICACTIONS } from '../../store/actions/metricActions';
import { Actions as ORGANISATIONACTIONS } from '../../store/actions/organisationActions';
import { Actions as REPORTACTIONS } from '../../store/actions/reportActions';
import { Actions as USAGEACTIONS } from '../../store/actions/usageActions';
import { AddButton, ImportLinks } from '../../styledComponents/GlobalStyle';
import UploadBtn from '../Common/ReusableComponents/UploadBtn';

const ConfirmationModal = ({
  toggleModal,
  modalProps,
  modalData,
  branding,
  progressState,
  toggleCognitoUserStatus,
  clearReportCacheDate,
  disconnectOrganisation,
  deleteItemFromDB,
  selectedAccounts,
  selectedTrackingCategory,
  selectedTrackingOptions,
  method,
  saveOrganisationSettings,
  saveOrganisation,
  tenant,
  tenants,
  setTenant,
  confirmationModal,
  deleteUsageData,
  sageConnected,
  recalculateUsage,
  bulkAddXeroOptions,
  modalType,
  reportGeneration,
  organisationCreation,
  apiAccess,
  executeContract,
  getAllItemsFromDB,
  getTemplates,
  getTypes,
  migrateContract,
}) => {
  const { primaryColor, cancelBtn, secondaryColor } = branding.colors;
  const { modalTitle, modalSubtitle, btn, table, type, tableName, extraInfo } = modalProps || {};

  const tenantToOption = (item) => ({
    value: item.tenantId,
    label: item.tenantName,
  });

  const recalcAllUsage = modalType === 'recalcAllUsage' ? true : null;
  const deletingReportsCache = modalType === 'reports-table-cache-delete' ? true : null;
  const recalcOrgUsage = modalType === 'orgs-table-recalcOrgUsage' ? true : null;
  const bulkDeleteUsage = modalType === 'usageData-bulk_delete' ? true : null;
  const deleteUsageItem = modalType === 'usageData-delete' ? true : null;
  const reportGenerat = modalType?.includes('OrgReports') ? true : null;
  const deleteOrganisations = modalType?.includes('deleteOrgs') ? true : null;
  const importMixedTemplates = modalType?.includes('importMixedTemplates') ? true : null;
  const orgCreation = modalType?.includes('OrgCreation') ? true : null;
  const excludeOrgFromDeletion = modalType?.includes('excludeOrgFromDeletion');
  const ApiAccess = modalType?.includes('APIAccess') ? true : null;
  const contractExecution = modalType?.includes('confirm-contract-execution') ? true : null;
  const migrateToWhiteLabel = modalType?.includes('confirm-migrate-to-whiteLabel') ? true : null;

  const [progressBarActive, setProgressBarActive] = useState(false);
  const [confirmRecalcAllUsage, setConfirmRecalcAllUsage] = useState(false);
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [settingsWipeNotice, setSettingsWipeNotice] = useState(table === 'disconnect' && true);
  const [selectedTenant, setSelectedTenant] = useState(
    tenants?.map(tenantToOption).find((tenantOption) => tenantOption.value === tenant)
  );

  useEffect(() => {
    setProgress(0);
  }, [progressBarActive]);

  useEffect(() => {
    if (progressState) setProgress(progressState);
  }, [progressState]);

  if (!confirmationModal) {
    return null;
  }

  return (
    <div className='modal' style={{ display: 'block', background: 'rgba(0,0,0,.5)' }}>
      <div className='modal-dialog' style={{ marginTop: '20vh' }}>
        <div className='modal-content'>
          <div className='modal-header'>
            <button
              onClick={() => {
                toggleModal(false, null);
                setProgress(0);
              }}
              className='close'>
              x
            </button>
            <h4 className='modal-title' id='modal-title'>
              {modalTitle}
            </h4>
          </div>
          {!progressBarActive && (
            <>
              <div className='modal-body' id='main-body' style={{ display: modalSubtitle ? 'block' : 'none' }}>
                {modalSubtitle}

                {extraInfo && Object.keys(extraInfo).length > 0 && extraInfo.show && (
                  <div style={{ marginTop: 10 }}>
                    {['downloadLink'].includes(extraInfo.action) && (
                      <>
                        {extraInfo.icon && extraInfo.icon}
                        <ImportLinks style={{ marginBottom: '2px' }} href={extraInfo.link}>
                          <span style={{ marginLeft: extraInfo.icon ? 5 : 0 }}>{extraInfo.title}</span>
                        </ImportLinks>
                      </>
                    )}
                  </div>
                )}

                {modalData?.selectOrg && (
                  <div style={{ marginTop: '10px' }}>
                    <Select
                      isClearable={false}
                      isSearchable={true}
                      options={modalData?.tenants?.map(tenantToOption)}
                      value={selectedTenant}
                      onChange={(selectedOption) => {
                        if (table === 'disconnect') {
                          if (selectedOption.value === tenant) {
                            setSettingsWipeNotice(true);
                          } else {
                            setSettingsWipeNotice(false);
                          }
                        }
                        setSelectedTenant(selectedOption);
                      }}></Select>
                  </div>
                )}
                {settingsWipeNotice && (
                  <p style={{ marginTop: '10px', marginBottom: '0px', color: 'red', fontWeight: 'bold' }}>
                    {`Please note, by disconnecting ${
                      selectedTenant?.label
                    } you will lose integration settings in CYF connected to this ${
                      sageConnected ? 'Sage' : 'Xero'
                    } organisation.`}
                  </p>
                )}
              </div>
              <div
                className='modal-body'
                style={{
                  marginBottom: 10,
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: recalcAllUsage ? 'column' : 'row',
                  alignItems: 'center',
                }}>
                {recalcAllUsage && (
                  <FormControlLabel
                    control={<Checkbox checked={confirmRecalcAllUsage} />}
                    onChange={(e) => setConfirmRecalcAllUsage(e.target.checked)}
                    label={'Please check to confirm this action.'}
                  />
                )}
                <div style={{ display: 'flex' }}>
                  {btn && !deleteOrganisations && !importMixedTemplates ? (
                    <AddButton
                      id='confirm-btn'
                      className='btn btn-success'
                      disabled={
                        (modalData?.selectOrg && !selectedTenant) || (recalcAllUsage && !confirmRecalcAllUsage) || loading
                      }
                      onClick={async () => {
                        setLoading(true);
                        if (tableName === 'profile') {
                          // This function is just for profile table - disable user
                          await toggleCognitoUserStatus(tableName, modalData);
                          toggleModal(false, null);
                        } else if (deletingReportsCache) {
                          // This function is to clear the report cache date
                          await clearReportCacheDate(modalData.id);
                          toggleModal(false, null);
                        } else if (recalcOrgUsage) {
                          // Recalculate Org / Subentity Usage
                          await recalculateUsage(modalData.id);
                          toggleModal(false, null);
                        } else if (recalcAllUsage) {
                          // Recalculate Org / Subentity Usage
                          await recalculateUsage('recalcAllUsage');
                          toggleModal(false, 'recalcAllUsage');
                        } else if (type === 'bulkAddXeroOptions') {
                          await bulkAddXeroOptions(modalData?.selectedTrackingCategory);
                          toggleModal(false, null);
                        } else if (excludeOrgFromDeletion) {
                          await saveOrganisation(modalData);
                          toggleModal(false, null);
                        } else if (table === 'disconnect') {
                          await disconnectOrganisation(type, selectedTenant?.value || tenant);
                          toggleModal(false, null);
                        } else if (migrateToWhiteLabel) {
                          await migrateContract(modalData);
                        } else if (tableName === 'usageData') {
                          const usageArray = deleteUsageItem ? [modalData] : modalData;
                          const adminPortalAction = bulkDeleteUsage || deleteUsageItem;
                          await deleteUsageData(usageArray, adminPortalAction);
                          setProgress(0);
                          setProgressBarActive(true);
                        } else if (table === 'saveXero') {
                          if (method === 'accountcodes') {
                            await saveOrganisationSettings({ selectedAccounts, method, type: 'xero' });
                          } else {
                            await saveOrganisationSettings({
                              selectedTrackingCategory,
                              selectedTrackingOptions,
                              method,
                              type: 'xero',
                            });
                          }
                          toggleModal(false, null);
                        } else if (table === 'saveXeroTenant') {
                          await setTenant(modalData?.tenant);
                          toggleModal(false, null);
                        } else if (table === 'selectIntegrationOrg') {
                          await setTenant(selectedTenant?.value);
                          toggleModal(false, null);
                        } else if (reportGenerat) {
                          await reportGeneration(modalData);
                          toggleModal(false, null);
                        } else if (orgCreation) {
                          await organisationCreation(modalData);
                        } else if (ApiAccess) {
                          await apiAccess(modalData);
                          toggleModal(false, null);
                        } else if (contractExecution) {
                          await executeContract(modalData);
                          toggleModal(false, null);
                        } else if (tableName === 'templates') {
                          await deleteItemFromDB(tableName, [{ ...modalData }]);
                          toggleModal(false, null);
                          getTemplates();
                        } else {
                          // Delete Item From DB
                          if (isArray(modalData))
                            await deleteItemFromDB(
                              tableName,
                              modalData?.map((x) => ({ id: x.id }))
                            );
                          else {
                            await deleteItemFromDB(tableName, [{ ...modalData }]);
                            if (tableName === 'usageTypes') {
                              await delay(10);
                              getTypes();
                            }
                          }
                          toggleModal(false, null);
                          getAllItemsFromDB(tableName);
                        }
                        setLoading(false);
                      }}>
                      {loading && <i className='fa fa-spin fa-spinner' style={{ marginRight: 5 }} />}
                      {btn?.title}
                    </AddButton>
                  ) : deleteOrganisations ? (
                    <UploadBtn action={'deleteOrgs'} toggleModal={toggleModal} />
                  ) : importMixedTemplates ? (
                    <UploadBtn action={'importMixedTemplates'} toggleModal={toggleModal} />
                  ) : null}
                  <AddButton
                    id='delete-cancel-btn'
                    className='btn btn-danger'
                    style={{ marginLeft: 40, width: 104, display: 'flex', backgroundColor: cancelBtn || secondaryColor }}
                    onClick={() => toggleModal(false, null)}>
                    <DoNotDisturbIcon sx={{ mr: 1 }} /> Cancel
                  </AddButton>
                </div>
              </div>
            </>
          )}
          {progressBarActive && (
            <div className='modal-body' style={{ marginBottom: 10, display: 'flex', justifyContent: 'center' }}>
              <Box sx={{ width: '100%' }}>
                <LinearProgressWithLabel sx={{ height: 7, borderRadius: 5 }} value={progress} primarycolor={primaryColor} />
                {progress === 100 && (
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <p>Usage Data has been Deleted Successfully!</p>
                  </div>
                )}
              </Box>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', height: '10vh' }}>
      <Box sx={{ width: '90%', mr: 4, marginLeft: '5vh', color: props.primarycolor }}>
        <LinearProgress color='inherit' variant='determinate' {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant='body2' fontSize='2vh' color='text.secondary'>{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /*
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
  primarycolor: PropTypes.string.isRequired,
};

const mapStateToProps = (state, props) => {
  const modalType = state?.admin?.modal?.modal;

  return {
    confirmationModal: state.admin?.modal?.confirmationModal,
    modalProps: state.admin?.modal?.modalProps,
    modalData: state.admin?.modal?.item,
    modalType,
    branding: state?.branding,
    progressState: state?.progress,
    selectedAccounts: state?.selectedAccounts,
    selectedTrackingCategory: state?.selectedTrackingCategory,
    selectedTrackingOptions: state?.selectedTrackingOptions,
    method: state?.method,
    tenants: state.tenants,
    tenant: state.tenant,
    sageConnected: state?.sageConnected,
  };
};

const mapDispatchToProps = (dispatch) => ({
  executeContract: (contract) => dispatch(ADMINACTIONS.executeContract(contract)),
  saveOrganisationSettings: (settings) => dispatch(ACTIONS.saveOrganisationSettings(settings)),
  toggleModal: (status, action) => dispatch(ADMINACTIONS.toggleModal(status, action)),
  reportGeneration: (orgDetails) => dispatch(ORGANISATIONACTIONS.reportGeneration(orgDetails)),
  organisationCreation: (orgDetails) => dispatch(ORGANISATIONACTIONS.organisationCreation(orgDetails)),
  apiAccess: (orgDetails) => dispatch(ORGANISATIONACTIONS.apiAccess(orgDetails)),
  toggleCognitoUserStatus: (table, item) => dispatch(ADMINACTIONS.toggleCognitoUserStatus(table, item)),
  clearReportCacheDate: (reportId) => dispatch(REPORTACTIONS.clearReportCacheDate(reportId)),
  disconnectOrganisation: (type, tenantId) => dispatch(ACTIONS.disconnectOrganisation(type, tenantId)),
  deleteItemFromDB: (table, item) => dispatch(ADMINACTIONS.deleteItemFromDB(table, item)),
  recalculateUsage: (id) => dispatch(ADMINACTIONS.recalculateUsage(id)),
  deleteUsageData: (items, adminPortal) => dispatch(USAGEACTIONS.deleteUsageData(items, adminPortal)),
  setTenant: (tenant) => dispatch(ACTIONS.saveTenant(tenant)),
  selectTrackingCategory: (selected) => dispatch(ACTIONS.selectTrackingCategory(selected)),
  bulkAddXeroOptions: (trackingCategory) => dispatch(ACTIONS.bulkAddXeroOptions(trackingCategory)),
  saveOrganisation: (orgDetails) => dispatch(ORGANISATIONACTIONS.saveOrganisation(orgDetails)),
  getAllItemsFromDB: (table) => dispatch(ADMINACTIONS.getAllItemsFromDB(table)),
  getTemplates: () => dispatch(ADMINACTIONS.getTemplates()),
  migrateContract: (contract) => dispatch(ADMINACTIONS.migrateContract(contract)),
  getTypes: () => dispatch(METRICACTIONS.getTypes()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationModal);
