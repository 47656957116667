import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Actions as USAGEACTIONS } from '../../store/actions/usageActions';
import { UploadFileBtn, CalendarBtn } from '../../styledComponents/GlobalStyle';
import Select from 'react-select';
import _ from 'lodash';
import { selectStyles } from '../utils/ReactSelectStyles';
import { itemToOptions } from '../utils/GlobalFunctions';
import { Divider, LinearProgress } from '@mui/material';
import { isValid, format } from 'date-fns';
import DatePicker from 'react-datepicker';

const InvoiceModal = ({
  showInvoiceModal,
  status,
  action,
  item,
  branding,
  getUploadLink,
  fieldToDisable,
  types,
  suppliers,
  options,
  processUpload,
  uploadUsageType,
  saveInvoice,
  uploadKey,
  excludeKeys,
  deleteInvoice,
  alertMessage,
  currentOrg,
}) => {
  const [modalTitle, setModalTitle] = useState('');
  const [buttonsTitle, setButtonsTitle] = useState({ confirm: '', cancel: '' });
  const [phase, setPhase] = useState(action === 'add' ? 'Upload' : 'Approve'); //Upload, Approve, Loading
  const [file, setFile] = useState(null);
  const [inputDisabled, setInputDisabled] = useState(false);
  const [type, setType] = useState(null);
  const [supplier, setSupplier] = useState(null);
  const [editedState, setEditedState] = useState(item);
  const [disableButton, setDisableButton] = useState(action !== 'delete' && !editedState?.facility ? true : false);
  const [expandedObjects, setExpandedObjects] = useState(false);
  const initialExpandedObjectsSet = useRef(false);
  const [buttonLoading, setButtonLoading] = useState(false);

  const textColor = branding?.btnTextColor || 'black';

  useEffect(() => {
    if (action === 'add') {
      setPhase('Upload');
    } else {
      setPhase('Approve');
      setEditedState(item);
    }
  }, [action]);

  const handleClose = () => {
    showInvoiceModal(false);
  };

  const selectFile = (e) => {
    const newFile = e.target.files && e.target.files[0];
    setFile(newFile);
    getUploadLink(newFile, 'invoices');
    e.target.value = '';
  };

  const handleFileRemove = () => {
    setInputDisabled(true); // disable the input
    setFile(null);
    setTimeout(() => {
      setInputDisabled(false); // re-enable the input
    }, 10); // wait for a brief delay
  };

  useEffect(() => {
    if (action === 'add') {
      setModalTitle('Import Invoice');
      setButtonsTitle({ confirm: 'Save', cancel: 'Cancel' });
    } else if (action === 'edit' || action === 'Approve') {
      setModalTitle('Edit Invoice');
      setButtonsTitle({ confirm: 'Save', cancel: 'Cancel' });
    } else if (action === 'delete') {
      setModalTitle('Delete Invoice');
      setButtonsTitle({ confirm: 'Delete', cancel: 'Cancel' });
    }
  }, [action]);

  function toShowCase(str) {
    return str
      ?.replace(/([a-z])([A-Z])/g, '$1 $2') // Add space before each uppercase letter
      ?.replace(/^./, (str) => str.toUpperCase());
  }

  const handleButtonClick = async () => {
    // Start the loader
    setButtonLoading(true);

    try {
      // Handle the action based on the 'action' value
      if (action === 'delete') {
        await deleteInvoice(editedState['id']);
      } else {
        await saveInvoice({
          ...editedState,
          key: editedState['key'] || uploadKey,
          organisation: currentOrg,
          type: String(type)?.split(' Invoice')?.[0],
        });
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      // Stop the loader
      setButtonLoading(false);
    }
  };

  function isNumber(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  // Initialize the expandedObjects state with the keys from editedState
  useEffect(() => {
    if (!initialExpandedObjectsSet.current) {
      const initialExpandedObjects =
        editedState &&
        Object.keys(editedState)
          ?.filter((key) => typeof editedState[key] === 'object' && editedState[key] !== null)
          ?.map((key) => ({
            key,
            isExpanded: false,
          }));
      setExpandedObjects(initialExpandedObjects);
      initialExpandedObjectsSet.current = true;
      console.log('initialExpandedObjects:', initialExpandedObjects);
    }
  }, [editedState]);

  console.log('editedstate:', editedState);

  // Function to check if an object is expanded
  const isExpanded = (objKey) => {
    const obj = expandedObjects && expandedObjects?.find((obj) => obj.key === objKey);
    console.log('obj:', obj);
    return obj ? obj.isExpanded : false;
  };

  // Function to toggle the expansion status for a specific object
  const toggleExpansion = (objKey) => {
    setExpandedObjects((prev) => prev?.map((obj) => (obj.key === objKey ? { ...obj, isExpanded: !obj.isExpanded } : obj)));
  };

  if (!status) return null;

  return (
    <div className='modal' style={{ display: 'block', background: 'rgba(0,0,0,.5)' }}>
      <div className='modal-dialog'>
        <div className='modal-content' style={{ background: 'transparent' }}>
          <div className='modal-header'>
            <button onClick={handleClose} className='close'>
              x
            </button>
            <h4 className='modal-title'>{modalTitle}</h4>
          </div>
          <div id='Background' style={{ background: 'transparent', height: '85vh', overflow: 'auto' }}>
            <div
              className='modal-body'
              style={{
                overflow: 'visible',
                background: 'white',
                borderBottomRightRadius: '6px',
                borderBottomLeftRadius: '6px',
              }}>
              {/* Modal Content */}
              <div id='Loading' style={{ display: phase === 'Loading' ? 'block' : 'none', margin: '30px 0px' }}>
                <h4 style={{ display: 'flex', justifyContent: 'center', marginBottom: 5 }}>{alertMessage}</h4>
                <LinearProgress color='inherit' />
              </div>

              <div
                id='UploadContent'
                style={{
                  display: phase === 'Upload' ? 'flex' : 'none',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}>
                <p>Upload an Invoice to Start:</p>
                <div id='file-name' style={{ marginBottom: 10 }}>
                  <strong>File Name: </strong>
                  <span>{file?.name}</span>
                </div>
                <div
                  id='Facility-select'
                  style={{
                    display: file ? 'flex' : 'none',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginBottom: 20,
                    flexDirection: 'column',
                    gap: 10,
                  }}>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <strong style={{ marginRight: 5 }}>Type: </strong>
                    <Select
                      styles={{
                        ...selectStyles,
                        menu: (styles) => ({ ...styles, zIndex: 999 }),
                        container: (base) => ({
                          ...base,
                          width: '200px',
                        }),
                        control: (base) => ({
                          ...base,
                          height: '30px',
                        }),
                      }}
                      options={types}
                      isClearable={true}
                      menuPlacement={'auto'}
                      value={_.find(types, { value: type }) || null}
                      placeholder={'Select Invoice type'}
                      onChange={(selected) => {
                        setType(selected?.value);
                      }}
                    />
                  </div>
                  {type && (
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <strong style={{ marginRight: 5 }}>Supplier: </strong>
                      <Select
                        styles={{
                          ...selectStyles,
                          menu: (styles) => ({ ...styles, zIndex: 999 }),
                          container: (base) => ({
                            ...base,
                            width: '200px',
                          }),
                          control: (base) => ({
                            ...base,
                            height: '30px',
                          }),
                        }}
                        options={suppliers}
                        isClearable={true}
                        menuPlacement={'auto'}
                        value={_.find(suppliers, { value: supplier }) || null}
                        placeholder={'Select Supplier'}
                        onChange={(selected) => {
                          setSupplier(selected?.value);
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>

              <div
                id='ApproveContent'
                style={{
                  display: phase === 'Approve' ? 'flex' : 'none',
                  flexDirection: 'column',
                  alignItems: 'center',
                  width: '100%',
                }}>
                {action !== 'delete' &&
                  editedState &&
                  Object.keys(editedState)
                    ?.filter((o) => !excludeKeys?.includes(o))
                    ?.sort((a, b) => a.localeCompare(b))
                    ?.map((obj) => {
                      return (
                        <div
                          className='form-group'
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            width: '100%',
                          }}>
                          <label>{toShowCase(obj)}</label>
                          {obj === 'facility' || obj === 'type' ? (
                            <Select
                              styles={{
                                ...selectStyles,
                                menu: (styles) => ({ ...styles, zIndex: 999 }),
                                container: (base) => ({
                                  ...base,
                                  width: '50%',
                                }),
                                control: (base) => ({
                                  ...base,
                                  height: '30px',
                                }),
                              }}
                              options={options[obj]}
                              isClearable={true}
                              menuPlacement={'auto'}
                              value={options[obj]?.find((f) => f.value === editedState[obj]) || null}
                              placeholder={`Select ${obj}`}
                              onChange={(selected) => {
                                const newEditedState = { ...editedState };
                                if (selected == null) {
                                  newEditedState[obj] = null;
                                  if (obj === 'facility') {
                                    setDisableButton(true);
                                  }
                                } else {
                                  newEditedState[obj] = selected?.value;
                                  if (obj === 'facility') {
                                    setDisableButton(false);
                                  }
                                }
                                setEditedState(newEditedState);
                              }}
                            />
                          ) : obj === 'readings' || obj === 'meterReadings' ? (
                            <>
                              <button
                                onClick={() => toggleExpansion(obj)}
                                className='btn btn-link'
                                style={{ display: 'flex', alignItems: 'center' }}>
                                {isExpanded(obj) ? 'Show Less' : 'Show More'}
                                {isExpanded(obj) ? (
                                  <i className='fa-solid fa-chevron-up' style={{ marginLeft: 5 }}></i>
                                ) : (
                                  <i className='fa-solid fa-chevron-down' style={{ marginLeft: 5 }}></i>
                                )}
                              </button>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  width: '100%',
                                }}>
                                {isExpanded(obj) &&
                                  editedState[obj]?.map((reading, readingIndex) => {
                                    if (reading?.billedUnits == 0 || reading?.consumption == 0) {
                                      return null;
                                    }
                                    const readingLength = editedState[obj]?.filter(
                                      (r) => r.billedUnits != 0 || r.consumption != 0
                                    )?.length;
                                    return (
                                      <div
                                        key={readingIndex}
                                        style={{
                                          marginBottom:
                                            readingIndex == editedState[obj]?.length - 1 || readingIndex == readingLength - 1
                                              ? '0px'
                                              : '20px',
                                          border: '1px solid #ccc',
                                          borderRadius: '12px',
                                          padding: 20,
                                          width: '55%',
                                          position: 'relative',
                                        }}>
                                        {/* Display Reading Index */}
                                        <h5
                                          style={{
                                            display: 'flex',
                                            marginTop: 0,
                                            justifyContent: 'center',
                                          }}>
                                          <b>Reading {readingIndex + 1}</b>
                                        </h5>{' '}
                                        <Divider style={{ marginBottom: 5 }} />
                                        {/* Start from Reading 1 */}
                                        {/* Display all fields for each reading */}
                                        {Object.keys(reading)?.map((fieldKey, fieldIndex) => (
                                          <div
                                            className='form-group'
                                            key={fieldIndex}
                                            style={{
                                              display: 'flex',
                                              flexDirection: 'column',
                                              justifyContent: 'center',
                                              alignItems: 'center',
                                              width: '100%',
                                              position: 'relative',
                                            }}>
                                            {/* Convert field key to a more readable format */}
                                            <label>{toShowCase(fieldKey)}</label>
                                            {/* Check if the field is a date, if it is show datePicker*/}
                                            {isValid(new Date(editedState[obj][readingIndex][fieldKey])) &&
                                            !isNumber(editedState[obj][readingIndex][fieldKey]) ? (
                                              <label className='input-group date datepicker'>
                                                <DatePicker
                                                  showMonthDropdown
                                                  showYearDropdown
                                                  dateFormat='dd/MM/yyyy'
                                                  name={fieldKey}
                                                  selected={new Date(editedState[obj][readingIndex][fieldKey])}
                                                  onChange={(selectedDate, e) => {
                                                    const newEditedState = { ...editedState };
                                                    newEditedState[obj][readingIndex][fieldKey] = format(
                                                      new Date(selectedDate),
                                                      'yyyy-MM-dd'
                                                    );
                                                    setEditedState(newEditedState);
                                                  }}
                                                  maxDate={new Date()}
                                                  className='form-control date'
                                                  wrapperClassName='date-picker'
                                                  popperPlacement='bottom-end'
                                                  popperModifiers={{
                                                    offset: {
                                                      enabled: true,
                                                      offset: '77px, 0px',
                                                    },
                                                  }}
                                                />
                                                <span className='input-group-btn'>
                                                  <CalendarBtn className='btn btn-primary date-set'>
                                                    {' '}
                                                    <i className='fa fa-calendar'></i>{' '}
                                                  </CalendarBtn>
                                                </span>
                                              </label>
                                            ) : (
                                              <input
                                                type='text'
                                                className='form-control'
                                                onChange={(e) => {
                                                  const newEditedState = { ...editedState };
                                                  newEditedState[obj][readingIndex][fieldKey] = e.target.value;
                                                  setEditedState(newEditedState);
                                                }}
                                                style={{ position: 'relative', width: '100%' }}
                                                disabled={fieldToDisable?.includes(fieldKey)}
                                                value={editedState[obj][readingIndex][fieldKey] || ''}
                                              />
                                            )}
                                          </div>
                                        ))}
                                      </div>
                                    );
                                  })}
                              </div>
                            </>
                          ) : obj !== 'readings' && typeof editedState[obj] === 'object' ? (
                            <>
                              <button
                                onClick={() => toggleExpansion(obj)}
                                className='btn btn-link'
                                style={{ display: 'flex', alignItems: 'center' }}>
                                {isExpanded(obj) ? 'Show Less' : 'Show More'}
                                {isExpanded(obj) ? (
                                  <i className='fa-solid fa-chevron-up' style={{ marginLeft: 5 }}></i>
                                ) : (
                                  <i className='fa-solid fa-chevron-down' style={{ marginLeft: 5 }}></i>
                                )}
                              </button>
                              <div
                                key={obj}
                                style={{
                                  display: isExpanded(obj) ? 'block' : 'none',
                                  border: '1px solid #ccc',
                                  borderRadius: '12px',
                                  padding: 20,
                                  width: '55%',
                                  position: 'relative',
                                }}>
                                {Object.keys(editedState[obj])?.map((def, defIndex) => (
                                  <div
                                    style={{
                                      marginBottom: defIndex == Object.keys(editedState[obj])?.length - 1 ? '0px' : '20px',
                                      paddingBottom: defIndex == Object.keys(editedState[obj])?.length - 1 ? '0px' : '5px',
                                      borderBottom:
                                        defIndex == Object.keys(editedState[obj])?.length - 1 ? '' : '1px solid #ccc',
                                    }}>
                                    <span>{editedState[obj][def]}</span>
                                  </div>
                                ))}
                              </div>
                            </>
                          ) : isValid(new Date(editedState[obj])) && !isNumber(editedState[obj]) ? (
                            <label className='input-group date datepicker' style={{ position: 'relative', width: '50%' }}>
                              <DatePicker
                                showMonthDropdown
                                showYearDropdown
                                dateFormat='dd/MM/yyyy'
                                name={obj}
                                selected={new Date(editedState[obj])}
                                onChange={(selectedDate, e) => {
                                  const newEditedState = { ...editedState };
                                  newEditedState[obj] = format(new Date(selectedDate), 'yyyy-MM-dd');
                                  setEditedState(newEditedState);
                                }}
                                maxDate={new Date()}
                                className='form-control date'
                                wrapperClassName='date-picker'
                                popperPlacement='bottom-end'
                                popperModifiers={{
                                  offset: {
                                    enabled: true,
                                    offset: '77px, 0px',
                                  },
                                }}
                              />
                              <span className='input-group-btn'>
                                <CalendarBtn className='btn btn-primary date-set'>
                                  {' '}
                                  <i className='fa fa-calendar'></i>{' '}
                                </CalendarBtn>
                              </span>
                            </label>
                          ) : (
                            <input
                              type='text'
                              className='form-control'
                              onChange={(e) => {
                                const newEditedState = { ...editedState };
                                newEditedState[obj] = e.target.value;
                                setEditedState(newEditedState);
                              }}
                              style={{ position: 'relative', width: '50%' }}
                              disabled={fieldToDisable?.includes(obj)}
                              value={editedState[obj]}
                            />
                          )}
                        </div>
                      );
                    })}
                {action === 'delete' && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '100%',
                    }}>
                    <h4 style={{ display: 'flex', marginBottom: 20, justifyContent: 'center' }}>
                      <b>Are you sure you want to delete this invoice?</b>
                    </h4>
                  </div>
                )}
              </div>

              {/* Button Div */}
              <div
                id='UploadButton'
                style={{
                  display: phase === 'Upload' ? 'flex' : 'none',
                  flexDirection: 'row',
                  gap: 10,
                  justifyContent: 'center',
                  marginBottom: 10,
                }}>
                <button
                  className='btn btn-success'
                  style={{
                    display: file ? 'flex' : 'none',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: textColor,
                    fontWeight: 600,
                  }}
                  disabled={!type || !supplier}
                  onClick={() => {
                    uploadUsageType('Invoice');
                    processUpload(file, 'invoices', { type: type, supplier: supplier, documentName: file?.name });
                    setPhase('Loading');
                  }}>
                  <i className='fa-solid fa-arrow-right' style={{ marginRight: 5 }}></i>Continue
                </button>
                {file ? (
                  <UploadFileBtn htmlFor='import_input' className='custom-file-upload btn' onClick={handleFileRemove}>
                    <i className='fa fa-trash' />
                    &nbsp;
                    <span style={{ fontWeight: 600 }}>Remove File</span>
                  </UploadFileBtn>
                ) : (
                  <>
                    <UploadFileBtn htmlFor='import_input' className='custom-file-upload btn btn-success'>
                      <i className='fa fa-cloud-upload' />
                      &nbsp;
                      <span style={{ fontWeight: 600 }}>Upload File</span>
                    </UploadFileBtn>
                    <input
                      id='import_input'
                      disabled={inputDisabled}
                      type='file'
                      accept='.pdf' //, .csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                      onChange={selectFile}
                    />
                  </>
                )}
              </div>
              <div
                id='ApproveButtons'
                style={{
                  display: phase === 'Approve' ? 'flex' : 'none',
                  marginBottom: 10,
                  gap: 10,
                  justifyContent: 'center',
                }}>
                <button
                  onClick={() => handleButtonClick()}
                  className='btn btn-success'
                  style={{ display: !buttonsTitle.confirm && 'none', color: textColor, fontWeight: 600 }}
                  disabled={disableButton}>
                  {buttonLoading ? (
                    <i className='fa fa-spin fa-spinner' style={{ marginRight: 5 }} />
                  ) : (
                    <>
                      <i className='fa-regular fa-circle-check'></i> {buttonsTitle?.confirm}
                    </>
                  )}
                </button>
                <button
                  onClick={handleClose}
                  className='btn btn-danger'
                  style={{ display: !buttonsTitle.cancel && 'none', color: textColor, fontWeight: 600 }}>
                  <i className='fa-regular fa-circle-xmark' style={{ marginRight: 5 }}></i>
                  {buttonsTitle?.cancel}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const facilityOptions = state?.facility?.list?.map(itemToOptions);
  const typeOptions = [
    { value: 'Electricity Invoice', label: 'Electricity' },
    { value: 'Gas Invoice', label: 'Gas' },
  ];
  const supplierOptions = ['F&S Energy Electricity', 'Regent Gas', 'Total Energies'];
  const excludeKeys = ['id', 'organisation', 'type', 'key'];

  return {
    status: state?.invoiceModal?.status,
    action: state?.invoiceModal?.action,
    item: state?.invoiceModal?.item,
    branding: state?.branding.colors,
    fieldToDisable: ['id', 'organisation', 'accountNumber', 'invoiceNumber'],
    facilities: facilityOptions,
    types: typeOptions,
    options: {
      facility: facilityOptions,
      type: typeOptions,
    },
    suppliers: supplierOptions?.map((s) => ({ value: s, label: s })),
    uploadKey: state?.importUploadKey || null,
    excludeKeys,
    alertMessage: state?.alertMessage?.message,
    currentOrg: state?.currentOrganisation,
  };
};

const mapDispatchToProps = (dispatch) => ({
  showInvoiceModal: (status, action, item) => dispatch(USAGEACTIONS.showInvoiceModal(status, action, item)),
  getUploadLink: (file, action) => dispatch(USAGEACTIONS.getUploadLink(file, action)),
  processUpload: (file, action, params) => dispatch(USAGEACTIONS.processUpload(file, action, params)),
  uploadUsageType: (type) => dispatch(USAGEACTIONS.uploadUsageType(type)),
  saveInvoice: (invoice) => dispatch(USAGEACTIONS.saveInvoice(invoice)),
  deleteInvoice: (id) => dispatch(USAGEACTIONS.deleteInvoice(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceModal);
