import axios from 'axios';
import createSignedRequest from './createSignedRequest';
const { apiURL } = require('./apiURL');

// types of action
const Types = {
  LIST: 'HELP_LIST',
  TOGGLE: 'HELP_TOGGLE',
};

const showHelp = (status) => ({
  type: Types.TOGGLE,
  status,
});

const updateHelp = (response) => ({
  type: Types.LIST,
  payload: response,
});

const fetchHelp = () => {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios(
        await createSignedRequest('GET', apiURL + `/my/help`, null, {
          Organisation: String(state.currentOrganisation),
        })
      );

      console.log('fetchHelp', response);
      dispatch(updateHelp(response.data));
    } catch (e) {
      console.log('fetchHelp Error', e);
    }
  };
};

const Actions = {
  fetchHelp,
  showHelp,
};

export { Actions, Types };
