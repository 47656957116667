import { Types } from '../actions/profileActions';

const defaultState = {};

const profileReducers = (state = defaultState, action) => {
  switch (action.type) {
    case Types.PROFILE_GETTING: {
      let newState = { ...state };

      newState.profile.updating = true;

      return newState;
    }
    case Types.PROFILE_GET: {
      let newState = { ...state };
      const profile = action?.payload?.profile;

      newState.profile.updating = false;

      newState.profile.details = {
        ...newState.profile.details, // Attributes from Cognito
        isPrimary: profile?.isPrimary || false,
        expiresAt: profile?.expiresAt || 0,
        id: profile?.id || newState?.profile?.details?.id,
        whiteLabelAdmin: profile?.whiteLabelAdmin || false,
        cyfAdmin: profile?.isAdmin || false,
        consultantAdmin: profile?.consultantAdmin || false,
        ambassadorAdmin: profile?.ambassadorAdmin || false,
        registrationDate: profile?.registrationDate,
        credentials: newState?.profile?.details?.credentials,
        contractAdmin: profile?.contractAdmin || false,
        ambassadorCreator: profile?.ambassadorCreator || false,
        contractApprover: profile?.contractApprover || false,
        legalAgreements: {
          userLicenseAgreementConfirmed: action?.payload?.userLicenseAgreementConfirmed || false,
          userPrivacyPolicyAgreementConfirmed: action?.payload?.userPrivacyPolicyAgreementConfirmed || false,
        },
        permissions: profile?.permissions,
      };

      if (profile?.greenElementUser) {
        newState.profile.details = { ...newState.profile.details, greenElementUser: true };
      }

      return newState;
    }
    case Types.PROFILE_SAVING: {
      let newState = { ...state };

      newState.profile.saving = action.status;

      return newState;
    }
    case Types.GENERATING_DNS_RECORDS: {
      let newState = { ...state };

      newState.profile.generatingDNSRecords = action.status;

      return newState;
    }
    case Types.PROFILE_SAVE: {
      let newState = { ...state };

      newState.profile.saving = false;
      newState.profile.details = action.payload;

      return newState;
    }
    case Types.PROFILE_ADMIN: {
      let newState = { ...state };

      newState.profile.details[action.payload.admin] = action.payload.status;

      if (action.payload.admin === 'whiteLabelAdmin') {
        if (action.payload.status) {
          newState.isWhiteLabelApp = true;
          newState.profile.details?.permissions?.portal?.push('WHITELABEL');
        } else {
          newState.isWhiteLabelApp = false;
          newState.profile.details.permissions.portal = newState.profile.details?.permissions?.portal.filter(
            (permission) => permission !== 'WHITELABEL'
          );
        }
      } else {
        newState.isWhiteLabelApp = false;
      }

      return newState;
    }
    case Types.PROFILE_SETTING_DOMAIN_EMAIL: {
      let newState = { ...state };

      if (!newState.organisation.wlDomain) {
        newState.organisation.wlDomain = {};
      }

      if (action.response.message) {
        //Error while Generating DNS Records
        newState.organisation.wlDomain = {
          ...newState.organisation.wlDomain,
          emailErrorMessage: action.response.message,
        };
      } else {
        //DNS Records Generated
        newState.organisation.details = {
          ...newState.organisation.details,
          emailIdentityDomain: action.response.domainName,
        };

        newState.organisation.wlDomain = {
          ...newState.organisation.wlDomain,
          dnsRecordsEmailDomain: action.response.dnsRecords,
        };
      }

      return newState;
    }
    case Types.PROFILE_SETTING_DOMAIN_APP_STATUS: {
      let newState = { ...state };

      if (!newState.organisation.wlDomain) {
        newState.organisation.wlDomain = {};
      }

      if (action.response?.data?.executionArn) {
        //Getting Execution Arn to start step function

        newState.organisation.wlDomain.creatingDomain = action.response?.data;

        return newState;
      } else {
        // Step Function Execution was Succeeded

        const output = JSON.parse(action.response.output);

        if (output.message) {
          //Error while Generating DNS Records
          newState.organisation.wlDomain = {
            ...newState.organisation.wlDomain,
            errorMessage: output.message,
          };
        } else {
          //DNS Records Generated
          newState.organisation.details = {
            ...newState.organisation.details,
            liveDomain: output.liveDomain,
          };

          newState.organisation.wlDomain = {
            ...newState.organisation.wlDomain,
            appDomainDNSRecords: output.dnsRecords,
            errorMessageAppDomain: null,
          };
        }

        return newState;
      }
    }
    case Types.CREATE_WL_DOMAIN_STATUS: {
      let newState = { ...state };

      newState.organisation.wlDomain.creatingDomain = {
        ...newState.organisation.wlDomain.creatingDomain,
        status: action.payload.data.status,
        stopDate: action.payload.data.stopDate,
      };

      return newState;
    }
    case Types.GET_SHORT_CODE: {
      let newState = { ...state };

      newState.promoter = {};

      if (!action.response) {
        delete newState.promoter.referralCode;
      }

      newState.promoter = {
        referralCode: action?.response?.referralCode,
      };

      return newState;
    }
    case Types.UPDATE_ORGANISATION: {
      let newState = { ...state };
      const stripeAccountId = action.payload;

      console.log('stripeAccountId', stripeAccountId);

      const orgDetails = state.organisation.details;

      newState.organisation.details = { ...orgDetails, stripeAccountId: stripeAccountId };

      return newState;
    }
    default:
      return state;
  }
};

export default profileReducers;
