import { Types } from '../actions/dashboardActions';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';

const defaultState = { components: [], data: {}, years: {}, targets: [] };

const dashboardReducers = (state = defaultState, action) => {
  switch (action.type) {
    case Types.SAVE_COMPONENT: {
      let newState = _.cloneDeep(state);
      if (!newState.dashboard) {
        newState.dashboard = defaultState;
      }

      newState.dashboard.components = [...newState.dashboard.components, { ...action.payload, id: uuidv4() }];

      return newState;
    }
    case Types.REMOVE_COMPONENT: {
      let newState = _.cloneDeep(state);
      newState.dashboard.components = newState.dashboard.components?.filter((component) => component.id !== action.payload);

      return newState;
    }
    case Types.EDIT_COMPONENT: {
      let newState = _.cloneDeep(state);
      newState.dashboard.components = newState.dashboard.components?.map((component) => {
        if (component.id === action.payload.id) {
          return action.payload;
        }
        return component;
      });

      return newState;
    }

    case Types.SAVE_SUM: {
      let newState = _.cloneDeep(state);
      if (!newState.dashboard) {
        newState.dashboard = defaultState;
      }

      const startYear = new Date(action.payload?.dashboardYears?.minStartDate).getFullYear();
      const endYear = new Date(action.payload?.dashboardYears?.maxEndDate).getFullYear();

      newState.dashboard.years.startYear = startYear;
      newState.dashboard.years.endYear = endYear;

      const yearlyData = action.payload?.result?.find((item) => item.chart === 'yearly')?.data;
      console.log('yearlyData', yearlyData);

      const totalCO2eByEntity = [];

      // Variables to store total values for each scope
      let totalScope1 = 0;
      let totalScope2 = 0;
      let totalScope3 = 0;

      // Iterate over the data
      yearlyData?.forEach((item) => {
        const { key, field, value } = item;
        let nameKey = newState.entities?.find((entity) => entity.id === key)?.name;

        if (!key) return; // Skip total entry

        // Extract the scope number
        const scopeMatch =
          field.match(/location.calendarYear.scope\.(\d+)/) || field.match(/market.calendarYear.scope\.(\d+)/);

        if (scopeMatch) {
          const scopeNumber = scopeMatch[1]; // Get the scope number (1, 2, or 3)

          // Check if the entity already exists in the array
          let entity = totalCO2eByEntity.find((entity) => entity.key === key);

          // If the entity doesn't exist, create a new one and add it to the array
          if (!entity) {
            entity = {
              key,
              name: nameKey,
              scope1: 0,
              scope2: 0,
              scope3: 0,
              total: 0,
            };
            totalCO2eByEntity.push(entity);
          }

          // Add the value to the respective scope for the entity
          entity[`scope${scopeNumber}`] += value;
          entity.total += value; // Sum for all scopes

          // Add the value to the respective global total scope
          if (scopeNumber === '1') totalScope1 += value;
          if (scopeNumber === '2') totalScope2 += value;
          if (scopeNumber === '3') totalScope3 += value;
        }
      });
      newState.dashboard.data.treemap = totalCO2eByEntity;

      newState.dashboard.data.totalEmissions = yearlyData?.find((item) => item.field === 'total')?.value;
      newState.dashboard.data.highestEmissions = _.maxBy(totalCO2eByEntity, 'total');
      newState.dashboard.data.lowestEmissions = _.minBy(totalCO2eByEntity, 'total');
      newState.dashboard.data.totalScope1 = totalScope1;
      newState.dashboard.data.totalScope2 = totalScope2;
      newState.dashboard.data.totalScope3 = totalScope3;

      const multiYearData = action.payload?.result?.find((item) => item.chart === 'multiYear')?.data;
      console.log('multiYearData', multiYearData);

      let multiYear = {};

      multiYearData.forEach((item) => {
        const { key, field, value } = item;

        if (!key) return; // Skip total entry

        // Ensure the year key exists in the multiYear object
        if (!multiYear[key]) {
          multiYear[key] = {
            scope1: 0,
            scope2: 0,
            scope3: 0,
            total: 0,
          };
        }

        // Assign the values based on the field (scope 1, scope 2, or scope 3)
        if (field.includes('scope.1')) {
          multiYear[key].scope1 = parseFloat(value?.toFixed(2));
        } else if (field.includes('scope.2')) {
          multiYear[key].scope2 = parseFloat(value?.toFixed(2));
        } else if (field.includes('scope.3')) {
          multiYear[key].scope3 = parseFloat(value?.toFixed(2));
        }

        // add the scope values together for the year total
        multiYear[key].total = multiYear[key].scope1 + multiYear[key].scope2 + multiYear[key].scope3;
      });

      newState.dashboard.data.multiYear = multiYear;

      return newState;
    }

    case Types.STORE_TARGETS: {
      let newState = _.cloneDeep(state);
      if (!newState.dashboard) {
        newState.dashboard = defaultState;
      }
      newState.dashboard.targets = action.payload;
      return newState;
    }

    default:
      return state;
  }
};

export default dashboardReducers;
