import React, { useEffect, useMemo, useState } from 'react';
import { addDays } from 'date-fns';
import { connect } from 'react-redux';
import { Actions } from '../../store/actions/dashboardActions';
import { AddButton } from '../../styledComponents/GlobalStyle';
import { Card, Divider, Tooltip } from '@mui/material';
import Select from 'react-select';
import { selectStyles } from '../../components/utils/ReactSelectStyles';
import DashboardCard from './DashboardCard';
import { PieChart, HierarchyChart, TreeMapChart } from './DashboardCharts';
import _ from 'lodash';

const PieChartSeriesInput = ({ onSeriesDataChange, data }) => {
  console.log('data', data);
  // State to hold the list of series data
  const [series, setSeries] = useState(data || [{ name: '', y: '' }]);

  // Function to handle input changes
  const handleInputChange = (index, field, value) => {
    const updatedSeries = [...series];
    updatedSeries[index][field] = field === 'y' ? parseFloat(value) : value;
    setSeries(updatedSeries);
    onSeriesDataChange(updatedSeries);
  };

  // Function to add a new input set
  const addSeries = () => {
    setSeries([...series, { name: '', y: '' }]);
  };

  // Function to remove a series input (optional)
  const removeSeries = (index) => {
    const updatedSeries = series.filter((_, i) => i !== index);
    setSeries(updatedSeries);
  };

  useEffect(() => {
    // This will update the series data if the data prop changes
    if (data) {
      setSeries(data);
      onSeriesDataChange(data);
    }
  }, [data]);

  return (
    <div>
      {series?.map((serie, index) => (
        <div key={index} style={{ display: 'flex', marginBottom: '10px', flexDirection: 'row' }}>
          <input
            type='text'
            className='form-control'
            placeholder='Name'
            value={serie.name}
            onChange={(e) => handleInputChange(index, 'name', e.target.value)}
            style={{ marginRight: '10px', width: '45%' }}
          />
          <input
            type='number'
            className='form-control'
            placeholder='Value'
            value={serie.y}
            onChange={(e) => handleInputChange(index, 'y', e.target.value)}
            style={{ marginRight: '10px', width: '45%' }}
          />
          {series.length > 1 && (
            <button className='btn btn-danger' onClick={() => removeSeries(index)}>
              Remove
            </button>
          )}
        </div>
      ))}

      <button onClick={addSeries} className='btn' style={{}}>
        + Add Series
      </button>
    </div>
  );
};

const DashboardModal = ({
  componentList,
  components,
  setDashboardModal,
  saveComponent,
  editComponent,
  dashboardComponents,
  dashboardModal,
  colors,
  branding,
  usageTotals,
}) => {
  const [selectedComponent, setSelectedComponent] = useState('Cards');
  const [editedState, setEditedState] = useState({});
  const [preview, setPreview] = useState(false);
  const [seriesData, setSeriesData] = useState([]);
  const { primaryColor, primaryButton, btnTextColor } = branding;
  const handleClose = () => {
    setDashboardModal(false);
    setPreview(false);
  };

  useEffect(() => {
    if (dashboardModal?.id) {
      const component = dashboardComponents.find((c) => c.id === dashboardModal?.id);
      if (component) {
        setEditedState(component);
      }
    }
    if (dashboardModal?.selectedComponent) {
      if (componentList.indexOf(dashboardModal?.selectedComponent) === -1) {
        componentList.push(dashboardModal?.selectedComponent);
      }
      setSelectedComponent(dashboardModal?.selectedComponent);
    }
  }, [dashboardModal]);

  console.log('editedState', editedState);

  console.log('selectedComponent', selectedComponent);

  const findOptionByValue = (groups, value) => {
    for (let group of groups) {
      for (let option of group.options) {
        if (option.value === value) {
          return option;
        }
      }
    }
    return null;
  };

  return (
    <div className='modal' style={{ display: 'block', background: 'rgba(0,0,0,.5)' }}>
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header'>
            <button onClick={handleClose} className='close'>
              x
            </button>
            <h4 className='modal-title'>{'Add New Component'}</h4>
          </div>
          <div className='modal-body' style={{ height: '80vh', overflow: 'hidden', display: 'flex' }}>
            <div className='dashboard-sidebar' style={{ width: '30%' }}>
              <ul className='dashboard-sidebar-list'>
                {componentList?.map((item, index) => (
                  <li key={index} className='dashboard-sidebar-item'>
                    <button
                      className='dashboard-sidebar-button'
                      style={{
                        '--dashboard-sidebar-button-background': selectedComponent == item ? primaryButton : 'transparent',
                        '--dashboard-sidebar-button': btnTextColor,
                        '--dashboard-sidebar-button-hover': selectedComponent !== item ? primaryButton : btnTextColor,
                      }}
                      onClick={() => {
                        setSelectedComponent(item);
                        setPreview(false);
                        setEditedState({});
                        setSeriesData([]);
                      }}>
                      {item
                        .split(/(?=[A-Z])/) // Split by capital letters
                        .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
                        .join(' ')}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
            <Divider orientation='vertical' flexItem />
            <div className='dashboard-sidebar-content' style={{ width: '70%', overflow: 'auto' }}>
              {components[selectedComponent]?.map((item, index) => {
                if (item.type === 'text') {
                  return (
                    <div key={index} className='form-group'>
                      <Tooltip
                        title={<p style={{ fontSize: 12 }}>{item?.tooltip}</p>}
                        arrow
                        componentsProps={{
                          tooltip: { sx: { backgroundColor: primaryColor } },
                          arrow: { sx: { color: primaryColor } },
                        }}>
                        <label>{item.label}</label>
                        {item?.tooltip ? (
                          <i className='fas fa-info-circle' style={{ marginLeft: 5, color: primaryColor }} />
                        ) : (
                          ''
                        )}
                      </Tooltip>
                      <input
                        type='text'
                        className='form-control'
                        name={item.name}
                        value={item.value || editedState[item.name] || ''}
                        onChange={(e) => {
                          item.value = e.target.value;
                          setEditedState({ ...editedState, [item.name]: e.target.value });
                        }}
                        disabled={item.disabled}
                      />
                    </div>
                  );
                } else if (item.type === 'select') {
                  return (
                    <div key={index} className='form-group'>
                      <Tooltip
                        title={<p style={{ fontSize: 12 }}>{item?.tooltip}</p>}
                        arrow
                        componentsProps={{
                          tooltip: { sx: { backgroundColor: primaryColor } },
                          arrow: { sx: { color: primaryColor } },
                        }}>
                        <label>{item.label}</label>
                        {item?.tooltip ? (
                          <i className='fas fa-info-circle' style={{ marginLeft: 5, color: primaryColor }} />
                        ) : (
                          ''
                        )}
                      </Tooltip>
                      <Select
                        options={item.options}
                        styles={{ selectStyles, width: '70%' }}
                        isClearable
                        menuPlacement={'auto'}
                        name={item.name}
                        value={
                          selectedComponent === 'Cards'
                            ? findOptionByValue(item.options, editedState[item.name])
                            : item.options?.filter((e) => e.value == editedState[item.name]) || null
                        }
                        onChange={(e) => {
                          const newEditedState = { ...editedState };
                          if (e?.value !== undefined || e?.value !== null) {
                            newEditedState[item.name] = e?.value;
                            if (selectedComponent === 'Cards') {
                              newEditedState['unit'] = e?.unit;
                              newEditedState['title'] = e?.label;
                            } else if (selectedComponent === 'treeMap') {
                              newEditedState['title'] = e?.label + ' Breakdown';
                            }
                          } else {
                            newEditedState[item.name] = null;
                            if (selectedComponent === 'Cards') {
                              newEditedState['unit'] = null;
                            }
                          }
                          setEditedState(newEditedState);
                        }}
                        disabled={item.disabled}
                      />
                    </div>
                  );
                } else if (item.type === 'series') {
                  return (
                    <div className='form-group'>
                      <label>Data Series</label>
                      <PieChartSeriesInput onSeriesDataChange={setSeriesData} data={editedState['data']} />
                    </div>
                  );
                } else if (item.type === 'chart') {
                  return (
                    <div key={index} className='form-group' style={{ display: 'flex', justifyContent: 'center' }}>
                      <Card
                        sx={{
                          width: '70%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          flexDirection: 'column',
                          borderRadius: 5,
                          '&:hover': {
                            backgroundColor: primaryButton,
                          },
                          border: '1px solid #E3E3E3',
                          boxShadow: 0,
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          if (componentList.indexOf(item.name) === -1) {
                            componentList.push(item.name);
                          }
                          setSelectedComponent(item.name);
                          setPreview(false);
                          setEditedState({});
                          setSeriesData([]);
                        }}>
                        <div style={{ display: 'flex', fontSize: 55, marginTop: 10 }}>{item.icon}</div>
                        <h5>{item.label}</h5>
                      </Card>
                    </div>
                  );
                }
                return null;
              })}{' '}
              {/*Return a preview of how the dashboard card would look like by calling the componenet and passing the values in editedState to it */}
              {selectedComponent === 'Cards' && (
                <div style={{ marginTop: '50px' }}>
                  <h4>Preview:</h4>
                  <DashboardCard
                    title={editedState?.title || 'Title'}
                    value={editedState?.value || 'Value'}
                    unit={editedState?.unit || 'Unit'}
                  />
                </div>
              )}
              {selectedComponent === 'pieChart' && (
                <div style={{ marginTop: '50px' }}>
                  <h4>Preview:</h4>
                  {preview ? (
                    <PieChart
                      title={editedState?.title || 'Title'}
                      data={seriesData || []} //JSON.parse(editedState?.data.replace(/([a-zA-Z0-9_]+):/g, '"$1":').replace(/'/g, '"')) || []}
                      valueSuffix={editedState?.valueSuffix || ''}
                      innerRadius={editedState?.innerRadius || 0}
                      dataLabels={editedState?.dataLabels || false}
                      legend={editedState?.legend || false}
                      colors={colors}
                      userAdded={false}
                    />
                  ) : seriesData?.length > 0 && seriesData[0].y ? (
                    <button
                      className='dashboard-sidebar-button'
                      style={{ color: primaryButton }}
                      onClick={() => {
                        setPreview(true);
                      }}>
                      Show Preview
                    </button>
                  ) : (
                    <p style={{ color: 'red' }}>Please enter a data series to view the preview</p>
                  )}
                </div>
              )}
              {selectedComponent === 'hierarchyChart' && (
                <div style={{ marginTop: '50px' }}>
                  <h4>Preview:</h4>
                  {preview ? (
                    <HierarchyChart
                      title={editedState?.title || 'Title'}
                      data={usageTotals || []}
                      valueSuffix={editedState?.valueSuffix || ''}
                      innerRadius={editedState?.innerRadius || 0}
                      dataLabels={editedState?.dataLabels || false}
                      legend={editedState?.legend || false}
                      colors={colors}
                      userAdded={false}
                    />
                  ) : (
                    <button
                      className='dashboard-sidebar-button'
                      style={{ color: primaryButton }}
                      onClick={() => {
                        setPreview(true);
                      }}>
                      Show Preview
                    </button>
                  )}
                </div>
              )}
              {selectedComponent === 'treeMap' && (
                <div style={{ marginTop: '50px' }}>
                  <h4>Preview:</h4>
                  {preview ? (
                    <TreeMapChart
                      title={editedState?.title || 'Title'}
                      data={editedState?.data || ''}
                      usageTotals={usageTotals}
                      colors={colors}
                      userAdded={false}
                    />
                  ) : editedState?.data ? (
                    <button
                      className='dashboard-sidebar-button'
                      style={{ color: primaryButton }}
                      onClick={() => {
                        setPreview(true);
                      }}>
                      Show Preview
                    </button>
                  ) : (
                    <p style={{ color: 'red' }}>Please select an entity to view the preview</p>
                  )}
                </div>
              )}
              <div
                style={{
                  display: selectedComponent && selectedComponent !== 'Charts' ? 'flex' : 'none',
                  justifyContent: 'center',
                  marginTop: '50px',
                }}>
                <AddButton
                  onClick={() => {
                    if (dashboardModal?.id) {
                      editComponent({ ...editedState, type: selectedComponent, id: dashboardModal?.id });
                      setDashboardModal({ show: false });
                    } else {
                      //Dispatch an action to save the component
                      selectedComponent == 'pieChart' && seriesData?.length > 0
                        ? saveComponent({ ...editedState, data: seriesData, type: selectedComponent })
                        : saveComponent({ ...editedState, type: selectedComponent });
                      setDashboardModal({ show: false });
                    }
                  }}>
                  {dashboardModal?.id ? 'Edit Component' : 'Add Component'}
                </AddButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { metrics: orgMetrics, details, currencies } = state?.organisation || {};
  const { palette } = state.branding?.colors || {};
  const usageTotals = state?.usageTotals;
  const dashboardComponents = state?.dashboard?.components;
  const orgIndustry = state?.organisation?.details?.industry;
  const metrics = state?.metric?.list;
  const industries = state?.organisation?.industries;
  const industry = industries?.find((i) => i.id == orgIndustry);
  const dashboardData = state?.dashboard?.data;
  const latestMetric = orgMetrics?.reduce((latest, current) => {
    return new Date(current.endDate) > new Date(latest.endDate) ? current : latest;
  }, orgMetrics[0]);

  const metricNames = ['intensityRatioMetric1ID', 'intensityRatioMetric2ID', 'intensityRatioMetric3ID'].map((ir, index) => {
    const metricID = industry?.[ir];
    const metric = metrics?.find((metric) => metric?.id !== '230393' && metric?.id === metricID);

    if (metric) {
      return { index: `metric${index + 1}`, title: metric?.description, units: metric?.name };
    }
  });

  const orgOptions = [
    {
      value: latestMetric?.originalTurnover || null,
      label: 'Revenue',
      unit: currencies?.find((c) => c.id == details.currency)?.symbol,
    },
    ...metricNames?.filter(Boolean)?.map((m, index) => ({
      value: latestMetric?.[m?.index] || null,
      label: m.title,
      unit: m.units,
    })),
  ];
  const usageOptions = [
    {
      value: dashboardData?.highestEmissions?.name,
      label: 'Activity with most Usage',
      unit: dashboardData?.highestEmissions?.total?.toFixed(2) + ' tCO2e',
    },
    {
      value: dashboardData?.lowestEmissions?.name,
      label: 'Activity with least Usage',
      unit: dashboardData?.lowestEmissions?.total?.toFixed(2) + ' tCO2e',
    },
  ];
  const groupedOptions = [
    { label: 'Organisation Metrics', options: orgOptions },
    { label: 'Usage Metrics', options: usageOptions },
  ];
  let componentList = ['Cards', 'Charts'];
  const components = {};
  components.Cards = [
    {
      type: 'text',
      label: 'Title',
      name: 'title',
    },
    {
      type: 'select',
      label: 'Value',
      name: 'value',
      options: groupedOptions,
    },
    {
      type: 'text',
      label: 'Units',
      name: 'unit',
      disabled: true,
    },
  ];
  components.Charts = [
    {
      type: 'chart',
      label: 'Line Chart',
      name: 'lineChart',
      icon: <i className='fa-solid fa-chart-line'></i>,
    },
    {
      type: 'chart',
      label: 'Bar Chart',
      name: 'barChart',
      icon: <i className='fa-solid fa-chart-column'></i>,
    },
    {
      type: 'chart',
      label: 'Pie Chart',
      name: 'pieChart',
      icon: <i className='fa-solid fa-chart-pie'></i>,
    },
    {
      type: 'chart',
      label: 'Hierarchy Chart',
      name: 'hierarchyChart',
      icon: <i className='fa-solid fa-sitemap'></i>,
    },
    {
      type: 'chart',
      label: 'Tree Map',
      name: 'treeMap',
      icon: <i className='fa-solid fa-table-cells-large'></i>,
    },
  ];
  components.pieChart = [
    {
      type: 'text',
      label: 'Chart Title',
      name: 'title',
    },
    {
      type: 'series',
      label: 'Data series',
      name: 'data',
      tooltip: 'Enter the data series for the pie chart',
    },
    {
      type: 'text',
      label: 'Tooltip Value Suffix',
      name: 'valueSuffix',
      tooltip: 'Text to be added after the value in the tooltip',
    },
    {
      type: 'text',
      label: 'Chart Inner Size (%)',
      name: 'innerRadius',
      tooltip: 'The percentage of the chart that you want to be visible',
    },
    {
      type: 'select',
      label: 'Enable Data Labels',
      name: 'dataLabels',
      options: [
        { value: true, label: 'True' },
        { value: false, label: 'False' },
      ],
      tooltip: 'Show the percentage of each slice on the chart',
    },
    {
      type: 'select',
      label: 'Enable Legend',
      name: 'legend',
      options: [
        { value: true, label: 'True' },
        { value: false, label: 'False' },
      ],
      tooltip: 'Show the legend for the chart data',
    },
  ];

  components.hierarchyChart = [
    {
      type: 'text',
      label: 'Chart Title',
      name: 'title',
    },
  ];

  components.treeMap = [
    {
      type: 'text',
      label: 'Chart Title',
      name: 'title',
      disabled: true,
    },
    {
      type: 'select',
      label: 'Entity',
      name: 'data',
      options:
        _.uniqBy(
          usageTotals?.map((t) => ({ value: t.entity, label: t.entityName })),
          'value'
        ) || [],
      tooltip: 'Choose an entity to see its breakdown',
    },
  ];
  return {
    componentList,
    components,
    dashboardComponents,
    colors: palette,
    branding: state.branding?.colors,
    usageTotals,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveComponent: (component) => dispatch(Actions.saveComponent(component)),
    editComponent: (component) => dispatch(Actions.editComponent(component)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardModal);
