import { Fade } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Pagination from '@mui/material/Pagination';
import TextField from '@mui/material/TextField';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Actions as ADMIN_ACTIONS } from '../../../store/actions/adminActions';
import { AddButton } from '../../../styledComponents/GlobalStyle';
import Breadcrumb from '../../Common/Breadcrumb';
import BulkEditModal from '../../Modals/BulkEditModal';
import { convertToTitleCase, getUsageTypeDetails, itemToOptions } from '../../utils/GlobalFunctions';
import { adminPortalSelect } from '../../utils/ReactSelectStyles';
import ConversionFactorsTable from './ConversionFactorsTable';

const filtersStyleInputs = {
  display: 'flex',
  width: '-webkit-fill-available',
  flexDirection: 'row',
  marginLeft: 10,
  marginRight: 10,
  marginBottom: 10,
  justifyContent: 'space-between',
  alignItems: 'center',
};

const ConversionFactors = ({
  bulkEditModal,
  toggleModal,
  branding,
  options,
  canEditAdminPortal,
  filterSize,
  itemCount,
  getItemsByTermsFromIndex,
  adminPortalModal,
  confirmationModal,
  getAllItemsFromDB,
  addOns,
  usageTypeDetails,
  subentities,
}) => {
  const { headers } = branding?.fonts[0]?.font || {};
  const tableName = 'conversionFactors';

  const [showHide, setShowHide] = useState(false);
  const [page, setPage] = useState(1);
  const [tableSize, setTableSize] = useState(filterSize?.[0]);
  const [totalItems, setTotalItems] = useState(null);
  const [filter, setFilter] = useState({});
  const [showPagination, setShowPagination] = useState(false);

  const handleFilter = (selectedFilter, e) => {
    const newFilter = { ...filter };
    const filterName = e?.target?.name || e.name;

    if (e?.action === 'clear') {
      delete newFilter[filterName];
    } else if (e?.target?.type === 'text') {
      newFilter[filterName] = e?.target?.value;
    } else if (filterName === 'entity') {
      //find subentities that have selectedFilter.value in their entity field
      const subentitiesForEntity = subentities?.filter((subentity) => subentity.entity.includes(selectedFilter?.value));
      //keep only the id from subentitiesforEntity
      if (subentitiesForEntity?.length > 0) {
        newFilter['subentity'] = _.uniq(subentitiesForEntity?.map((subentity) => subentity.id));
      }
    } else newFilter[filterName] = selectedFilter?.value;
    setFilter(newFilter);
  };

  const handlePageChange = (_, value) => {
    setPage(value);
    getItemsByTermsFromIndex(tableName, filter, value, tableSize?.value);
  };

  const handleTableSizeChange = (selected) => {
    setTableSize(selected);
    setPage(1);
    getItemsByTermsFromIndex(tableName, filter, 1, selected?.value);
  };

  useEffect(() => {
    (async () => {
      if (!addOns) {
        await getAllItemsFromDB('addOns');
      }
      if (!usageTypeDetails) {
        await getAllItemsFromDB('usageTypeDetails');
      }
    })();
    if (showHide) {
      setShowPagination(true);
    }
  }, [showHide, page, tableSize, adminPortalModal, confirmationModal, addOns, usageTypeDetails, getAllItemsFromDB]);

  const items = [{ label: 'Conversion Factors', link: '/admin/conversionfactors' }];
  const total = totalItems ? totalItems : itemCount;
  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Breadcrumb items={items} />
        <Box sx={{ display: 'flex', flexDirection: 'row', width: 'auto' }}>
          <div style={{ marginRight: '1.5vh', display: canEditAdminPortal ? 'block' : 'none' }}>
            <AddButton
              to={'/admin/conversionfactors'}
              className='btn btn-success'
              onClick={() => {
                // Set all usage types as null for the default state
                const defaultState =
                  Array.isArray(usageTypeDetails) &&
                  usageTypeDetails.reduce((acc, usageType) => {
                    acc[usageType.type] = null;
                    return acc;
                  }, {});
                toggleModal(true, 'conversionFactors-table-add', defaultState, null, {
                  filters: filter,
                  page,
                  tableSize: tableSize.value,
                });
              }}>
              Add a Conversion Factor
            </AddButton>
          </div>
        </Box>
      </Box>

      {!showHide && (
        <section className='panel' style={{ fontFamily: headers }}>
          <header
            className='panel-heading'
            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
            Conversion Factor Filters
          </header>
          <div className='panel-body' style={{ height: 'auto' }}>
            {Object.keys(options)?.map((optionType, index) => {
              const notUsageTypes = ['metric', 'addOns', 'year', 'subentity', 'entity'].includes(optionType);
              let label = convertToTitleCase(optionType);
              if (!notUsageTypes) {
                label = getUsageTypeDetails(usageTypeDetails, optionType)?.displayName;
              }
              return options[optionType] !== 'SearchByText' ? (
                <div key={index} style={filtersStyleInputs}>
                  <label style={{ fontSize: 16, marginBottom: 0 }}>{label}</label>
                  <div style={{ width: '80%' }}>
                    <Select
                      id={'select-' + optionType}
                      options={options?.[optionType]}
                      name={optionType}
                      style={adminPortalSelect}
                      onChange={(selected, e) => handleFilter(selected, e)}
                      isClearable={true}
                    />
                  </div>
                </div>
              ) : (
                <div key={index} style={filtersStyleInputs}>
                  <label style={{ fontSize: 16, marginBottom: 0 }}>{convertToTitleCase(optionType)}</label>
                  <div style={{ width: '80%' }}>
                    <TextField
                      id='outlined-basic'
                      onChange={(e) => handleFilter(null, e)}
                      label='Search By Id'
                      variant='outlined'
                      name='id'
                      sx={{ width: '100%' }}
                    />
                  </div>
                </div>
              );
            })}
            <div style={{ display: 'grid', placeItems: 'center', marginTop: 10 }}>
              <AddButton
                id='search-btn'
                className='btn'
                onClick={() => {
                  //Forces the page to 1 when searching
                  setPage(1);
                  getItemsByTermsFromIndex(tableName, filter, 1, tableSize?.value);
                  setShowHide(true);
                }}
                bordercolor='true'
                disabled={_.isEmpty(filter)}
                style={{ width: '20vh', fontSize: 16 }}>
                Search
              </AddButton>
            </div>
          </div>
        </section>
      )}

      {showHide && (
        <>
          <ConversionFactorsTable
            page={page}
            setTableSize={setTableSize}
            handleTableSizeChange={handleTableSizeChange}
            tableSize={tableSize}
            setTotalItems={setTotalItems}
            setShowHide={setShowHide}
            setFilter={setFilter}
            filter={filter}
            setPage={setPage}
            options={options}
            setShowPagination={setShowPagination}
            canEditAdminPortal={canEditAdminPortal}
          />

          {total > 0 && showPagination && (
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Pagination count={Math.ceil(total / tableSize?.value)} page={page} onChange={handlePageChange} />
            </div>
          )}
        </>
      )}

      {bulkEditModal && (
        <Modal
          aria-labelledby='transition-modal-title'
          aria-describedby='transition-modal-description'
          open={bulkEditModal}
          onClose={toggleModal}
          closeAfterTransition>
          <Fade in={bulkEditModal}>
            <div>
              <BulkEditModal />
            </div>
          </Fade>
        </Modal>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  const options = {};
  const optionTypes = state?.metric?.types;
  const entities = state?.entities;
  const subentities = state?.subentities;
  const metrics = state?.metric?.list;
  const addOns = state?.admin?.adminPortal?.addOns;
  const usageTypeDetails = state?.admin?.adminPortal?.usageTypeDetails;
  //const entities = state?.entities;

  options.year = [...new Array(new Date().getFullYear() - 2012)]
    .map((_, index) => {
      return {
        label: (2013 + index).toString(),
        value: (2013 + index).toString(),
      };
    })
    .sort((a, b) => b.label.localeCompare(a.label));
  options.entity = entities
    ?.concat({ name: '<null>', id: 'NA' })
    ?.map(itemToOptions)
    ?.sort((a, b) => a.label.localeCompare(b.label));
  options.subentity = subentities
    ?.concat({ name: '<null>', id: 'NA' })
    ?.map(itemToOptions)
    ?.sort((a, b) => a.label.localeCompare(b.label));

  optionTypes &&
    Object.keys(optionTypes)?.map((item) => {
      options[item] = optionTypes[item]
        ?.concat({ name: '<null>', id: 'NA' })
        ?.map(itemToOptions)
        ?.sort((a, b) => a.label.localeCompare(b.label));
      return options;
    });

  options.metric = metrics
    ?.concat({ name: '<null>', id: 'NA' })
    ?.map(itemToOptions)
    ?.sort((a, b) => a.label.localeCompare(b.label));
  options.addOns = addOns?.map(itemToOptions)?.sort((a, b) => a.label.localeCompare(b.label));

  return {
    options,
    bulkEditModal: state?.admin?.modal?.bulkEditModal,
    branding: state.branding,
    filterSize: state?.admin?.filterSize,
    itemCount: state?.admin?.termsItems?.totalCount,
    adminPortalModal: state.admin?.modal?.adminPortalModal,
    confirmationModal: state.admin?.modal?.confirmationModal,
    openSearchTerms: state?.admin?.openSearchTerms,
    addOns,
    usageTypeDetails,
    subentities,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAllItemsFromDB: (table) => dispatch(ADMIN_ACTIONS.getAllItemsFromDB(table)),
  toggleModal: (status, action, item, modalProps, notAutoClose, openSearchTerms) =>
    dispatch(ADMIN_ACTIONS.toggleModal(status, action, item, modalProps, notAutoClose, openSearchTerms)),
  getItemsByTermsFromIndex: (index, filters, page, size) =>
    dispatch(ADMIN_ACTIONS.getItemsByTermsFromIndex(index, filters, page, size)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConversionFactors);
