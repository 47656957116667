import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import HighchartsDrilldown from 'highcharts/modules/drilldown';

HighchartsDrilldown(Highcharts);

const EmissionsBySourceChart = ({ drilldownData, mainData, isMarket, updateCenterLabelMain, textColor }) => {
  const mainChartRef = useRef(null);
  const drilldownChartRef = useRef(null);

  const updateMainChartTotal = () => {
    const mainChart = mainChartRef.current?.chart;
    const drilldownChart = drilldownChartRef.current?.chart;

    if (!mainChart) return;

    const mainSeries = mainChart.series[0];
    const drilldownSeries = drilldownChart?.series[0];

    // Calculate drilldown total excluding hidden points
    const drilldownTotal = drilldownSeries?.data.reduce((sum, point) => {
      return point.visible ? sum + (isMarket ? point.co2eMarket : point.co2e) : sum;
    }, 0);

    // Calculate main total excluding hidden points
    const mainTotal = mainSeries.data.reduce((sum, point) => {
      if (point.name === 'Other') return sum;
      return point.visible ? sum + (isMarket ? point.co2eMarket : point.co2e) : sum;
    }, 0);

    // Combine both totals
    const combinedTotal = (mainTotal + (drilldownTotal || 0)).toFixed(0);

    // Update the custom label in the main chart
    let customLabel = mainChart.options.chart.custom.label;

    if (!customLabel) {
      customLabel = mainChart.options.chart.custom.label = mainChart.renderer
        .label(
          `<span style="font-size: 1.8em;" >
            <strong>${combinedTotal.replace(/(?<!\.\d*)(?<=\d)(?=(?:\d{3})+(?!\d))/g, ' ')}</strong></span><br/>tCO2e`
        )
        .css({
          color: '#000',
          textAnchor: 'middle',
          fontFamily: 'Open Sans',
        })
        .add();
    } else {
      customLabel.attr({
        text: `<span style="font-size: 1.8em;" ><strong>${combinedTotal.replace(
          /(?<!\.\d*)(?<=\d)(?=(?:\d{3})+(?!\d))/g,
          ' '
        )}</strong></span><br/>tCO2e`,
      });
    }

    const x = mainSeries.center[0] + mainChart.plotLeft;
    const y = mainSeries.center[1] + mainChart.plotTop - customLabel.attr('height') / 2;

    customLabel.attr({ x, y });
    customLabel.css({ fontSize: `${mainSeries.center[2] / 13}px` });
  };

  useEffect(() => {
    // Trigger the label update for the main chart
    if (mainChartRef.current) {
      const mainChart = mainChartRef.current.chart;
      updateCenterLabelMain(mainChart, isMarket);
    }
  }, []);

  useEffect(() => {
    const mainChart = mainChartRef.current.chart;
    const handleResize = () => updateCenterLabelMain(mainChart, isMarket);
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const mainChartOptions = {
    credits: false,
    chart: {
      type: 'pie',
      margin: [35, 0, 0, 0],
      custom: {
        drilldownData: drilldownData,
      },
      reflow: true,
      events: {
        render() {
          const chart = this;
          updateMainChartTotal();
          updateCenterLabelMain(chart, isMarket);
        },
        redraw: function () {
          const chart = this;
          updateMainChartTotal();
          updateCenterLabelMain(chart, isMarket);
        },
        load: function () {
          const chart = this;
          updateCenterLabelMain(chart, isMarket);
        },
      },
    },
    title: {
      text: 'Emissions by Activity',
      style: {
        fontSize: '18px',
        fontFamily: 'Open Sans',
        fontWeight: '600',
        color: textColor,
      },
    },
    tooltip: {
      formatter() {
        return `<b>${this.key}</b><br/>tCO2e: ${
          isMarket ? this.point.co2eMarket?.toFixed(2) : this.point.co2e?.toFixed(2)
        }<br/>`;
      },
      style: {
        fontSize: '12px',
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          distance: 15,
          alignTo: 'edges',
          style: {
            fontFamily: 'Open Sans',
            fontSize: '12px',
            textOverflow: 'clip',
            fontWeight: 600,
          },
          format: '<b>{point.name}</b>: {point.percentage:.2f}%',
        },
        //showInLegend: true,
      },
    },
    series: [
      {
        innerSize: '75%',
        name: 'Emissions By Source',
        colorByPoint: true,
        data: _.orderBy(
          mainData.filter((data) => (isMarket ? data.co2eMarket : data.co2e) > 0),
          [(data) => (data.name === 'Other' ? 0 : 1), isMarket ? 'co2eMarket' : 'co2e', 'name'],
          ['asc', 'desc', 'asc']
        ),
      },
    ],
    exporting: false,
  };

  const drilldownChartOptions = {
    credits: false,
    chart: {
      type: 'pie',
      backgroundColor: 'transparent',
      margin: [0, 0, 160, 0],
      spacing: [160, 0, 0, 0],
      custom: {},
      events: {
        render() {
          const chart = this,
            series = chart.series[0];
          let customLabel = chart.options.chart.custom.label;

          if (!customLabel) {
            customLabel = chart.options.chart.custom.label = chart.renderer
              .label(`<span style="font-size: 1.5em;" ><strong>Other<br/>Breakdown</strong></span>`)
              .css({
                color: '#000',
                textAnchor: 'middle',
                fontFamily: 'Roboto, sans-serif',
              })
              .add();
          }

          const x = series.center[0] + chart.plotLeft,
            y = series.center[1] + chart.plotTop - customLabel.attr('height') / 2;

          customLabel.attr({
            x,
            y,
          });
          // Set font size based on chart diameter
          customLabel.css({
            fontSize: `${series.center[2] / 13}px`,
          });
        },
        redraw: function () {
          const mainChart = mainChartRef.current?.chart;
          if (mainChart) {
            mainChart.redraw();
          }
        },
      },
    },
    title: { text: '' },
    tooltip: {
      formatter() {
        return `<b>${this.key}</b><br/>tCO2e: ${
          isMarket ? this.point.co2eMarket?.toFixed(2) : this.point.co2e?.toFixed(2)
        }<br/>`;
      },
      style: {
        fontSize: '12px',
      },
    },
    plotOptions: {
      pie: {
        size: '80%',
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: false,
          distance: 10,
          format: '<b>{point.name}</b>: {point.y:.2f}%',
        },
        showInLegend: true,
        //colors,
      },
    },
    legend: {
      enabled: true,
      layout: 'vertical',
      align: 'center',
      verticalAlign: 'bottom',
      maxHeight: 185,
      margin: 0,
      labelFormatter: function () {
        return this.name + ': ' + this.y.toFixed(2) + '%';
      },
      itemStyle: {
        textOverflow: undefined,
        fontWeight: 600,
      },
    },
    responsive: {
      rules: [
        {
          condition: {
            // Arbitrary condition, e.g., width or height. This ensures it triggers.
            callback: function () {
              const chart = this; // The current chart instance
              const legendItemCount = chart.legend.allItems.length;

              // Adjust condition based on number of legend items
              return legendItemCount > 7;
            },
          },
          chartOptions: {
            legend: {
              verticalAlign: 'bottom',
            },
          },
        },
        {
          condition: {
            callback: function () {
              const chart = this;
              const legendItemCount = chart.legend.allItems.length;

              // For fewer legend items, reset to center alignment
              return legendItemCount <= 7;
            },
          },
          chartOptions: {
            legend: {
              verticalAlign: 'middle',
            },
          },
        },
      ],
    },
    series: [
      {
        innerSize: '75%',
        name: 'Other Emissions Breakdown',
        colorByPoint: true,
        data: _.orderBy(
          drilldownData
            .map((item) => ({
              name: item.name,
              y: item.y,
              co2e: item.co2e,
              co2eMarket: item.co2eMarket,
              color: item.color,
            }))
            .filter((data) => (isMarket ? data.co2eMarket : data.co2e) > 0),
          [isMarket ? 'co2eMarket' : 'co2e', 'name'],
          ['desc', 'asc']
        ),
      },
    ],
    exporting: false,
  };

  if (!mainChartOptions || !mainChartOptions.series?.length) return null;

  return (
    <div style={{ display: 'flex', justifyContent: 'space-evenly', width: '100%', position: 'relative' }}>
      <div
        style={{
          display: 'block',
          width: drilldownChartOptions.series[0].data.length > 0 ? '75%' : '100%',
          marginRight: 80,
        }}>
        <HighchartsReact ref={mainChartRef} highcharts={Highcharts} options={mainChartOptions} />
      </div>
      <div
        style={{
          position: 'absolute',
          top: '0',
          right: '0',
          width: '20%',
        }}>
        {drilldownChartOptions.series[0].data.length > 0 && (
          <HighchartsReact ref={drilldownChartRef} highcharts={Highcharts} options={drilldownChartOptions} />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { co2eByEntity, co2eMarketByEntity, withMarketFactors, filter } = state.consumption;
  const { entities } = state;
  const colors = state.branding?.colors?.palette;
  const { textColor } = state.branding?.colors;

  const entityIDs = (co2eByEntity && Object.keys(co2eByEntity)) || [];
  const isMarket = withMarketFactors && !filter.includes('market');

  const chartData = entityIDs.map((entityID) => {
    const sliceData = isMarket ? co2eMarketByEntity[entityID] : co2eByEntity[entityID];
    const entityDetails = entities && entities.find(({ id }) => id === entityID);

    return {
      name: entityDetails?.name || 'Unknown',
      y: sliceData || 0,
      co2e: sliceData || 0,
      co2eMarket: co2eMarketByEntity[entityID] || 0,
    };
  });

  const updateCenterLabelMain = (chart, isMarket) => {
    const series = chart.series[0];

    // Accessing the drilldown data
    const drilldownData = chart.options.chart.custom.drilldownData;

    //Calculate the total CO2e for the drilldown data
    const drilldownTotal = drilldownData.reduce((sum, point) => {
      return sum + (isMarket ? point.co2eMarket : point.co2e);
    }, 0);

    // Calculate the total CO2e for the main chart data
    const mainTotal = series.data.reduce((sum, point) => {
      if (point.name === 'Other') return sum;
      return sum + (isMarket ? point.co2eMarket : point.co2e);
    }, 0);

    // Combine both totals
    const combinedTotal = (mainTotal + drilldownTotal).toFixed(0);

    let customLabel = chart.options.chart.custom.label;

    if (!customLabel) {
      customLabel = chart.options.chart.custom.label = chart.renderer
        .label(
          `<span style="font-size: 1.8em;" >
            <strong>${combinedTotal.replace(/(?<!\.\d*)(?<=\d)(?=(?:\d{3})+(?!\d))/g, ' ')}</strong></span><br/>tCO2e`
        )
        .css({
          color: '#000',
          textAnchor: 'middle',
          fontFamily: 'Open Sans',
        })
        .add();
    }

    const x = series.center[0] + chart.plotLeft;
    const y = series.center[1] + chart.plotTop - customLabel.attr('height') / 2;

    customLabel.attr({ x, y });
    // Set font size based on chart diameter
    customLabel.css({ fontSize: `${series.center[2] / 13}px` });
  };

  const threshold = 3; // Threshold percentage

  const processChartData = (data, threshold, colors) => {
    let mainData = [];
    let drilldownData = [];
    let otherTotal = 0;
    let otherTotalMarket = 0;
    const usedColors = new Set(); // Set to track colors used in the main chart

    const getValue = (point) => (isMarket ? point.co2eMarket : point.co2e);
    // Calculate the total CO2e for the main chart
    const totalCo2e = data.reduce((sum, d) => sum + getValue(d), 0);

    // Sort data by CO2e
    data = _.orderBy(data, [(d) => d.co2e], ['desc']);

    // Process each point to determine whether it should go into the main chart or drilldown data
    data.forEach((point) => {
      const value = point.co2e;
      const percentage = (value / totalCo2e) * 100;

      if (percentage < threshold && mainData.length > 7) {
        drilldownData.push(point);
        otherTotal += point.co2e;
        otherTotalMarket += point.co2eMarket;
      } else {
        // Assign a color to this point and add it to usedColors
        const color = colors?.[mainData?.length % colors?.length];
        usedColors.add(color);
        mainData.push({
          ...point,
          color,
          co2e: point.co2e,
          co2eMarket: point.co2eMarket,
        });
      }
    });

    // Add the 'Other' point to the main data
    mainData.push({
      name: 'Other',
      y: otherTotal,
      co2e: otherTotal,
      co2eMarket: otherTotalMarket,
      color: '#FF0000',
      sliced: true,
    });

    // Adjust drilldown data to reflect percentages within 'Other'
    const otherPercentage = (otherTotal / totalCo2e) * 100;
    drilldownData = _.orderBy(drilldownData, [(d) => d.co2e], ['desc']);
    drilldownData.forEach((point, index) => {
      point.y = (point.co2e / otherTotal) * otherPercentage;

      // Assign a color that hasn't been used in mainData
      const availableColors = colors?.filter((color) => !usedColors?.has(color));
      point.color = availableColors[index % availableColors?.length];
    });

    return { mainData, drilldownData };
  };

  const { mainData, drilldownData } = processChartData(chartData, threshold, colors, isMarket);

  return { drilldownData, mainData, isMarket, updateCenterLabelMain, textColor };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(EmissionsBySourceChart);
