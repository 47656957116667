import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { processTableID, showLoader, toggleAlertMessage, updateProgressBar } from '../action.js';
import { Actions as ADMIN_ACTIONS } from './adminActions';
import createSignedRequest from './createSignedRequest';
import { Actions as FACILITY_ACTIONS } from './facilityActions';
import { checkReportingYearEndIssue } from './organisationActions';
const { apiURL } = require('./apiURL');

const Types = {
  USAGE_DELETE: 'USAGE_DELETE',
  USAGE_UPDATE: 'USAGE_UPDATE',
  USAGE_UPDATE_TOTALS: 'USAGE_UPDATE_TOTALS',
  USAGE_TOGGLE_STATUS: 'USAGE_TOGGLE_STATUS',

  USAGE_EDIT_MODAL: 'USAGE_EDIT_MODAL',
  USAGE_SHOW_DATA_MODAL: 'USAGE_SHOW_DATA_MODAL',
  USAGE_SHOW_DATA_DELETE_MODAL: 'USAGE_SHOW_DATA_DELETE_MODAL',
  USAGE_INVOICE_MODAL: 'USAGE_INVOICE_MODAL',
  USAGE_SAVE_INVOICES: 'USAGE_SAVE_INVOICES',

  USAGE_UPLOAD_TYPE: 'USAGE_UPLOAD_TYPE',
  USAGE_GET_TEMPALATES: 'USAGE_GET_TEMPLATES',
  USAGE_IMPORT_LINK: 'USAGE_IMPORT_LINK',
  USAGE_IMPORT_SHOW_MODAL: 'USAGE_IMPORT_SHOW_MODAL',
  USAGE_IMPORT_SHOW_HISTORY: 'USAGE_IMPORT_SHOW_HISTORY',
  USAGE_MY_UPLOADS: 'USAGE_MY_UPLOADS',
  USAGE_OPTIONS: 'USAGE_OPTIONS',
  USAGE_IMPORT_ANALYSIS: 'USAGE_IMPORT_ANALYSIS',
  LIST_USAGE_DETAILS: 'USAGE_LIST_DETAILS',
  USAGE_PREVIOUS_DATES: 'USAGE_PREVIOUS_DATES',
};

const updateUsageData = (payload) => ({
  type: Types.USAGE_UPDATE,
  payload,
});

const setPreviousDates = (newUsage) => ({ type: Types.USAGE_PREVIOUS_DATES, newUsage });

export const showAnalysisImportModal = (status, payload) => ({
  type: Types.USAGE_IMPORT_ANALYSIS,
  status,
  payload,
});

const saveUsageData = (usage, adminPortal) => {
  const usageData = usage?.map((u) => ({
    id: u?.id || null,
    facility: u?.facility?.value || u?.facility,
    entity: u?.entity?.value || u?.entity,
    dateStart: u?.dateStart,
    dateEnd: u?.dateEnd,
    userLabel: u?.userLabel || null,
    subentity: u?.subentity?.value || u?.subentity,
    metric: u?.metric?.value || u?.metric,
    production: u?.production?.value || u?.production || null,
    fuel: u?.fuel?.value || u?.fuel || null,
    load: u?.load?.value || u?.load || null,
    seat: u?.seat?.value || u?.seat || null,
    originlocation: u?.originlocation?.value || u?.originlocation || null,
    finallocation: u?.finallocation?.value || u?.finallocation || null,
    factorprovider: u?.factorprovider?.value || u?.factorprovider || null,
    price: u?.price?.value || u?.price || null,
    organisation: u?.organisation?.value || u?.organisation,
    vehicle: u?.vehicle?.value || u?.vehicle || null,
    waste: u?.waste?.value || u?.waste || null,
    usage: parseFloat(u?.usage),
    waterTreatment: u?.waterTreatment || null,
    solarConsumed: u?.solarConsumed || null,
    fuel_mix: u?.fuel_mix ? JSON.stringify(u?.fuel_mix) : null,
    currency: u?.currency || null,
    isSpend: u?.isSpend || null,
  }));

  const usageDataLength = usageData.length;
  let chunked_usage;

  if (usageDataLength > 200) {
    chunked_usage = _.chunk(usageData, 200);
  } else if (adminPortal) {
    //chunk by organisation.
    chunked_usage = usageData.reduce((acc, item) => {
      const index = acc.findIndex((chunk) => chunk[0].organisation === item.organisation);

      if (index !== -1) {
        acc[index].push(item);
      } else {
        acc.push([item]);
      }

      return acc;
    }, []);
  } else {
    chunked_usage = [];
    chunked_usage.push(usageData);
  }

  return async (dispatch, getState) => {
    const state = getState();

    let response;

    dispatch(updateProgressBar(0));

    try {
      const tableIDs = [];

      for (const usageArr of chunked_usage) {
        const tableID = uuidv4();
        tableIDs.push(tableID);
        let orgDetails = state?.organisation?.details;
        if (adminPortal) orgDetails = _.find(state?.admin?.organisations, { id: usageArr[0].organisation });
        const { reportingYearEndMonth, reportingYearEndDay } = orgDetails;

        response = await axios(
          await createSignedRequest(
            'POST',
            apiURL + `/my/usage/${reportingYearEndDay}/${reportingYearEndMonth}/${tableID}`,
            JSON.stringify(usageArr),
            {
              Organisation: orgDetails.id,
            }
          )
        );

        if (!response.data.success) {
          dispatch(toggleAlertMessage(true, response.data.message));
          return;
        }
      }

      const processedCounts = new Array(tableIDs.length).fill(0);
      const chunkedUsageLength = chunked_usage.length;

      const usageParamsObj = {
        processedCounts,
        chunkedDataLength: chunkedUsageLength,
        totalDataLength: usageDataLength,
        state,
        dispatch,
        type: 'usage',
      };

      await Promise.all(tableIDs.map(async (tableID, index) => await processTableID(tableID, { ...usageParamsObj, index })));

      if (!adminPortal && usageDataLength === 1) dispatch(setPreviousDates(usageData[0]));

      const newState = getState();

      if (adminPortal && newState.progress === 100) {
        const { filters, page, tableSize } = state?.admin?.openSearchTerms;
        setTimeout(async () => {
          dispatch(ADMIN_ACTIONS.getItemsByTermsFromIndex('usageData', filters, page, tableSize)); // this triggers the fetch of the new/edited CF from the CF admin page
        }, 1500);
      } else if (state?.profile?.details?.cyfAdmin && getState()?.usageData?.length > 0)
        dispatch(ADMIN_ACTIONS.initializeCheckBoxes());
    } catch (e) {
      console.log('saveUsageData Error:', e);
    }
  };
};

const dispatchDeleteUsageData = (payload) => ({
  type: Types.USAGE_DELETE,
  payload,
});

function filterProperties(dataArray, properties) {
  return dataArray.map((item) => {
    let newItem = {};
    properties.forEach((prop) => {
      if (item.hasOwnProperty(prop)) {
        newItem[prop] = item[prop];
      }
    });
    return newItem;
  });
}

const deleteUsageData = (items, adminPortal) => {
  const usageDataLength = items.length;
  let chunked_usage;

  items = filterProperties(items, ['source', 'organisation', 'invoiceLineItemId', 'id', 'isParent']);

  if (usageDataLength > 100) {
    chunked_usage = _.chunk(items, 100);
  } else {
    chunked_usage = [];
    chunked_usage.push(items);
  }

  const numOfChunks = chunked_usage.length;

  return async (dispatch, getState) => {
    const state = getState();

    let response;
    let counter = 0;

    dispatch(updateProgressBar(0));
    let deletedItems = [];
    try {
      for (const usageArr of chunked_usage) {
        response = await axios(
          await createSignedRequest('DELETE', apiURL + `/my/usage`, JSON.stringify(usageArr), {
            Organisation: String(state.currentOrganisation),
          })
        );

        counter++;
        const perecentage = (counter / numOfChunks) * 100;
        const tempArray = response?.data?.deletedItems;
        if (tempArray?.length > 0) {
          deletedItems = deletedItems.concat(tempArray);
        }
        dispatch(updateProgressBar(perecentage));
      }

      console.log('deletedUsageData', response);
      if (!adminPortal) dispatch(dispatchDeleteUsageData(deletedItems));

      if (!response.data.success && items.length >= 2) {
        dispatch(toggleAlertMessage(true, response.data.message, 'error'));
      } else dispatch(toggleAlertMessage(true, response.data.message, 'success'));

      if (!adminPortal) dispatch(updateUsageTotals(null, true));
      dispatch(showUsageDataDeleteModal(false, null));

      if (adminPortal) {
        const { filters, page, tableSize } = state?.admin?.openSearchTerms;
        setTimeout(async () => {
          dispatch(ADMIN_ACTIONS.getItemsByTermsFromIndex('usageData', filters, page, tableSize)); // this triggers the fetch of the new/edited CF from the CF admin page
        }, 1500);
      } else if (state?.profile?.details?.cyfAdmin && getState()?.usageData?.length > 0)
        dispatch(ADMIN_ACTIONS.initializeCheckBoxes());

      dispatch(checkReportingYearEndIssue(state.currentOrganisation));
    } catch (e) {
      console.log('deletedUsageData Error:', e);
    }

    setTimeout(function () {
      dispatch(ADMIN_ACTIONS.toggleModal(false, null));
    }, 2000);
  };
};

const listUsageDetails = (response) => ({
  type: Types.LIST_USAGE_DETAILS,
  payload: response,
});

const getUsageById = (id) => {
  return async (dispatch, getState) => {
    dispatch(showLoader(true));
    try {
      const response = await axios(await createSignedRequest('GET', apiURL + `/my/usage?usageId=${id}`, null, null));
      console.log('getUsageById', response);
      dispatch(listUsageDetails(response.data));
    } catch (e) {
      console.log('getUsageById Error:', e);
    }
    dispatch(showLoader(false));
  };
};

const updateUsageTotals = (response, flag) => ({
  type: Types.USAGE_UPDATE_TOTALS,
  response,
  flag,
});

const toggleProcessingUsage = (saving) => ({
  type: Types.USAGE_TOGGLE_STATUS,
  saving,
});

const getUsageTotals = () => {
  return async (dispatch, getState) => {
    const state = getState();

    const currentOrganisation = state.currentOrganisation;

    const { reportingYearEndMonth, reportingYearEndDay } = state?.organisation?.details || {};

    if (!reportingYearEndMonth || !reportingYearEndDay) {
      dispatch(updateUsageTotals([], false));
      return;
    }

    dispatch(FACILITY_ACTIONS.getFacilities());

    dispatch(toggleProcessingUsage(true));
    try {
      const response = await axios(
        await createSignedRequest('GET', apiURL + `/my/usage/${reportingYearEndDay}/${reportingYearEndMonth}/totals`, null, {
          Organisation: String(currentOrganisation),
        })
      );

      const url = response?.data;

      const usageData = await fetch(url).then((res) => res.json());

      dispatch(updateUsageTotals(usageData, false));
      if (state?.profile?.details?.cyfAdmin && getState()?.usageData?.length > 0) {
        dispatch(ADMIN_ACTIONS.initializeCheckBoxes());
      }

      dispatch(checkReportingYearEndIssue(currentOrganisation));
    } catch (e) {
      console.log('getUsage Totals Error:', e);
    }
  };
};

const showUsageDataDeleteModal = (status, item) => ({
  type: Types.USAGE_SHOW_DATA_DELETE_MODAL,
  status,
  item,
});

export const editUsageModal = (selectedInput, event) => ({
  type: Types.USAGE_EDIT_MODAL,
  selectedInput,
  event,
});

const showUsageDataModal = (status, item, adminPortal) => ({
  type: Types.USAGE_SHOW_DATA_MODAL,
  status,
  item,
  adminPortal,
});

const uploadUsageType = (response) => ({
  type: Types.USAGE_UPLOAD_TYPE,
  payload: response,
});

const setTemplateOptions = (response) => ({
  type: Types.USAGE_GET_TEMPALATES,
  payload: response,
});

const usageImportUploadLink = (response) => ({
  type: Types.USAGE_IMPORT_LINK,
  payload: response,
});

const getUploadLink = (file, action) => {
  return async (dispatch, getState) => {
    const state = getState();
    const uploadObj = {
      name: file.name,
      type: file.type,
    };

    if (action === 'deleteOrgs') {
      uploadObj.action = 'deleteOrgs';
    } else if (action === 'importMixedTemplates') {
      uploadObj.action = 'importMixedTemplates';
    } else if (action === 'invoices') {
      uploadObj.action = 'invoices';
    }

    try {
      const response = await axios(
        await createSignedRequest('POST', apiURL + '/my/upload', JSON.stringify(uploadObj), {
          Organisation: String(state.currentOrganisation),
        })
      );

      console.log('getUploadLink', response);
      dispatch(usageImportUploadLink(response.data));
    } catch (e) {
      console.log('getUploadLink Error:', e);
    }
  };
};

const loadMyUploads = (response) => ({
  type: Types.USAGE_MY_UPLOADS,
  payload: response,
});

const getMyUploads = () => {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios(
        await createSignedRequest('GET', apiURL + '/my/uploads', null, {
          Organisation: String(state.currentOrganisation),
        })
      );

      console.log('getMyUploads', response);
      dispatch(loadMyUploads(response.data));
    } catch (e) {
      console.log('getMyUploads Error:', e);
    }
  };
};

const confirmUploadEstimate = (id) => {
  return async (dispatch, getState) => {
    dispatch(showLoader(true));
    const state = getState();

    const upload = _.find(state.myUploads.uploads, { id });

    try {
      const tableID = uuidv4();
      const response = await axios(
        await createSignedRequest(
          'POST',
          apiURL + '/my/import',
          JSON.stringify({ uploadId: id, key: upload.outputKey, type: upload.type, estimate: false, tableID: tableID }),
          { Organisation: String(state.currentOrganisation) }
        )
      );

      console.log('confirmUploadEstimate', response);
      dispatch(toggleAlertMessage(true, 'We are currently preparing your Import...', 'success', 12000));
      const importUsageParamsObj = { state, dispatch, type: 'importUsage' };
      await processTableID(tableID, importUsageParamsObj);
      if (state?.profile?.details?.cyfAdmin && getState()?.usageData?.length > 0) {
        dispatch(ADMIN_ACTIONS.initializeCheckBoxes());
      }
    } catch (e) {
      dispatch(toggleAlertMessage(true, 'Your import failed!', 'error', 5000));
    }
  };
};

const processUpload = (file, action, params) => {
  return async (dispatch, getState) => {
    const state = getState();

    dispatch(showLoader(true));
    const importUploadKey = state.importUploadKey;
    const importUploadLink = state.importUploadLink;
    const usageUploadType = state.usageUploadType;

    dispatch(toggleAlertMessage(true, 'Your File Is Being Uploaded!', 'success', 2000));
    const tableID = uuidv4();

    const input = {
      key: importUploadKey,
      type: usageUploadType.value,
      tableID,
      estimate: true,
    };

    if (action === 'deleteOrgs') {
      input.action = action;
      input.uploadType = 'deleteOrgs';
    } else if (action === 'importMixedTemplates') {
      input.action = action;
      input.uploadType = 'importMixedTemplates';
    } else if (action === 'invoices') {
      input.action = action;
      input.uploadType = 'invoices';
      input.invoiceType = params?.type;
      input.company = params?.supplier;
      input.documentName = params?.documentName;
    } else {
      // Import Usage Data
      input.uploadType = 'importUsage';
    }

    // step 1 upload to AWS
    fetch(importUploadLink, {
      credentials: 'include',
      method: 'PUT',
      body: file,
      headers: {
        Accept: 'application/json',
        'Content-Type': file.type,
      },
    })
      .then(async () => {
        try {
          const response = await axios(
            await createSignedRequest('POST', apiURL + '/my/import', JSON.stringify(input), {
              Organisation: String(state.currentOrganisation),
            })
          );
          console.log('File Upload Succesful', response);
          dispatch(toggleAlertMessage(true, 'We are currently preparing your Import...', 'success', 12000));
          const importUsageParamsObj = { state, dispatch, type: input.uploadType };
          await processTableID(tableID, importUsageParamsObj);
          if (state?.profile?.details?.cyfAdmin && getState()?.usageData?.length > 0) {
            dispatch(ADMIN_ACTIONS.initializeCheckBoxes());
          }
        } catch (e) {
          console.log('File Upload Error:', e);
          dispatch(toggleAlertMessage(true, 'Your File upload has failed!', 'error', 5000));
          dispatch(showInvoiceModal(false));
        }
      })
      .catch((e) => {
        console.log('Usage Upload Template Upload Error:', e);
        dispatch(toggleAlertMessage(true, 'Your File upload has failed!', 'error', 5000));
        dispatch(showInvoiceModal(false));
        dispatch(showLoader(false));
      });
  };
};

const getFilteredFactors = (dEnd, subentity, gm, metric, organisationID) => {
  return async (dispatch, getState) => {
    const state = getState();
    dispatch(showLoader(true));

    try {
      const response = await axios(
        await createSignedRequest('GET', apiURL + `/my/usage/${dEnd}/${subentity}/${gm}/${metric}/options`, null, {
          Organisation: organisationID ? String(organisationID) : String(state.currentOrganisation),
        })
      );

      const { conversionFactors, gridSources } = response.data;

      response.data.conversionFactors.forEach((cf) => {
        if (cf?.discontinuedDate < moment(dEnd).format('YYYY-MM-DD')) {
          response.data.conversionFactors.splice(response.data.conversionFactors.indexOf(cf), 1);
        }
      });

      if (conversionFactors.err || gridSources.err) return;
      await dispatch(ADMIN_ACTIONS.getAllItemsFromDB('usageTypeDetails'));
      dispatch(updateUsageOptions(response.data));
      dispatch(showLoader(false));
    } catch (e) {
      console.log('updateUsageOptions Error:', e);
      dispatch(showLoader(false));
    }
  };
};

const showUploadUsageDataModal = (status) => ({
  type: Types.USAGE_IMPORT_SHOW_MODAL,
  status,
});

const showUploadHistory = (status) => ({
  type: Types.USAGE_IMPORT_SHOW_HISTORY,
  status,
});

const updateUsageOptions = (payload) => ({
  type: Types.USAGE_OPTIONS,
  payload,
});

const showInvoiceModal = (status, action, item) => ({
  type: Types.USAGE_INVOICE_MODAL,
  status,
  action,
  item,
});

const saveInvoices = (payload) => ({
  type: Types.USAGE_SAVE_INVOICES,
  payload,
});

const getInvoices = () => {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios(
        await createSignedRequest('GET', apiURL + `/my/invoices`, null, {
          Organisation: String(state.currentOrganisation),
        })
      );

      console.log('getInvoices', response);
      dispatch(saveInvoices(response.data));
    } catch (e) {
      console.log('getInvoices Error:', e);
    }
  };
};

const saveInvoice = (invoice) => {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios(
        await createSignedRequest('POST', apiURL + '/my/invoice', JSON.stringify(invoice), {
          Organisation: String(state.currentOrganisation),
        })
      );

      console.log('saveInvoice', response);
      dispatch(getInvoices());
      dispatch(showInvoiceModal(false));
    } catch (e) {
      console.log('saveInvoice Error:', e);
      toggleAlertMessage(true, 'There was an error saving the invoice', 'error');
    }
  };
};

const fetchInvoice = (key) => {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios(
        await createSignedRequest('GET', apiURL + `/my/invoice/${encodeURIComponent(key)}`, null, {
          Organisation: String(state.currentOrganisation),
        })
      );

      console.log('fetchInvoice', response);
      return response?.data;
    } catch (e) {
      console.log('fetchInvoice Error:', e);
      toggleAlertMessage(true, 'There was an error fetching the invoice', 'error');
    }
  };
};

const deleteInvoice = (id) => {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios(
        await createSignedRequest('DELETE', apiURL + `/my/invoice/${id}`, '{}', {
          Organisation: String(state.currentOrganisation),
        })
      );

      console.log('deleteInvoice', response);
      dispatch(getInvoices());
      dispatch(showInvoiceModal(false));
    } catch (e) {
      console.log('deleteInvoice Error:', e);
      toggleAlertMessage(true, 'There was an error deleting the invoice', 'error');
    }
  };
};

const getTemplates = () => {
  return async (dispatch, getState) => {
    dispatch(showLoader(true));

    try {
      const response = await axios(await createSignedRequest('GET', apiURL + `/my/templates`));

      dispatch(setTemplateOptions(response.data));
      dispatch(showLoader(false));
      return response.data;
    } catch (error) {
      dispatch(showLoader(false));
      console.error('Error:', error);
    }
  };
};

const Actions = {
  getTemplates,
  saveUsageData,
  getUsageTotals,
  toggleProcessingUsage,
  showUsageDataModal,
  showUsageDataDeleteModal,
  showAnalysisImportModal,
  deleteUsageData,
  uploadUsageType,
  processUpload,
  confirmUploadEstimate,
  showUploadUsageDataModal,
  showUploadHistory,
  getUploadLink,
  getMyUploads,
  getFilteredFactors,
  editUsageModal,
  getUsageById,
  updateUsageData,
  updateUsageTotals,
  showInvoiceModal,
  getInvoices,
  saveInvoice,
  fetchInvoice,
  deleteInvoice,
};

export { Actions, Types };
