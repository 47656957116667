import React, { useEffect, useRef, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';
import TreeMap from 'highcharts/modules/treemap';
import Treegraph from 'highcharts/modules/treegraph';
import { Actions } from '../../store/actions/dashboardActions';
import { connect } from 'react-redux';
import _ from 'lodash';

// Initialize exporting modules
HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);
TreeMap(Highcharts);
Treegraph(Highcharts);

const PieCharts = ({
  id,
  title,
  data,
  innerRadius,
  valueSuffix,
  dataLabels,
  legend,
  colors,
  setDashboardModal,
  userAdded = true,
  removeComponent,
}) => {
  const chartRef = useRef(null);

  data?.forEach((item, index) => {
    item.color = colors[index];
  });

  const options = {
    credits: false,
    chart: {
      type: 'pie',
      borderRadius: 15,
      spacing: [20, 20, 20, 20],
      custom: {},
    },
    title: {
      text: title,
      style: {
        fontSize: '18px',
        fontFamily: 'Open Sans',
        fontWeight: '600',
        color: '#130834',
      },
    },
    plotOptions: {
      pie: {
        innerSize: innerRadius,
        depth: 0,
        dataLabels: {
          enabled: dataLabels,
          format: '{point.percentage:.1f}%',
          style: {
            fontSize: '12px', // Adjust font size here
          },
        },
        //borderRadius: '15px',
        showInLegend: true,
      },
    },
    legend: {
      enabled: legend,
      useHTML: true,
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'middle',
      x: -5,
      y: 10,
      labelFormatter: function () {
        return this.name + `<br/><span style="margin-top: 4;"><strong>${this.percentage.toFixed(2)}%</strong></span>`;
      },
      navigation: {
        enabled: false, // Disable legend pagination
      },
      squareSymbol: false,
      symbolPadding: 5,
      symbolWidth: 18,
      symbolHeight: 4,
      symbolRadius: 0,
    },
    tooltip: {
      useHTML: true,
      formatter: function () {
        return `
        <strong>${this.point.name}</strong><br>
        <span style="font-size: 12px;"><span style="color:${this.color};">\u25CF</span> ${this.series.name}: 
        <strong>${this.point.y.toFixed(2)}</strong> ${valueSuffix}
      `;
      },
    },
    series: [
      {
        name: 'Data',
        data: data,
      },
    ],
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 600,
          },
          chartOptions: {
            legend: {
              layout: 'horizontal',
              align: 'center',
              verticalAlign: 'bottom',
            },
          },
        },
      ],
    },
    exporting: {
      enabled: true,
    },
  };

  return (
    <div style={{ position: 'relative' }}>
      <div style={{ zIndex: 2, display: userAdded ? 'flex' : 'none', position: 'absolute', right: 0, top: 0 }}>
        <button
          style={{ border: '0px', background: 'inherit', padding: '8px 15px' }}
          onClick={() => {
            removeComponent(id);
          }}>
          <i className='fa-solid fa-xmark' style={{ fontSize: '15px' }}></i>
        </button>
      </div>
      <HighchartsReact highcharts={Highcharts} options={options} ref={chartRef} />
      <div
        style={{
          zIndex: 2,
          display: userAdded ? 'flex' : 'none',
          position: 'absolute',
          right: 0,
          bottom: 0,
        }}>
        <button
          style={{ border: '0px', background: 'inherit', padding: '10px 15px' }}
          onClick={() => {
            setDashboardModal({ show: true, id: id, selectedComponent: 'pieChart' });
          }}>
          <i class='fa-solid fa-pencil' style={{ fontSize: '18px' }}></i>
        </button>
      </div>
    </div>
  );
};

const HierarchyCharts = ({ id, title, data, setDashboardModal, colors, userAdded = true, removeComponent }) => {
  const chartRef = useRef(null);

  function buildHierarchy(data) {
    const hierarchy = [];

    // Group by entity
    const entityMap = new Set();
    data.forEach((item) => {
      const entityId = item.entity;
      const subentityId = item.subentity;

      // Check if entity already exists in the map
      if (!entityMap.has(entityId)) {
        // Create a new entity (parent node)
        hierarchy.push({
          id: entityId,
          name: item?.entityName, // Name of the entity
        });
        entityMap.add(entityId);
      }

      // Add subentity as a child to the entity
      hierarchy.push({
        id: subentityId,
        name: item?.subentityName, // Name of the subentity
        parent: entityId, // Reference the entity as the parent
        value: item?.usageValue, // Value of the subentity
        metricName: item?.metricName, // Name of the metric
      });
    });

    console.log('hierh: ', hierarchy);
    return hierarchy;
  }

  data.forEach((item, index) => {
    item.color = colors[index];
  });

  const options = {
    credits: false,
    chart: {
      height: 400,
      inverted: true,
    },
    title: {
      text: title || '',
    },
    series: [
      {
        type: 'treegraph',
        data: buildHierarchy(data).slice(0, 7),
        //allowTraversingTree: true, // Allow collapsing and expanding nodes
        marker: {
          symbol: 'rect',
          width: 50,
          height: 80,
          fillColor: '#cdcdcd', //'transparent',
          style: {
            borderRadius: '5px',
          },
          borderRadius: 5,
        },
        dataLabels: {
          pointFormat: '{point.name} ({point.value} {point.metricName})',
        },
        //colorByPoint: true,
      },
    ],
    tooltip: {
      outside: true,
    },
    exporting: {
      allowHTML: true,
      sourceWidth: 800,
      sourceHeight: 600,
    },
  };

  return (
    <div style={{ position: 'relative' }}>
      <div style={{ zIndex: 2, display: userAdded ? 'flex' : 'none', position: 'absolute', right: 0, top: 0 }}>
        <button
          style={{ border: '0px', background: 'inherit', padding: '8px 15px' }}
          onClick={() => {
            removeComponent(id);
          }}>
          <i className='fa-solid fa-xmark' style={{ fontSize: '15px' }}></i>
        </button>
      </div>
      <HighchartsReact highcharts={Highcharts} options={options} ref={chartRef} />
      <div
        style={{
          zIndex: 2,
          display: userAdded ? 'flex' : 'none',
          position: 'absolute',
          right: 0,
          bottom: 0,
        }}>
        <button
          style={{ border: '0px', background: 'inherit', padding: '10px 15px' }}
          onClick={() => {
            setDashboardModal({ show: true, id: id, selectedComponent: 'hierarchyChart' });
          }}>
          <i class='fa-solid fa-pencil' style={{ fontSize: '18px' }}></i>
        </button>
      </div>
    </div>
  );
};

const TreeMapCharts = ({ id, title, data, usageTotals, setDashboardModal, colors, userAdded = true, removeComponent }) => {
  const chartRef = useRef(null);
  const [filteredData, setFilteredData] = useState([]);

  var getNode = function (name, value, metric, facility) {
    return {
      name: name + ': <br>' + value.toFixed(2) + ' ' + metric + ` (${facility})`,
      show: name,
      value: Math.max(0.4, Math.sqrt(value)),
      realValue: value,
    };
  };

  let groupedData = {};
  usageTotals
    ?.filter((t) => t.entity === String(data))
    ?.forEach((item) => {
      const key = `${item.subentityName}-${item.facilityName}-${item.metricName}`; // Unique key for subentity and facility and metric
      if (!groupedData[key]) {
        groupedData[key] = {
          subentityName: item.subentityName,
          facilityName: item.facilityName,
          usageValue: 0,
          metricName: item.metricName,
        };
      }
      // Aggregate usage values
      groupedData[key].usageValue += item.usageValue;
    });

  // Convert the grouped object back to the required array for getNode
  let chartData = Object.values(groupedData).map((item) =>
    getNode(item.subentityName, item.usageValue, item.metricName, item.facilityName)
  );

  chartData = _.sortBy(chartData, ['realValue'])?.reverse();

  //add colors to the data using the colors array starting from index 0
  chartData?.forEach((item, index) => {
    item.color = colors[index];
  });

  const getMaxDataPoints = () => {
    const screenWidth = window.innerWidth;

    if (screenWidth < 600) return 4;
    if (screenWidth < 1000) return 7;
    return 9; //data.length;
  };

  const updateFilteredData = () => {
    const maxDataPoints = getMaxDataPoints();
    const displayedData = chartData?.slice(0, maxDataPoints);

    setFilteredData(displayedData);
  };

  useEffect(() => {
    updateFilteredData(); // Initial call to set the data

    const handleResize = () => {
      updateFilteredData();
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line
  }, [data]);

  const options = {
    credits: false,
    chart: {
      height: 400,
      borderRadius: 15,
      spacing: [20, 20, 20, 20],
    },
    series: [
      {
        type: 'treemap',
        layoutAlgorithm: 'squarified',
        data: filteredData,
        borderRadius: '5px',
        dataLabels: {
          //useHTML: true,
          style: {
            textOutline: 'none', // Remove text outline
            fontSize: '12px',
            fontFamily: 'Open Sans',
            textAlign: 'center',
            justifyContent: 'center',
            textOverflow: 'ellipsis',
            align: 'center',
            verticalAlign: 'middle',
          },
        },
      },
    ],
    title: {
      text: title || '',
      style: {
        fontSize: '18px',
        fontFamily: 'Open Sans',
        fontWeight: '600',
        color: '#130834',
      },
    },
    tooltip: {
      enabled: true,
      useHTML: true,
      shadow: false,
      borderWidth: 1,
      borderColor: '#777',
      style: {
        zIndex: 100,
        fontSize: '12px',
      },
      //pointFormat: '<b>{point.show}</b>: <b>{point.value}</b> tCO2e',
      formatter: function () {
        return `<p><b>${this.point.show}: <b>${this.point.realValue?.toFixed(2)}</b> tCO2e</p>`;
      },
    },
  };

  return (
    <div style={{ position: 'relative' }}>
      <div style={{ zIndex: 2, display: userAdded ? 'flex' : 'none', position: 'absolute', right: 0, top: 0 }}>
        <button
          style={{ border: '0px', background: 'inherit', padding: '8px 15px' }}
          onClick={() => {
            removeComponent(id);
          }}>
          <i className='fa-solid fa-xmark' style={{ fontSize: '15px' }}></i>
        </button>
      </div>
      <HighchartsReact highcharts={Highcharts} options={options} ref={chartRef} />
      <div
        style={{
          zIndex: 2,
          display: userAdded ? 'flex' : 'none',
          position: 'absolute',
          right: 0,
          bottom: 0,
        }}>
        <button
          style={{ border: '0px', background: 'inherit', padding: '10px 15px' }}
          onClick={() => {
            setDashboardModal({ show: true, id: id, selectedComponent: 'treeMap' });
          }}>
          <i class='fa-solid fa-pencil' style={{ fontSize: '18px' }}></i>
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {};

const mapDispatchToProps = (dispatch) => {
  return {
    removeComponent: (id) => dispatch(Actions.removeComponent(id)),
  };
};

const PieChart = connect(mapStateToProps, mapDispatchToProps)(PieCharts);

const HierarchyChart = connect(mapStateToProps, mapDispatchToProps)(HierarchyCharts);

const TreeMapChart = connect(mapStateToProps, mapDispatchToProps)(TreeMapCharts);

export { PieChart, HierarchyChart, TreeMapChart };
