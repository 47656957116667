import React from 'react';
import { connect } from 'react-redux';
import ACTIONS from '../../store/action';
import { Actions as FACILITYACTIONS } from '../../store/actions/facilityActions';
import { AddButton } from '../../styledComponents/GlobalStyle';
import { handleFileRemove } from './UploadFacilityData';

const FacilitiesImportModal = ({
  successfulImport,
  errorImport,
  facilityAnalysisImportModal,
  processingFacilityImport,
  showAnalysisImportModal,
  showFacilityImportModal,
}) => {
  if (!facilityAnalysisImportModal || processingFacilityImport) return null;

  const closeModal = () => {
    showAnalysisImportModal(false);
    showFacilityImportModal(false);
    handleFileRemove();
  };

  return (
    <div className='modal' style={{ display: 'block', background: 'rgba(0,0,0,.5)' }}>
      <div className='modal-dialog' style={{ height: '90vh', overflow: 'auto', fontSize: '12px' }}>
        <div className='modal-content'>
          <div className='modal-header'>
            <button onClick={() => closeModal()} className='close'>
              x
            </button>
            <h4 className='modal-title'>Import Analysis</h4>
          </div>
          <div className='modal-body'>
            {successfulImport && (
              <div className='form-group'>
                <div id='import-link'>
                  <p className='sucess-imports' style={{ fontSize: '1.2em', fontWeight: 'bold', color: '#60B12F' }}>
                    {successfulImport.length} {successfulImport.length > 1 ? 'rows' : 'row'} imported sucessfully
                  </p>
                </div>
              </div>
            )}

            {errorImport && errorImport.length >= 1 && (
              <div className='form-group'>
                <div id='import-link'>
                  <p className='error-imports' style={{ fontSize: '1.2em', fontWeight: 'bold', color: 'red' }}>
                    {errorImport.length > 1 ? `Encountered ${errorImport.length} errors` : 'Encountered 1 error'}
                  </p>
                  {errorImport.map((facility, key) => (
                    <div style={{ marginTop: 5, marginLeft: 5 }} key={key}>
                      <span style={{ fontWeight: 'bold', fontSize: '1em' }}>Row {facility.row}:</span>
                      <span> {facility.error}</span>
                    </div>
                  ))}
                </div>
              </div>
            )}

            <div className='row block buttons' style={{ display: 'flex', flexDirection: 'row-reverse' }}>
              <AddButton
                onClick={() => closeModal()}
                className='btn btn-success'
                id='Facilities-Modal-close'
                bordercolor='true'
                style={{ marginRight: 20 }}>
                Close
              </AddButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    facilityImportModal: state.facility.facilityImportModal,
    processingFacilityImport: state.facility.processingFacilityImport,
    successfulImport: state.facility.successfulImport,
    errorImport: state.facility.errorImport,
    facilityAnalysisImportModal: state.facility.facilityAnalysisImportModal,
  };
};

const mapDispatchToProps = (dispatch) => ({
  processFacilitiesUpload: (file) => dispatch(FACILITYACTIONS.processFacilitiesUpload(file)),
  getUploadLink: (file) => dispatch(ACTIONS.getUploadLink(file)),
  showAnalysisImportModal: (status) => dispatch(FACILITYACTIONS.showAnalysisImportModal(status)),
  showFacilityImportModal: (status) => dispatch(FACILITYACTIONS.showFacilityImportModal(status)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FacilitiesImportModal);
