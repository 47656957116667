import { HelpOutline, Add as AddIcon } from '@mui/icons-material';
import { Alert, Button, Modal, Chip, Stack, ToggleButton, ToggleButtonGroup, LinearProgress } from '@mui/material';
import DatePicker from 'react-datepicker';
import { addDays, differenceInDays, format, subYears } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import IntegrationModal from '../components/Integrations/IntegrationModal';
import OrgAgreementsModal from '../components/Modals/OrgAgreementsModal';
import UserAgreementsModal from '../components/Modals/UserAgreementsModal';
import SubscriptionFailed from '../components/Payments/SubscriptionFailed';
import SubscriptionSuccessful from '../components/Payments/SubscriptionSuccessful';
import Register from '../components/Register/Register';
import ACTIONS, { showLoader, toggleAlertMessage } from '../store/action';
import { Actions as PROFILEACTIONS } from '../store/actions/profileActions';
import { DashboardButton, DashboardIcon, CalendarBtn, AddButton } from '../styledComponents/GlobalStyle';
import Treemap from '../components/Dashboard/Treemap';
import ColoumnChart from '../components/Dashboard/ColoumnChart';
import DonutChart from '../components/Dashboard/DonutChart';
import NetZeroChart from '../components/Dashboard/NetZeroChart';
import { generateShades } from '../components/utils/PaletteCreator';
import DashboardCard from '../components/Dashboard/DashboardCard';
import DashboardModal from '../components/Dashboard/DashboardModal';
import Select from 'react-select';
import { PieChart, TreeMapChart } from '../components/Dashboard/DashboardCharts';
import { Waveform } from '@uiball/loaders';
import { Actions as DASHBOARDACTIONS } from '../store/actions/dashboardActions';
import { Actions as USAGEACTIONS } from '../store/actions/usageActions';
import { useHistory } from 'react-router';

const ConnectStripeAccountModal = ({ showStripeConnect, connectStripeAccount }) => {
  const handleClose = () => null;
  const [loading, setLoading] = useState(false);

  if (!showStripeConnect) {
    return null;
  } else {
    return (
      <div>
        <Modal open={showStripeConnect} onClose={handleClose}>
          <div className='modal-dialog'>
            <div className='modal-content'>
              <div className='modal-header'>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <h4 className='modal-title'>Please connect your account</h4>
                </div>
              </div>
              <div className='modal-body' style={{ overflow: 'auto' }}>
                <p style={{ fontWeight: 'bold' }}>
                  To complete your Ambassador set up, please follow this link to create a Stripe Connect account. This will
                  enable payment of commissions.
                </p>
                <button
                  className='btn btn-success'
                  onClick={async () => {
                    setLoading(true);
                    await connectStripeAccount();
                    setLoading(false);
                  }}>
                  {loading && <i className='fa fa-spinner fa-spin' style={{ marginRight: 5 }} />}
                  Connect Stripe Account
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
};

const Dashboard = ({
  firstName,
  branding,
  showSubscriptionSuccesfulModal,
  stripeCheckoutFailed,
  stripeCheckoutSuccess,
  hlp,
  tourID,
  hasSubscriptionActive,
  ssoUser,
  list,
  fetchedOrganisations,
  ambassadorAdmin,
  stripeAccountId,
  currentOrg,
  directUser,
  whiteLabelAdmin,
  blockBtns,
  connectStripeAccount,
  accountType,
  daysUntilTestOrgExpire,
  helpHeroBtnStyles,
  slaAgreementConfirmed,
  eulaAgreementConfirmed,
  toggleAlertMessage,
  dashboardYear,
  years,
  components,
  sumValues,
  dashboardData,
  loader,
  currentYear,
  usageTotals,
  getUsageTotals,
  getTargets,
  orgTargets,
  SBTNetZero,
}) => {
  const [showPaymentModal, setShowPaymentModal] = useState(null);
  const [showStripeConnect, setShowStripeConnect] = useState(false);
  const [showIntegrationModal, setShowIntegrationModal] = useState(false);
  const [year, setYear] = useState(currentYear);
  const history = useHistory();
  console.log('year:', dashboardYear);
  const [fromDate, setFromDate] = useState(new Date(new Date().getFullYear(), 0, 1));
  const [toDate, setToDate] = useState(new Date());
  const [chipSelected, setChipSelected] = useState('1 Year');
  const [dataType, setDataType] = useState('market');
  const [dashboardModal, setDashboardModal] = useState({ show: false, id: null });
  const font = branding?.fonts?.[0]?.font?.body;

  useEffect(() => {
    (async () => {
      // User is an ambassador admin and org doesn't have a stripe account -> First sign in, show stripe connect modal.
      if (ambassadorAdmin && currentOrg) {
        // User is an ambassador admin and org doesn't have a stripe account -> First sign in, show stripe connect modal.
        if (!stripeAccountId) {
          setShowStripeConnect(true);
        }
      }
      // Org doesn't have subscription -> show payment modal.
      if (hasSubscriptionActive === false) {
        setShowPaymentModal(true);
      } else {
        setShowPaymentModal(false);
      }
      // If xeroUser and no organisations, show integration modal.
      if (ssoUser && fetchedOrganisations && list?.length === 0) {
        setShowIntegrationModal(true);
      } else {
        setShowIntegrationModal(false);
      }
    })();
    // eslint-disable-next-line
  }, [currentOrg, fetchedOrganisations, ambassadorAdmin, stripeAccountId]);

  useEffect(() => {
    //Treemap and Donut Charts
    let data = [
      {
        group_by: 'entity',
        field:
          dataType === 'location'
            ? ['location.calendarYear.scope.3', 'location.calendarYear.scope.2', 'location.calendarYear.scope.1']
            : ['market.calendarYear.scope.3', 'market.calendarYear.scope.2', 'market.calendarYear.scope.1'],
        beginningStartDate: format(fromDate, 'yyyy-MM-dd'),
        endStartDate: format(fromDate, 'yyyy-MM-dd'),
        beginningEndDate: format(toDate, 'yyyy-MM-dd'),
        endEndDate: format(toDate, 'yyyy-MM-dd'),
        chart: 'yearly',
      },
      //Column Chart
      {
        group_by: dataType === 'location' ? 'location.calendarYear.year' : 'market.calendarYear.year',
        field:
          dataType === 'location'
            ? ['location.calendarYear.scope.3', 'location.calendarYear.scope.2', 'location.calendarYear.scope.1']
            : ['market.calendarYear.scope.3', 'market.calendarYear.scope.2', 'market.calendarYear.scope.1'],
        beginningStartDate: format(new Date(subYears(currentYear, 8).getFullYear(), 0, 1), 'yyyy-MM-dd'), //Always 8 years from endDate
        endStartDate: format(new Date(subYears(currentYear, 8).getFullYear(), 0, 1), 'yyyy-MM-dd'),
        beginningEndDate: format(new Date(currentYear), 'yyyy-MM-dd'),
        endEndDate: format(new Date(currentYear, 11, 31), 'yyyy-MM-dd'),
        chart: 'multiYear',
      },
      //Net Zero Chart to be done later
    ];
    let year = new Date(fromDate).getFullYear();
    if (currentOrg) {
      sumValues(data, year, currentOrg, dataType);
      getUsageTotals();
    }
  }, [fromDate, toDate, currentOrg, dataType]);

  useEffect(() => {
    if (currentOrg) {
      getTargets();
    }
  }, [currentOrg]);

  const handleDataType = (event, newDataType) => {
    if (newDataType !== null) {
      setDataType(newDataType);
    }
  };

  const {
    btnTextColor,
    dashboardFirstIcon,
    dashboardSecondIcon,
    dashboardThirdIcon,
    dashboardFourthIcon,
    buttonHover,
    dashboardFirstIconBtn,
    dashboardSecondIconBtn,
    dashboardThirdIconBtn,
    dashboardFourthIconBtn,
    dashboardIconBtnTxtColor,
    dashboardFirstIconBtnHover,
    dashboardSecondIconBtnHover,
    dashboardThirdIconBtnHover,
    dashboardForthIconBtnHover,
    dashboardHeaderBgColor,
    dashboardBoxBg,
    dashboardBoxBorder,
    categoryBackground,
    categoryText,
    primaryButton,
    sidebarBackground,
    palette,
  } = branding.colors || {};

  const extendWithPrefix = (prefix, obj) => {
    const result = {};
    for (let key in obj) {
      result[`${prefix}_${key}`] = obj[key];
    }
    return result;
  };
  const color1 = categoryBackground; //'#61B12F';
  const color2 = '#544FC5'; //sidebarBackground; ;
  const color3 = primaryButton; //'#33C2EA'; //'#45C8FF';
  const color4 = '#FFD700';
  const color5 = '#969696';
  /* let palette = {
    color1,
    ...extendWithPrefix('color1', generateShades(color1)),
    color2,
    ...extendWithPrefix('color2', generateShades(color2)),
    color3,
    ...extendWithPrefix('color3', generateShades(color3)),
    color4,
    ...extendWithPrefix('color4', generateShades(color4)),
    color5,
    ...extendWithPrefix('color5', generateShades(color5)),
  };
  palette = Object.values(palette); */

  if (stripeCheckoutFailed) return <SubscriptionFailed />;
  if (stripeCheckoutSuccess) return <SubscriptionSuccessful />;

  const showSLAAgreementModal = slaAgreementConfirmed === false && (directUser || whiteLabelAdmin);
  const showEULAAgreementModal = eulaAgreementConfirmed === false && (directUser || whiteLabelAdmin);

  const chipStyle = (selected) => {
    return {
      fontSize: '13px',
      backgroundColor: selected ? primaryButton : '#F3F3F3',
      color: selected ? btnTextColor : '#000',
    };
  };

  const fromYear = new Date(fromDate)?.getFullYear();
  const toYear = new Date(toDate)?.getFullYear();

  return (
    <>
      <div className='row'>
        <div className='col-lg-12'>
          <section className='panel'>
            <header className='panel-heading dashboard__header' style={{ '--dashboard-header-bg': dashboardHeaderBgColor }}>
              Welcome {firstName}
            </header>
          </section>
        </div>
      </div>

      {/* show a loader if dashboardData is not loaded yet */}
      {!dashboardData && hasSubscriptionActive && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            width: '100%',
            heigth: '90%',
          }}>
          <span style={{ fontSize: '30px', fontWeight: '500' }}>Dashboard Loading...</span>
          <Waveform size={50} lineWeight={3.5} speed={1} color='black' />
        </div>
      )}

      {!hasSubscriptionActive && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Alert severity='warning' style={{ fontSize: '1.2rem', border: '1px #CCC solid', fontFamily: 'inherit' }}>
            You do not have an active subscription. Please subscribe to view the dashboard.
          </Alert>
        </div>
      )}

      {dashboardData && hasSubscriptionActive && (
        <>
          {loader && (
            <div>
              <LinearProgress color='inherit' />
            </div>
          )}
          {/*<div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              padding: '0px 20px 10px 20px',
              flexWrap: 'wrap',
              gap: '10px',
            }}>
             <div
          // className='flex-dir'
          style={{ display: 'flex', justifyContent: 'space-evenly', zIndex: 3, marginTop: 5, marginRight: 5 }}>
          <div style={{ marginRight: 10, minWidth: 145 }}>
            <label className='input-group date datepicker'>
              <DatePicker
                showMonthDropdown
                name={'From'}
                showYearDropdown
                yearDropdownItemNumber={5}
                scrollableYearDropdown
                dateFormat='dd/MM/yyyy'
                className='form-control startDate'
                popperPlacement='bottom-end'
                maxDate={toDate || new Date()}
                selected={fromDate || null}
                isClearable={true}
                placeholderText='From'
                onChange={(date) => {
                  if (!date) setFromDate(null);
                  else setFromDate(new Date(date));
                }}
                popperModifiers={{ offset: { enabled: true, offset: '77px, 0px' } }}
              />
              <span className='input-group-btn'>
                <CalendarBtn className='btn btn-primary date-set'>
                  <i className='fa fa-calendar' />
                </CalendarBtn>
              </span>
            </label>
          </div>
          <div style={{ marginRight: 10, minWidth: 145 }}>
            <label className='input-group date datepicker'>
              <DatePicker
                showMonthDropdown
                showYearDropdown
                scrollableYearDropdown
                dateFormat='dd/MM/yyyy'
                className='form-control startDate'
                popperPlacement='bottom-end'
                yearDropdownItemNumber={5}
                isClearable={true}
                minDate={fromDate}
                maxDate={new Date()}
                selected={toDate || null}
                placeholderText='To'
                onChange={(date) => {
                  if (!date) setToDate(null);
                  else setToDate(new Date(date));
                }}
                popperModifiers={{ offset: { enabled: true, offset: '77px, 0px' } }}
              />
              <span className='input-group-btn'>
                <CalendarBtn className='btn btn-primary date-set'>
                  <i className='fa fa-calendar' />
                </CalendarBtn>
              </span>
            </label>
          </div>
        </div> */}

          {/* <div style={{ display: 'flex' }}>
          <Stack direction={{ xs: 'row' }} spacing={1} style={{ marginRight: 10 }}>
            <Chip
              label={'3 Years'}
              variant='outlined'
              style={chipStyle(chipSelected === '3 Years')}
              onClick={() => {
                console.log('3 Years');
                setChipSelected('3 Years');
                setToDate(new Date());
                setFromDate(addDays(new Date(), -1095));
              }}
            />
            <Chip
              label={'1 Year'}
              variant='outlined'
              style={chipStyle(chipSelected === '1 Year')}
              onClick={() => {
                console.log('1 Year');
                setChipSelected('1 Year');
                setToDate(new Date());
                setFromDate(addDays(new Date(), -365));
              }}
            />
            <Chip
              label={'6 Months'}
              variant='outlined'
              style={chipStyle(chipSelected === '6 Months')}
              onClick={() => {
                console.log('6 months');
                setChipSelected('6 Months');
                setToDate(new Date());
                setFromDate(addDays(new Date(), -183));
              }}
            />
            <Chip
              label={'3 Months'}
              variant='outlined'
              style={chipStyle(chipSelected === '3 Months')}
              onClick={() => {
                console.log('3 months');
                setChipSelected('3 Months');
                setToDate(new Date());
                setFromDate(addDays(new Date(), -91));
              }}
            />
          </Stack>
        </div> 
          </div>*/}
          <div>
            <div
              id='Date and Cards'
              className='flex-dir'
              style={{
                display: 'flex',
                justifyContent: 'space-evenly',
                width: '100%',
                alignItems: 'center',
                padding: '20px',
                gap: '10px',
              }}>
              <div className='dashboard-year-dataType'>
                <div
                  id='dashboard-year'
                  style={{
                    display: 'flex',
                    marginBottom: '15px',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}>
                  <span style={{ marginRight: '5px', marginLeft: '10px', fontSize: '1.3rem', fontWeight: 'bold' }}>
                    Dashboard Year:
                  </span>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ maxWidth: '250px', minWidth: '150px' }}>
                      <Select
                        id='year-select'
                        options={years}
                        onChange={(selected) => {
                          const currentYear = new Date().getFullYear();
                          setFromDate(new Date(selected?.value, 0, 1));
                          setToDate(selected?.value === currentYear ? new Date() : new Date(selected?.value, 11, 31));
                          setYear(selected?.value);
                        }}
                        isClearable={false}
                        isSearchable={false}
                        placeholder={'Year'}
                        menuPlacement='auto'
                        styles={{
                          control: (base) => ({
                            ...base,
                            fontSize: '18px', // Control text font size
                            fontWeight: '600',
                          }),
                          valueContainer: (base) => ({
                            ...base,
                            justifyContent: 'center',
                          }),
                        }}
                        value={
                          !year ? years?.find((yr) => yr?.value === dashboardYear) : years?.find((yr) => yr?.value === year)
                        }
                      />
                    </div>
                    {loader && (
                      <div className='loader' style={{ fontSize: '20px', marginLeft: 10 }}>
                        <i class='fa-solid fa-spinner fa-spin'></i>
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  <ToggleButtonGroup
                    value={dataType}
                    exclusive
                    onChange={handleDataType}
                    sx={{
                      '& .MuiToggleButton-root': {
                        backgroundColor: '#DDD', // Default background color
                        color: '#555',
                        fontFamily: font,
                        fontWeight: '600',
                        fontSize: '0.9rem',
                        '&.Mui-selected': {
                          color: btnTextColor, // Text color when selected
                          backgroundColor: primaryButton, // Background color when selected
                        },
                        '&:hover': {
                          backgroundColor: '#CCC',
                        },
                      },
                    }}>
                    <ToggleButton disableRipple value='market'>
                      Market
                    </ToggleButton>
                    <ToggleButton disableRipple value='location'>
                      Location
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>
              </div>
              <div
                id='Dashboard Cards'
                className='dashboard-grid-cardbox'
                style={
                  {
                    /* display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-evenly',
                  width: '82%',
                  flexDirection: 'row',
                  flexWrap: 'nowrap',
                  margin: 10,
                  gap: '10px', */
                    /*                   display: 'grid',
                  gridTemplateColumns: 'repeat(4, 1fr)',
                  gap: '10px',
                  width: '82%', */
                  }
                }>
                <div style={{ boxSizing: 'border-box' }}>
                  <DashboardCard
                    title={
                      fromYear && toYear && fromYear !== toYear
                        ? `Total Emissions in the ${fromYear}-${toYear} Period`
                        : `Total Emissions in ${fromYear}`
                    }
                    value={dashboardData?.totalEmissions?.toFixed(2) || '0'}
                    unit={<span>tCO2e</span>}
                    userAdded={false}
                  />
                </div>
                <div style={{ boxSizing: 'border-box' }}>
                  <DashboardCard
                    title={
                      fromYear && toYear && fromYear !== toYear
                        ? `Highest Emissions by Activity in the ${fromYear}-${toYear} Period`
                        : `Highest Emissions by Activity in ${fromYear}`
                    }
                    value={dashboardData ? `${dashboardData?.highestEmissions?.name}:` : ''}
                    unit={<span>{dashboardData?.highestEmissions?.total?.toFixed(2) || '0'} tCO2e</span>}
                    userAdded={false}
                  />
                </div>

                {/* <div style={{ boxSizing: 'border-box' }}>
                  <DashboardCard
                    title={
                      fromYear && toYear && fromYear !== toYear
                        ? `Highest Emissions by Activity in the ${fromYear}-${toYear} Period`
                        : `Highest Emissions by Activity in ${fromYear}`
                    }
                    value={dashboardData ? `${dashboardData?.highestEmissions?.name}:` : ''}
                    unit={<span>{dashboardData?.highestEmissions?.total?.toFixed(2) || '0'} tCO2e</span>}
                    userAdded={true}
                    setDashboardModal={setDashboardModal}
                  />
                </div>
                <div style={{ boxSizing: 'border-box' }}>
                  <DashboardCard
                    title={
                      fromYear && toYear && fromYear !== toYear
                        ? `Highest Emissions by Activity in the ${fromYear}-${toYear} Period`
                        : `Highest Emissions by Activity in ${fromYear}`
                    }
                    value={dashboardData ? `${dashboardData?.highestEmissions?.name}:` : ''}
                    unit={<span>{dashboardData?.highestEmissions?.total?.toFixed(2) || '0'} tCO2e</span>}
                    userAdded={true}
                    setDashboardModal={setDashboardModal}
                  />
                </div> */}

                {components
                  ?.filter((c) => c.type === 'Cards')
                  ?.map((c, i) => (
                    <DashboardCard
                      key={i}
                      title={c.title}
                      value={c.value}
                      unit={c.unit}
                      userAdded={true}
                      id={c.id}
                      setDashboardModal={setDashboardModal}
                    />
                  ))}
                {/* <Button
                  sx={{
                    backgroundColor: '#FEFEFE',
                    color: '#000',
                    margin: '10px',
                    borderRadius: '50%',
                    width: '50px',
                    height: '50px',
                    minWidth: '50px',
                    '&:hover': {
                      backgroundColor: '#E9E9E9',
                    },
                    border: '1px solid #E3E3E3',
                  }}
                  aria-label='add'
                  onClick={() => setDashboardModal({ show: true })}>
                  <AddIcon />
                </Button> */}
              </div>
            </div>

            <div className='dashboard-grid'>
              <div className='left-column'>
                {dashboardData?.treemap && (
                  <div style={{}}>
                    <Treemap
                      colors={palette}
                      fromYear={new Date(fromDate)?.getFullYear()}
                      toYear={new Date(toDate)?.getFullYear()}
                      data={dashboardData?.treemap}
                    />
                  </div>
                )}
                <DonutChart
                  year={year}
                  fromYear={new Date(fromDate)?.getFullYear()}
                  toYear={new Date(toDate)?.getFullYear()}
                  data={dashboardData}
                />
                {components
                  ?.filter((c) => c.type !== 'Cards')
                  ?.map((c, i) =>
                    c?.type === 'pieChart' ? (
                      <PieChart
                        key={i}
                        id={c?.id}
                        title={c?.title || ''}
                        data={c?.data || []}
                        valueSuffix={c?.valueSuffix || ''}
                        innerRadius={c?.innerRadius || 0}
                        dataLabels={c?.dataLabels || false}
                        legend={c?.legend || false}
                        colors={palette}
                        setDashboardModal={setDashboardModal}
                      />
                    ) : c?.type === 'treeMap' ? (
                      <TreeMapChart
                        key={i}
                        id={c?.id}
                        title={c?.title || ''}
                        data={c?.data || ''}
                        usageTotals={usageTotals || []}
                        colors={palette}
                        setDashboardModal={setDashboardModal}
                      />
                    ) : null
                  )}
              </div>
              <div className='right-column'>
                <ColoumnChart />
                {SBTNetZero &&
                  (orgTargets?.length > 0 ? (
                    <NetZeroChart orgTargets={orgTargets} />
                  ) : (
                    <div style={{ background: 'white', borderRadius: '15px', padding: 20 }}>
                      <h3 style={{ textAlign: 'center', fontWeight: '600', marginBottom: 20 }}>No Net-Zero Targets Set</h3>
                      <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 20 }}>
                        <AddButton onClick={() => history.push('/netZero')}>Add Net-Zero Target</AddButton>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div className='row' id='clientTestOrgs__alertMessage'>
                <div style={{ display: accountType?.includes('Client Test Organisation') ? 'block' : 'none' }}>
                  <Alert
                    severity='info'
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      textAlign: 'center',
                      border: '1px solid grey',
                      marginLeft: '15px',
                    }}>
                    <p
                      style={{
                        fontSize: 16,
                        marginTop: '5px',
                        marginBottom: '5px',
                      }}>
                      Test Organisation expires in: {daysUntilTestOrgExpire} days.
                    </p>
                  </Alert>
                </div>
              </div>
              <div id='dashboard__helpHero_btn'>
                <Button
                  variant='contained'
                  startIcon={<HelpOutline />}
                  style={helpHeroBtnStyles}
                  onClick={() => {
                    hlp.anonymous();
                    hlp.startTour(String(tourID));
                  }}>
                  Help
                </Button>
              </div>
            </div>
          </div>
        </>
      )}

      {/* <div
        style={{
          height: window.screen.availHeight > 1078 ? '78vh' : '75vh',
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
        }}>
        <div className='row' id='dashboard__icons'>
          <div className='col-md-3' id='dashboard__organisations'>
            <div
              className='mini-stat clearfix dashboard__mini-stat'
              style={{ '--dashboard-box-bg': dashboardBoxBg, '--dashboard-box-border': dashboardBoxBorder }}>
              <DashboardIcon className='mini-stat-icon' bgcolor={dashboardFirstIcon}>
                <i className='fa fa-building' />
              </DashboardIcon>
              <div className='mini-stat-info' style={{ marginLeft: '2vmin', fontSize: 14 }}>
                Organisations
              </div>
            </div>

            <DashboardButton
              className='btn btn-xs'
              bgcolor={dashboardFirstIconBtn || dashboardFirstIcon}
              bgcolorhover={dashboardFirstIconBtnHover || buttonHover}
              color={dashboardIconBtnTxtColor || btnTextColor}
              to='/organisations'
              style={{ fontSize: 14 }}>
              Manage Organisations
            </DashboardButton>
          </div>

          <div className='col-md-3' id='dashboard__facilities'>
            <div
              className='mini-stat clearfix dashboard__mini-stat'
              style={{ '--dashboard-box-bg': dashboardBoxBg, '--dashboard-box-border': dashboardBoxBorder }}>
              <DashboardIcon className='mini-stat-icon' bgcolor={dashboardSecondIcon}>
                <i className='fa fa-map-marker' />
              </DashboardIcon>

              <div className='mini-stat-info' style={{ marginLeft: '2vmin', fontSize: 14 }}>
                Facilities
              </div>
            </div>

            <DashboardButton
              className='btn btn-xs'
              bgcolor={dashboardSecondIconBtn || dashboardSecondIcon}
              bgcolorhover={dashboardSecondIconBtnHover || buttonHover}
              color={dashboardIconBtnTxtColor || btnTextColor}
              to='/facilities'
              style={{ fontSize: 14 }}>
              Manage Facilities
            </DashboardButton>
          </div>

          <div className='col-md-3' id='dashboard__usageData'>
            <div
              className='mini-stat clearfix dashboard__mini-stat'
              style={{ '--dashboard-box-bg': dashboardBoxBg, '--dashboard-box-border': dashboardBoxBorder }}>
              <DashboardIcon className='mini-stat-icon' bgcolor={dashboardThirdIcon}>
                <i className='fa-solid fa-chart-column' />
              </DashboardIcon>
              <div className='mini-stat-info' style={{ marginLeft: '2vmin', fontSize: 14 }}>
                Data Entries
              </div>
            </div>

            <DashboardButton
              className='btn btn-xs'
              bgcolor={dashboardThirdIconBtn || dashboardThirdIcon}
              bgcolorhover={dashboardThirdIconBtnHover || buttonHover}
              color={dashboardIconBtnTxtColor || btnTextColor}
              to='/usage'
              style={{ pointerEvents: blockBtns ? 'none' : 'auto', fontSize: 14 }}>
              Manage Usage Data
            </DashboardButton>
          </div>

          <div className='col-md-3' id='dashboard__reports'>
            <div
              className='mini-stat clearfix dashboard__mini-stat'
              style={{ '--dashboard-box-bg': dashboardBoxBg, '--dashboard-box-border': dashboardBoxBorder }}>
              <DashboardIcon className='mini-stat-icon' bgcolor={dashboardFourthIcon}>
                <i className='fa fa-file-text' />
              </DashboardIcon>
              <div className='mini-stat-info' style={{ marginLeft: '2vmin', fontSize: 14 }}>
                Reports
              </div>
            </div>

            <DashboardButton
              className='btn btn-xs'
              bgcolor={dashboardFourthIconBtn || dashboardFourthIcon}
              bgcolorhover={dashboardForthIconBtnHover || buttonHover}
              color={dashboardIconBtnTxtColor || btnTextColor}
              to='/reports'
              style={{ pointerEvents: blockBtns ? 'none' : 'auto', fontSize: 14 }}>
              Manage Reports
            </DashboardButton>
          </div>
        </div>

        </div> */}

      {showPaymentModal && !hasSubscriptionActive && <Register setShowPaymentModal={setShowPaymentModal} />}
      {ssoUser && showIntegrationModal && (
        <IntegrationModal setShowIntegrationModal={setShowIntegrationModal} showIntegrationModal={showIntegrationModal} />
      )}

      <ConnectStripeAccountModal showStripeConnect={showStripeConnect} connectStripeAccount={connectStripeAccount} />
      {showSLAAgreementModal && !showPaymentModal && !showIntegrationModal && <OrgAgreementsModal />}
      {showEULAAgreementModal && !showPaymentModal && !showIntegrationModal && <UserAgreementsModal />}
      {/* Display SubscriptionSuccessful Modal here if user updates subscription under dashboard component*/}
      {showSubscriptionSuccesfulModal && <SubscriptionSuccessful setShowPaymentModal={setShowPaymentModal} />}

      {dashboardModal.show && <DashboardModal dashboardModal={dashboardModal} setDashboardModal={setDashboardModal} />}
    </>
  );
};

const mapStateToProps = (state) => {
  const { firstName, ambassadorAdmin, cyfAdmin, whiteLabelAdmin, consultantAdmin, ssoUser } = state?.profile?.details || {};
  const { softwareLicenseAgreementConfirmed, userLicenseAgreementConfirmed } = state.profile.details.legalAgreements || {};
  const usageTotals = state?.usageTotals;

  const { hasSubscriptionActive, stripeAccountId, accountType, creationDate } = state.organisation?.details || {};
  const directUser = consultantAdmin === false && cyfAdmin === false && ambassadorAdmin === false;
  const { orgMissingCompulsoryData, list, fetchedOrganisations } = state?.organisation || {};
  const blockBtns = !cyfAdmin && (!hasSubscriptionActive || orgMissingCompulsoryData);
  const features = state?.features;
  const availableFeatures = state.organisation?.details?.features;
  const SBTfeature = features?.find((feature) => feature.featureKey === 'SCIENCE_BASED_TARGETS');
  const SBTNetZero = availableFeatures?.includes('SCIENCE_BASED_TARGETS') || SBTfeature?.enabledByDefault;

  const hostname = window.location.hostname;

  let daysUntilTestOrgExpire = 0;
  if (accountType?.includes('Client Test Organisation') && creationDate) {
    const expireDay = addDays(new Date(creationDate), 30);
    daysUntilTestOrgExpire = differenceInDays(expireDay, new Date()) + 1;
    daysUntilTestOrgExpire = daysUntilTestOrgExpire < 0 ? 0 : daysUntilTestOrgExpire;
  }

  const helpHeroBtnStyles = {
    background: state.branding?.colors?.primaryColor,
    width: 93,
    borderRadius: 19,
    fontWeight: 600,
    padding: '10px 15px 10px 15px',
    fontSize: 15,
    display: ['localhost', 'compareyourfootprint'].some((x) => hostname.includes(x)) ? 'flex' : 'none',
  };

  const startYear = state?.dashboard?.years?.startYear;
  const endYear = state?.dashboard?.years?.endYear;
  const dashboardYear = endYear; //new Date().getFullYear();

  const years = Array.from({ length: endYear - startYear + 1 }, (v, i) => ({
    label: `${endYear - i}`,
    value: endYear - i,
  }));

  return {
    slaAgreementConfirmed: softwareLicenseAgreementConfirmed,
    eulaAgreementConfirmed: userLicenseAgreementConfirmed,
    currentOrg: state.currentOrganisation,
    firstName,
    ssoUser,
    ambassadorAdmin,
    whiteLabelAdmin,
    directUser,
    stripeAccountId,
    list,
    fetchedOrganisations,
    branding: state.branding || {},
    hasSubscriptionActive,
    showSubscriptionSuccesfulModal: state.payment && state.payment.subscriptionSucessfull,
    accountType,
    daysUntilTestOrgExpire,
    helpHeroBtnStyles,
    stripeCheckoutFailed: window.location.pathname.includes('cancel'),
    stripeCheckoutSuccess: window.location.pathname.includes('success'),
    blockBtns,
    dashboardYear,
    years,
    components: state?.dashboard?.components,
    dashboardData: state?.dashboard?.data,
    loader: state?.showLoader,
    currentYear: new Date().getFullYear(),
    usageTotals,
    orgTargets: state?.dashboard?.targets,
    SBTNetZero: SBTNetZero || false,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getLicenses: () => dispatch(ACTIONS.getLicenses()),
  connectStripeAccount: () => dispatch(PROFILEACTIONS.connectStripeAccount()),
  toggleAlertMessage: (status, message, severity, hideDuration) =>
    dispatch(toggleAlertMessage(status, message, severity, hideDuration)),
  sumValues: (data, year, org, dataType) => dispatch(DASHBOARDACTIONS.sumValues(data, year, org, dataType)),
  showLoader: (status) => dispatch(showLoader(status)),
  getUsageTotals: () => dispatch(USAGEACTIONS.getUsageTotals()),
  getTargets: () => dispatch(DASHBOARDACTIONS.getTargets()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
