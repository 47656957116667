import { Fade, Modal } from '@mui/material';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { Actions as ADMIN_ACTIONS } from '../../store/actions/adminActions';
import { Actions as USAGEACTIONS } from '../../store/actions/usageActions';
import { AddButton, UsageDataGuide } from '../../styledComponents/GlobalStyle';
import ConfirmationModal from '../Modals/ConfirmationModal';
import { checkReportingYearEndAndIndustry, itemToOptions } from '../utils/GlobalFunctions';
import AddEditUsage from './AddEditUsage';
import DeleteUsageDataModal from './DeleteUsageDataModal';
import UploadHistory from './UploadHistory';
import UploadUsageData from './UploadUsageData';
import UsageTable from './UsageTable';
import { BetaTag } from '../Common/ReusableComponents/Reusable';

const UsageData = ({
  facilities,
  showUsageDataModal,
  showUploadUsageDataModal,
  showUploadHistory,
  currentOrganisation,
  getMyUploads,
  usageDataGuideUrl,
  adminPortalConfirmationModal,
  toggleModal,
  getUsageTotals,
  addUsageIssue,
  orgMissingCompulsoryData,
  getAllItemsFromDB,
  addUsageData,
  usageUploadModal,
  usageUploadHistory,
  usageDataDeleteModal,
  getInvoices,
  features,
  availableFeatures,
  branding,
}) => {
  const history = useHistory();

  useEffect(() => {
    getUsageTotals();
    getAllItemsFromDB('usageTypeDetails');
    getInvoices();
  }, [currentOrganisation, getAllItemsFromDB, getUsageTotals]);

  const { sidebarBackground, sidebarLinkColor, btnTextColor } = branding;

  return (
    <>
      <div style={{ display: orgMissingCompulsoryData ? 'none' : 'block' }}>
        {facilities?.length > 0 && (
          <AddButton
            id='add-usage-button'
            className='btn btn-success'
            onClick={() => showUsageDataModal(true, {})}
            style={{ marginRight: 15 }}>
            <i className='fa-regular fa-chart-bar' /> Add usage data
          </AddButton>
        )}

        {facilities?.length > 0 && (
          <AddButton
            id='UsageData-importUsageButton'
            className='btn btn-success'
            onClick={() => showUploadUsageDataModal(true)}
            style={{ marginRight: 15 }}>
            <i className='fa fa-cloud-upload' /> Import usage data
          </AddButton>
        )}

        {facilities?.length > 0 &&
          (features?.find((f) => f.featureKey === 'INVOICE_IMPORT')?.enabledByDefault ||
            availableFeatures?.includes('INVOICE_IMPORT')) && (
            <AddButton
              className='btn btn-success'
              onClick={() => history.push('/usage/invoices')}
              style={{ marginRight: 15 }}>
              <i className='fa-solid fa-file-lines' /> Import Invoices
              {features?.find((f) => f.featureKey === 'INVOICE_IMPORT')?.isBeta && <BetaTag />}
            </AddButton>
          )}

        {facilities?.length > 0 && (
          <UsageDataGuide className='btn btn-success' target='_blank' href={usageDataGuideUrl} style={{ marginRight: 15 }}>
            <i className='fa fa-book' /> Usage Data Guide
          </UsageDataGuide>
        )}

        {facilities?.length > 0 && (
          <AddButton
            className='btn btn-success'
            onClick={() => {
              getMyUploads();
              showUploadHistory(true);
            }}>
            <i className='fa fa-cloud-upload' /> Freight Import History
          </AddButton>
        )}
      </div>

      {/* if missing r.y.e or industry direct to set that first, then direct to make facility, if neither issue return nothing */}
      {addUsageIssue || orgMissingCompulsoryData ? (
        <AddButton className='btn btn-success' onClick={() => history.push(`/organisations/${currentOrganisation}`)}>
          <i className='fa fa-map-marker' /> &#160;{' '}
          {addUsageIssue
            ? `${addUsageIssue} before adding usage data`
            : 'Please complete your organisation details before adding usage data'}
        </AddButton>
      ) : facilities && facilities.length === 0 ? (
        <AddButton className='btn btn-success' onClick={() => history.push('/facilities')}>
          <i className='fa fa-map-marker' /> &#160; Add a facility before adding usage data
        </AddButton>
      ) : (
        <></>
      )}
      {!!currentOrganisation && <UsageTable />}
      {addUsageData && <AddEditUsage />}
      {usageUploadModal && <UploadUsageData />}
      {usageDataDeleteModal && <DeleteUsageDataModal />}
      {usageUploadHistory > 0 && <UploadHistory />}
      {facilities?.length > 0 && adminPortalConfirmationModal && (
        <Modal
          aria-labelledby='transition-modal-title'
          aria-describedby='transition-modal-description'
          open={adminPortalConfirmationModal}
          onClose={toggleModal}
          closeAfterTransition>
          <Fade in={adminPortalConfirmationModal}>
            <div>
              <ConfirmationModal optionType='usage_data' />
            </div>
          </Fade>
        </Modal>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  let usageDataGuideUrl;

  switch (window.location.hostname) {
    case 'carbon.worldlandtrust.org':
      usageDataGuideUrl = `https://www.worldlandtrust.org/what-we-do/carbon-balanced/offsetting-for-organisations/usage-data-guide/`;
      break;
    default:
      usageDataGuideUrl = `https://compareyourfootprint.com/usage-data-guide/`;
  }

  const options = { 'Start Date': 'date', 'End Date': 'date', 'Custom top-level category': 'text', 'Usage Value': 'text' };
  const optionTypes = state?.metric?.types;
  const subentity = state?.subentities;
  const metric = state?.metric?.list;

  optionTypes &&
    Object.keys(optionTypes)?.map((item) => {
      options[item] = optionTypes[item]?.map(itemToOptions)?.sort((a, b) => a.label.localeCompare(b.label));
      return options;
    });

  options.facility =
    state?.facility?.list &&
    _.uniqBy(state?.facility?.list, 'id')
      ?.map(itemToOptions)
      ?.sort((a, b) => (a.label > b.label ? 1 : -1));

  options.entity = state?.entities?.map(itemToOptions)?.sort((a, b) => (a.label > b.label ? 1 : -1));

  options.subentity = subentity?.map(itemToOptions)?.sort((a, b) => a.label.localeCompare(b.label));
  options.metric = metric?.map(itemToOptions)?.sort((a, b) => a.label.localeCompare(b.label));

  const orgDetails = state?.organisation?.details;
  const { orgMissingCompulsoryData } = state?.organisation;

  const addUsageIssue = checkReportingYearEndAndIndustry(orgDetails);

  return {
    addUsageData: state?.usageDataModal?.status,
    usageUploadModal: state.usageUploadModal,
    usageUploadHistory: state.usageUploadHistory,
    usageDataDeleteModal: state.usageDataDeleteModal?.status,
    facilities: state?.facility?.list,
    usageDataModal: state.usageDataModal,
    currentOrganisation: state.currentOrganisation,
    addUsageIssue,
    branding: state.branding,
    usageDataGuideUrl,
    orgMissingCompulsoryData,
    adminPortalConfirmationModal: state?.admin?.modal?.confirmationModal,
    options,
    features: state?.features,
    availableFeatures: orgDetails?.features,
    branding: state.branding?.colors,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getMyUploads: () => dispatch(USAGEACTIONS.getMyUploads()),
  getUsageTotals: () => dispatch(USAGEACTIONS.getUsageTotals()),
  showUsageDataModal: (status, item) => dispatch(USAGEACTIONS.showUsageDataModal(status, item)),
  showUploadUsageDataModal: (status) => dispatch(USAGEACTIONS.showUploadUsageDataModal(status)),
  showUploadHistory: (status) => dispatch(USAGEACTIONS.showUploadHistory(status)),
  toggleModal: (status, action, item, modalProps) => dispatch(ADMIN_ACTIONS.toggleModal(status, action, item, modalProps)),
  getAllItemsFromDB: (table) => dispatch(ADMIN_ACTIONS.getAllItemsFromDB(table)),
  getInvoices: () => dispatch(USAGEACTIONS.getInvoices()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UsageData);
