import React from 'react';
import { connect } from 'react-redux';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import TreeMap from 'highcharts/modules/treemap';
import Treegraph from 'highcharts/modules/treegraph';
import Accessibility from 'highcharts/modules/accessibility';
import format from 'date-fns/format';

Accessibility(Highcharts);
TreeMap(Highcharts);
Treegraph(Highcharts);

function transformData(data, date, currentOrganisation, orgList, branding) {
  const { sidebarBackground, primaryButton } = branding;
  let map = [];

  // Initialize the root node
  map.push({
    id: currentOrganisation,
    name: orgList?.find((o) => o.id === currentOrganisation)?.name,
    value: 100,
    color: sidebarBackground, //'#130834',
  });

  // Filter child orgs with date is passed
  if (date)
    data = data?.filter(
      (item) =>
        new Date(item.startDate).setHours(0) <= new Date(date) && new Date(item.endDate).setHours(0) >= new Date(date)
    );

  let orgMap = {}; // Use an object to track organizations by a combined key of org and owner

  //Populate the map with child orgs
  data?.forEach((item) => {
    const key = `${item.org}-${item.owner}`; // Create a unique key for each org-owner combination
    const formattedStartDate = format(new Date(item.startDate), 'dd/MM/yyyy');
    const formattedEndDate = item.endDate === '2099-12-31' ? 'Ongoing' : format(new Date(item.endDate), 'dd/MM/yyyy');

    if (orgMap[key]) {
      // If the org already exists, append the new dates
      orgMap[key].startDates.push(formattedStartDate);
      orgMap[key].endDates.push(formattedEndDate);
      orgMap[key].value.push(parseInt(item.percentage, 10));
      orgMap[key].dates.push(
        `<span>${formattedStartDate} - ${formattedEndDate} : ${parseInt(item.percentage, 10)}%</span><br><br>`
      );
      // Combine the start and end dates
    } else {
      // If the org does not exist, create a new entry
      orgMap[key] = {
        id: item.org,
        name: item.name,
        value: [parseInt(item.percentage, 10)],
        parent: item.owner,
        startDates: [formattedStartDate],
        endDates: [formattedEndDate],
        dates: [formattedStartDate + ' - ' + formattedEndDate + ' : ' + parseInt(item.percentage, 10) + '%<br><br>'],
        color: primaryButton, //'#33C2EA',
      };
    }
  });

  // Convert the orgMap back to an array format if needed
  orgMap = Object.values(orgMap).map((item) => ({
    ...item,
    dates: item.dates.join(''),
  }));

  map = map.concat(orgMap);

  // Filter nodes to make sure each has a parent (except root)
  map = map.filter((node) => node.id === currentOrganisation || map.some((parent) => parent.id === node.parent));

  return map;
}

const TreeGraph = ({ data, date, currentOrganisation, orgList, branding }) => {
  if (!data || !currentOrganisation || !orgList) return;

  const transformedData = transformData(data, date, currentOrganisation, orgList, branding);
  const options = {
    chart: {
      type: 'treegraph',
      inverted: true,
    },
    credits: false,
    exporting: {
      buttons: {
        contextButton: {
          align: 'left', // Align the context button to the left
        },
      },
    },
    plotOptions: {
      series: {
        dataLabels: {
          useHtml: true,
          enabled: true,
          //format: '{point.name}', // Data labels for nodes
          formatter: function () {
            return '<div style="text-align: center; width: 100%;">' + this.point.name + '</div>';
          },
          style: {
            fontSize: '10px',
            textOutline: 'none',
          },
          padding: 1,
          distance: 10,
          //allowOverlap: true,
        },
        linkOptions: {
          dataLabels: {
            enabled: true,
            formatter: function () {
              return '';
            },
          },
        },
        nodes: {
          padding: 10,
        },
      },
    },
    series: [
      {
        type: 'treegraph',
        data: transformedData,
        allowDrillToNode: true,
        marker: {
          symbol: 'rect',
          width: 70,
          height: 100,
        },
        borderRadius: 10,
      },
    ],
    title: {
      text: '',
    },
    tooltip: {
      style: {
        margin: 5,
        fontSize: '11px',
        font: 'Arial',
        lineSpacing: '1.5',
      },
      useHtml: true,
      enabled: true,
      pointFormat: '<b>{point.dates}</b>',
      linkFormat: '<b>{point.name}</b>', //'{point.start} - {point.end}: {point.value}%',
    },
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

const mapStateToProps = (state) => {
  return {
    data: state.organisation?.hierarchy,
    currentOrganisation: state.currentOrganisation,
    orgList: state.organisation?.list,
    branding: state?.branding?.colors,
  };
};

export default connect(mapStateToProps)(TreeGraph);
