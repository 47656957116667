import Select from 'react-select';
import { format } from 'date-fns';
import { Card } from '@mui/material';
import { connect } from 'react-redux';
import { Tooltip } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import Breadcrumb from '../../Common/Breadcrumb';
import LinearProgress from '@mui/material/LinearProgress';
import { uuidValidateV4 } from '../../utils/GlobalFunctions';
import { Actions as ADMINACTIONS } from '../../../store/actions/adminActions';

const TemplateVersioning = ({ templates, showLoader, toggleModal, getTemplates, toggleTemplate, generateNewTemplate }) => {
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const versions = useMemo(
    () => templates?.find((t) => t.value === selectedTemplate)?.versions,
    [templates, selectedTemplate]
  );

  useEffect(() => {
    getTemplates();
  }, [getTemplates]);

  return (
    <>
      <Breadcrumb items={[{ label: 'Template Versioning', link: '/admin/templates' }]} />

      <div className='card'>
        <div className='card-body'>
          <div className='mb-4'>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '300px' }}>
                <div style={{ flexGrow: 1, marginRight: '10px' }}>
                  <Select
                    placeholder='Select Template'
                    onChange={(selected) => setSelectedTemplate(selected.value)}
                    options={templates}
                  />
                </div>
                <button disabled={showLoader} className='btn btn-success' onClick={() => getTemplates()}>
                  <i className='fas fa-sync me-2' />
                </button>
              </div>

              {selectedTemplate && (
                <button disabled={showLoader} className='btn btn-info' onClick={() => generateNewTemplate(selectedTemplate)}>
                  <i className='far fa-square-plus me-2' style={{ paddingRight: '0.5rem' }} />
                  Regenerate Template
                </button>
              )}
            </div>
          </div>

          {showLoader && <LinearProgress style={{ marginTop: '1.5rem' }} color='inherit' />}
          {selectedTemplate && !showLoader && (
            <Card sx={{ borderRadius: 2 }} style={{ marginTop: '1.5rem' }}>
              <div className='table-responsive'>
                <table className='table'>
                  <thead>
                    <tr>
                      <th>Version</th>
                      <th>Created At</th>
                      <th>Created By</th>
                      <th>Status</th>
                      <th className='text-center'>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {versions?.length === 0 && (
                      <tr>
                        <td colSpan='5' className='text-center'>
                          No versions found!
                        </td>
                      </tr>
                    )}
                    {versions?.map((version) => (
                      <tr key={version.id}>
                        <td>{version.version}</td>
                        <td>{format(new Date(version.createdAt), 'HH:mm dd/MM/yyyy')}</td>
                        {!uuidValidateV4(version.createdBy) && <td>{version.createdBy}</td>}
                        {uuidValidateV4(version.createdBy) && (
                          <td>
                            <Tooltip
                              arrow
                              sx={{ fontSize: 20 }}
                              title={<p style={{ fontSize: 12, marginBottom: 0 }}>{version.createdBy}</p>}>
                              <>Unknown</>
                            </Tooltip>
                          </td>
                        )}
                        <td>
                          <span className={`badge ${version.active ? 'bg-success' : 'bg-secondary'}`}>
                            {version.active ? 'Active' : 'Inactive'}
                          </span>
                        </td>
                        <td className='text-center'>
                          <a target='blank' href={version.downloadLink}>
                            <button className='btn btn-link btn-sm p-0' title='Download'>
                              <i className='fas fa-download' />
                            </button>
                          </a>
                          {/* <button
                            className='btn btn-link btn-sm p-0'
                            title='Delete'
                            onClick={() => toggleModal(true, 'templates-table-delete', version)}>
                            <i className='fas fa-trash' />
                          </button> */}
                          <button
                            className='btn btn-link btn-sm p-0'
                            onClick={() => toggleTemplate(version)}
                            title={version.active ? 'Deactivate' : 'Activate'}>
                            {!version.active && <i className='fas fa-check' />}
                            {version.active && <i className='fas fa-xmark' />}
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Card>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  showLoader: state?.showLoader,
  templates: state?.admin?.adminPortal?.templates || [],
});
const mapDispatchToProps = (dispatch) => ({
  getTemplates: () => dispatch(ADMINACTIONS.getTemplates()),
  toggleTemplate: (template) => dispatch(ADMINACTIONS.toggleTemplate(template)),
  generateNewTemplate: (templateType) => dispatch(ADMINACTIONS.generateNewTemplate(templateType)),
  toggleModal: (status, action, item, notAutoClose) =>
    dispatch(ADMINACTIONS.toggleModal(status, action, item, notAutoClose)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TemplateVersioning);
