import _ from 'lodash';
import Select from 'react-select';
import Breadcrumb from '../components/Common/Breadcrumb';
import React, { useMemo, useState, useEffect } from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import { AddButton } from '../styledComponents/GlobalStyle';

const conversionRates = {
  kg: 1,
  g: 0.001,
  lb: 0.45359237,
  oz: 0.028349523125,
  st: 907.1847,
  t: 1000,
  lt: 1016.047,

  km: 1,
  m: 0.001,
  ft: 0.0003048,
  mile: 1.609344,

  'tonne.km': 1,
  'ton.mile': 1.459,
  'tonne.mile': 1.609,

  J: 1,
  Btu: 1055.06,
};

const unitCategories = {
  Mass: [
    { value: 'kg', label: 'Kilograms (kg)' },
    { value: 'g', label: 'Grams (g)' },
    { value: 'lb', label: 'Pounds (lb)' },
    { value: 'oz', label: 'Ounces (oz)' },
    { value: 'st', label: 'US ton (st)' },
    { value: 't', label: 'Tonne (t)' },
    { value: 'lt', label: 'Imperial ton (lt)' },
  ],
  Distance: [
    { value: 'km', label: 'Kilometers (km / passenger.km)' },
    { value: 'mile', label: 'Miles (mi / passenger.mile)' },
    { value: 'ft', label: 'Feet (ft)' },
    { value: 'm', label: 'Meters (m)' },
  ],
  Transport: [
    { value: 'tonne.km', label: 'Tonne Kilometers (tonne.km)' },
    { value: 'ton.mile', label: 'Ton Miles (ton.mile)' },
    { value: 'tonne.mile', label: 'Tonne Miles (tonne.mile)' },
  ],
  Energy: [
    { value: 'J', label: 'Joules (J)' },
    { value: 'Btu', label: 'British Thermal Units (btu)' },
  ],
};

const UnitConverter = () => {
  const [result, setResult] = useState(0);
  const [toUnit, setToUnit] = useState(null);
  const [fromUnit, setFromUnit] = useState(null);
  const [inputValue, setInputValue] = useState('');

  const unitOptions = useMemo(
    () =>
      Object.entries(unitCategories).map(([category, units]) => ({
        label: category,
        options: units.map((unit) => ({ ...unit, group: category })),
      })),
    []
  );

  const convertUnits = (value, from, to) => {
    if (!from || !to || value === '' || isNaN(value)) return 0;
    const fromRate = conversionRates[from.value];
    const toRate = conversionRates[to.value];
    if (!fromRate || !toRate) return 0;
    return (Number(value) * fromRate) / toRate;
  };

  const roundNumber = (number) => parseFloat(number.toFixed(8)).toLocaleString(navigator.language);

  useEffect(() => {
    if (fromUnit && toUnit) {
      if (toUnit.group !== fromUnit.group) {
        setToUnit(null);
        return;
      }
      const result = convertUnits(inputValue, fromUnit, toUnit);
      setResult(result);
    } else setResult(0);
  }, [inputValue, fromUnit, toUnit]);

  const conversionRate = useMemo(() => {
    if (fromUnit && toUnit && fromUnit.group === toUnit.group) return roundNumber(convertUnits(1, fromUnit, toUnit));
    else return null;
  }, [fromUnit, toUnit]);

  return (
    <React.Fragment>
      <Breadcrumb items={[{ label: 'Unit Converter', link: '/unitConverter' }]} />
      <div className='col-lg-12' style={{ padding: 0 }}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Card
            sx={{
              width: '400px',
              borderRadius: '16px',
              background: 'rgba(255,255,255,0.8)',
              boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
              border: '1px solid rgba(255,255,255,0.3)',
            }}>
            <CardContent sx={{ padding: 3 }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: '24px',
                }}>
                <Typography variant='h6' sx={{ color: 'text.primary', fontWeight: 500 }}>
                  Unit Converter
                </Typography>
              </div>

              <div style={{ marginBottom: '24px' }}>
                <input
                  id='convert_input'
                  type='number'
                  placeholder='0'
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  style={{
                    width: '100%',
                    borderRadius: '4px',
                    lineHeight: '2.5rem',
                    padding: '0 0.75rem',
                    marginBottom: '1rem',
                    border: 'solid lightgray 1px',
                  }}
                />
                <Select
                  value={fromUnit}
                  isClearable={true}
                  isSearchable={false}
                  options={unitOptions}
                  onChange={(e) => setFromUnit(e)}
                  menuPortalTarget={document.body}
                  isOptionDisabled={(option) => toUnit && option.group !== toUnit.group}
                  placeholder='Select unit from...'
                />
              </div>

              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <i
                  style={{
                    cursor: 'pointer',
                    color: '#919191',
                    userSelect: 'none',
                    fontSize: '1.5rem',
                  }}
                  className='fa-solid fa-arrows-rotate'
                  onClick={() => {
                    setToUnit(fromUnit);
                    setFromUnit(toUnit);
                  }}
                />
              </div>

              <div style={{ marginTop: '24px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <input
                    readOnly
                    type='text'
                    id='convert_result'
                    value={roundNumber(result)}
                    style={{
                      width: '82%',
                      borderRadius: '4px',
                      lineHeight: '2.5rem',
                      padding: '0 0.75rem',
                      marginBottom: '1rem',
                      border: 'solid lightgray 1px',
                    }}
                  />
                  <AddButton
                    title='Copy'
                    className='btn btn-info'
                    style={{ width: '15%', height: '2.5rem' }}
                    onClick={() => navigator.clipboard.writeText(result.toString())}>
                    <i className='fa-regular fa-copy' />
                  </AddButton>
                </div>

                <Select
                  value={toUnit}
                  isClearable={true}
                  isSearchable={false}
                  options={unitOptions}
                  onChange={(e) => setToUnit(e)}
                  menuPortalTarget={document.body}
                  isOptionDisabled={(option) => fromUnit && option.group !== fromUnit.group}
                  placeholder='Select unit to...'
                />
              </div>

              {fromUnit && toUnit && fromUnit.group === toUnit.group && (
                <Typography
                  variant='body2'
                  sx={{
                    textAlign: 'center',
                    color: 'text.secondary',
                    marginTop: 3,
                  }}>
                  1 {fromUnit.label}
                  <br /> = <br />
                  {conversionRate} {toUnit.label}
                </Typography>
              )}
            </CardContent>
          </Card>
        </div>
      </div>
    </React.Fragment>
  );
};

export default UnitConverter;
