import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import Breadcrumb from '../components/Common/Breadcrumb';
import { AddButton } from '../styledComponents/GlobalStyle';
import { Divider, TextField, IconButton, Switch, FormControlLabel } from '@mui/material';
import Select from 'react-select';
import { adminPortalSelect } from '../components/utils/ReactSelectStyles';
import { format, subYears } from 'date-fns';
import { Actions as DASHBOARDACTIONS } from '../store/actions/dashboardActions';
import { Actions as ORGANISATIONACTIONS } from '../store/actions/organisationActions';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { showLoader } from '../store/action';

const NetZeroChart = ({ baseYear, multiYearData, targetYear, reduction, targetCoverage, data, useData }) => {
  let currentEmissionsData = [];
  if (!useData) {
    currentEmissionsData = Object.keys(multiYearData)
      ?.filter((year) => Number(year) >= Number(baseYear))
      ?.map((year) => {
        const yearData = multiYearData[year];

        const filteredData = Object.keys(yearData)
          ?.filter((key) => targetCoverage.includes(key))
          ?.reduce((sum, key) => {
            sum += yearData[key];
            return sum;
          }, 0);

        return filteredData;
      }, {});
  }

  const netZero = useData ? data * (1 - reduction / 100) : currentEmissionsData[0] * (1 - reduction / 100); // Calculate the net zero target

  function calculateDataPoints(currentData, startYear, endYear) {
    if (useData) {
      const totalYears = endYear - startYear;
      const firstValue = data;
      const decrement = (firstValue - netZero) / totalYears;

      let resultData = [data];

      for (let i = 0; i < totalYears; i++) {
        let value = resultData[resultData.length - 1] - decrement;
        resultData.push(value);
      }

      resultData[resultData.length - 1] = netZero;

      return resultData;
    } else {
      const initialLength = currentData?.length;
      const totalYears = endYear - startYear + 1;
      const remainingYears = totalYears - initialLength;

      // Calculate the decrement step to ensure the last value is 10
      const firstValue = currentData[currentData.length - 1];
      const decrement = (firstValue - netZero) / remainingYears;

      // Initialize the result array with the current data
      let resultData = [...currentData];

      // Generate data points from the next year after the initial data to endYear
      for (let i = 0; i < remainingYears; i++) {
        let value = resultData[resultData.length - 1] - decrement;
        resultData.push(value);
      }

      // Ensure the last value is exactly netZero (to account for floating point precision)
      resultData[resultData.length - 1] = netZero;

      return resultData;
    }
  }

  const netZero2040 = useData
    ? calculateDataPoints(data, baseYear, targetYear)
    : calculateDataPoints(currentEmissionsData, baseYear, targetYear);

  const options = {
    credits: false,
    chart: {
      type: 'areaspline',
      borderRadius: 15,
      spacing: [20, 20, 20, 20],
    },
    title: {
      text: `Net Zero Targets by ${targetYear}`,
      style: {
        fontSize: '18px',
        fontFamily: 'Open Sans',
        fontWeight: '600',
        color: '#130834',
      },
    },
    yAxis: {
      title: {
        text: 'Emissions (tCO2e)',
      },
      labels: {
        style: {
          fontSize: '0.9em',
        },
      },
      plotLines: [
        {
          color: '#FF0000',
          dashStyle: 'Dash',
          value: netZero, // Position of the line
          width: 2,
          label: {
            align: 'left',
            style: {
              color: '#FF0000',
              fontWeight: 'bold',
              fontSize: '13px',
            },
            text: 'Net Zero',
            x: 15,
          },
          zIndex: 10,
        },
      ],
    },
    xAxis: {
      accessibility: {
        rangeDescription: 'Years',
      },
      categories: Array.from({ length: targetYear - baseYear + 1 }, (_, i) => (baseYear + i).toString()),
    },
    plotOptions: {
      series: {},
    },
    legend: {
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'bottom',
    },
    tooltip: {
      useHTML: true, // Enable HTML to use custom styling
      formatter: function () {
        // Return the formatted tooltip text
        return (
          `<span style="font-size: 12px;">Year: <b>${this.x}</b></span><br/>` +
          `<span style="font-size: 12px;"><span style="color:${this.color};">\u25CF</span> ${
            this.series.name
          }: <b>${this.y.toFixed(2)}</b></span>`
        );
      },
    },
    series: [
      /* {
        name: 'Actual Emissions',
        data: useData ? data : [...currentEmissionsData],
        zIndex: 5,
        lineWidth: 1,
        fillOpacity: 1,
        marker: {
          enabled: false,
          symbol: 'circle',
        },
        color: '#1F9B38',
        type: 'areaspline',
      }, */
      {
        name: `Net Zero Target ${targetYear}`,
        data: netZero2040,
        marker: {
          enabled: false,
          symbol: 'circle',
        },
        zIndex: 4,
        fillOpacity: 1,
        lineWidth: 1,
        color: '#33C2EA',
        type: 'areaspline',
      },
    ],
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 600,
          },
          chartOptions: {
            legend: {
              layout: 'horizontal',
              align: 'center',
              verticalAlign: 'bottom',
            },
          },
        },
      ],
    },
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

const NetZero = ({
  orgDetails,
  currentOrg,
  SBTExcel,
  branding,
  fields,
  targetCoverage,
  absoluteTargets,
  dashboardData,
  currentYear,
  sumValues,
  saveTargets,
  multiYearData,
  showLoader,
  loader,
}) => {
  const [editedState, setEditedState] = useState({
    targetCoverage: ['scope3'],
    organisation: currentOrg,
    absoluteReduction: NaN,
    totalTarget: NaN,
  });
  const [formulation, showFormulation] = useState(false);
  const [useData, setUseData] = useState(true);
  const [disableInputs, setDisableInputs] = useState(false);
  const [formKey, setFormKey] = useState(Date.now());
  const { categoryBackground, categoryText, primaryButton } = branding;

  /* useEffect(() => {
    //Gets data for MultiYear charts
    let data = [
      {
        group_by: 'location.calendarYear.year',
        field: ['location.calendarYear.scope.3', 'location.calendarYear.scope.2', 'location.calendarYear.scope.1'],
        beginningStartDate: format(new Date(subYears(currentYear, 8).getFullYear(), 0, 1), 'yyyy-MM-dd'), //Always 8 years from endDate
        endStartDate: format(new Date(subYears(currentYear, 8).getFullYear(), 0, 1), 'yyyy-MM-dd'),
        beginningEndDate: format(new Date(currentYear), 'yyyy-MM-dd'),
        endEndDate: format(new Date(currentYear, 11, 31), 'yyyy-MM-dd'),
        chart: 'multiYear',
      },
    ];
    let year = new Date(currentYear).getFullYear();
    if (currentOrg) {
      sumValues(data, year, currentOrg);
      setEditedState({
        targetCoverage: ['scope3'],
        organisation: currentOrg,
        absoluteReduction: NaN,
        totalTarget: NaN,
      });
      setFormKey(Date.now()); // update key to re-render inputs
      setUseData(true);
    }
  }, [currentOrg]); */

  useEffect(() => {
    //Calculate total emissions
    const newEditedState = { ...editedState };
    let total = 0;
    editedState['targetCoverage']?.forEach((scope) => {
      if (editedState[scope]) {
        total += parseFloat(editedState[scope]);
      }
    });
    newEditedState.total = total;
    setEditedState(newEditedState);
  }, [editedState['scope1'], editedState['scope2'], editedState['scope3'], editedState['targetCoverage']]);

  useEffect(() => {
    if (!editedState['total'] && !editedState['sectorPathway']) return;

    //Calculate total target emissions
    const newEditedState = { ...editedState };
    newEditedState.totalTarget =
      parseFloat(editedState['total']) * (1 - absoluteTargets.get(editedState['sectorPathway']) / 100);
    setEditedState(newEditedState);
  }, [editedState['total'], editedState['sectorPathway']]);

  //Label to show which scopes are in the coverage
  const targetCovLabel = targetCoverage?.find(
    (o) => JSON.stringify(o.value) === JSON.stringify(editedState?.['targetCoverage'])
  )?.label;

  //Adds the border to required fields
  const requiredStyle = useMemo(
    () => (name, required) => {
      if (name === 'scope1' || name === 'scope2' || name === 'scope3') {
        if (editedState['targetCoverage']?.includes(name)) {
          return { border: '1px solid green' };
        } else {
          return { border: '1px solid #CCC' };
        }
      } else if (required === true) {
        return { border: '1px solid green' };
      } else {
        return { border: '1px solid #CCC' };
      }
    },
    [editedState['targetCoverage']]
  );

  useEffect(() => {
    //Check if all the fields needed for sbt fomrmulation are set
    if (editedState?.['sectorPathway'] && editedState?.['baseYear'] && editedState?.['targetYear']) {
      showFormulation(true);
    } else {
      showFormulation(false);
    }
  }, [editedState['sectorPathway'], editedState?.['baseYear'], editedState?.['targetYear']]);

  useEffect(() => {
    //Copy data from dashboard to the fields if useData is false
    if (!useData) {
      const dashData = dashboardData?.multiYear?.[editedState?.baseYear];
      setEditedState((prevState) => ({
        ...prevState,
        scope1: dashData ? dashData?.['scope1'] : 0,
        scope2: dashData ? dashData?.['scope2'] : 0,
        scope3: dashData ? dashData?.['scope3'] : 0,
      }));
    }
  }, [useData, editedState?.['baseYear']]);

  //Check if all the fields are set to enable save button
  const canSave =
    editedState?.['sectorPathway'] &&
    editedState?.['baseYear'] &&
    editedState?.['targetYear'] &&
    editedState?.['total'] != 0 &&
    editedState?.['totalTarget'] &&
    !isNaN(editedState?.['totalTarget']) &&
    editedState?.['absoluteReduction'] &&
    !isNaN(editedState?.['absoluteReduction']);

  console.log('editedState', editedState);

  return (
    <React.Fragment>
      <Breadcrumb items={[{ label: 'Net Zero Tool', link: '/netZero' }]} />
      <div className='col-lg-12'>
        <section className='panel'>
          <div className='panel-body'>
            <div
              className='SBT-title-section'
              style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <div className='left-side' style={{ justifyContent: 'flex-start' }}>
                <img
                  className='SBT-logo'
                  src={process.env.PUBLIC_URL + '/images/SBT-logo.png'}
                  alt='Science Based Targets logo'
                  //style={{ width: '210px', height: '105px' }}
                />
              </div>
              <div className='right-side' style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'column' }}>
                <span className='SBT-title'>Corporate Net-Zero Tool</span>
                <br />
                <p style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <b>Version:</b>
                  &nbsp;
                  <span>1.1</span>
                </p>
                <p style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <b>Excel:</b>
                  &nbsp;
                  <a className='a-link' href={SBTExcel} style={{ display: 'flex', flexShrink: 0 }}>
                    Download Excel Template
                  </a>
                </p>
              </div>
            </div>
            <Divider />
            <div className='SBT-body' style={{ width: '100%' }}>
              <div style={{ display: 'flex', marginTop: '10px', gap: '20px', width: '50%', alignItems: 'center' }}>
                <span style={{ width: '50%', padding: '5px', borderRadius: '3px', paddingLeft: '20px' }}>
                  {'Company Name:'}
                </span>
                <input
                  className='form_input'
                  type={'text'}
                  value={orgDetails?.name || ''}
                  disabled={true}
                  style={{
                    textAlign: 'center',
                    height: '40px',
                    width: '250px',
                  }}></input>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', gap: '15px' }}>
                <table className='table-hover' style={{ marginTop: '20px', width: '69%' }}>
                  <thead>
                    <tr>
                      <th>Net-Zero Tool User Guide</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        Section 1.1: Input emissions data as required by the selected Target Coverage. Required input fields
                        are highlighted in <span style={{ color: 'green' }}>green</span>.{' '}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Section 1.2: Summary of emissions reduction target data, absolute emissions based target setting
                        methods.
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Section 2.1: Input emissions and activity data as required by the selected Target Coverage and Target
                        Setting Method. Required input fields are highlighted in{' '}
                        <span style={{ color: 'green' }}>green</span>.
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div style={{ marginTop: '20px', width: '30%' }}>
                  <h5 style={{ fontWeight: 'bold' }}>Important Notice:</h5>
                  <span style={{ textAlign: 'center' }}>
                    SBTi’s modeled pathways in this tool outline the minimum ambition required to meet a given temperature
                    scenario. Companies are encouraged to set targets that are more ambitious than the minimum required
                    reduction.
                  </span>
                </div>
              </div>
              <div
                style={{
                  marginTop: '10px',
                  width: '100%',
                  background: categoryBackground,
                  color: categoryText,
                  padding: '5px 10px',
                }}>
                <h5 style={{ fontSize: '16px', marginLeft: 10 }}>
                  <b>1. ABSOLUTE EMISSIONS BASED TARGET SETTING METHODS </b>
                </h5>
              </div>
              <div className='Section 1.1'>
                <div style={{ display: 'flex' }}>
                  <h5 style={{ marginLeft: 10, marginTop: 20 }}>
                    <b>Section 1.1. Input Data (Absolute Targets)</b>
                  </h5>
                </div>
                <Divider />
                {fields.map((field, index) => {
                  return field?.type === 'input' ? (
                    <>
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <div className='SBT-div'>
                          <div className='SBT-form'>
                            <span className='SBT-form-label'>
                              {field?.name === 'total' ? field?.label.replace('*', targetCovLabel || '') : field?.label}
                            </span>
                            <div
                              className='SBT-input-div' /* style={{ display: 'flex', width: '50%', justifyContent: 'center' }} */
                            >
                              <input
                                key={`index-${formKey}`}
                                className='form_input'
                                type={field?.format}
                                step={'any'}
                                value={field?.value || editedState?.[field?.name]}
                                disabled={field?.disabled || disableInputs}
                                style={{
                                  textAlign: 'center',
                                  height: '32px',
                                  width: '80%',
                                  cursor: 'text',
                                  ...requiredStyle(field?.name, field?.required),
                                }}
                                onChange={(e) => {
                                  setEditedState((prevState) => ({
                                    ...prevState,
                                    [field?.name]: e.target.value,
                                  }));
                                }}></input>
                            </div>{' '}
                          </div>
                          <div className='SBT-adornment'>{field?.adornment}</div>
                        </div>
                        <div
                          style={{
                            display:
                              field?.copy === true && editedState?.['targetCoverage']?.includes(field?.name)
                                ? 'flex'
                                : 'none',
                            marginTop: 10,
                          }}></div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <div className='SBT-div'>
                          <div className='SBT-form'>
                            <span className='SBT-form-label'>{field?.label}</span>
                            <div className='SBT-select-div'>
                              <div style={{ marginLeft: '10%' }}>
                                <Select
                                  key={`index-${formKey}`}
                                  isClearable
                                  menuPlacement={'auto'}
                                  styles={{
                                    ...adminPortalSelect,
                                    control: (provided) => ({
                                      ...provided,
                                      width: '89%',
                                      ...(field?.required === true ? requiredStyle(field?.name, field?.required) : ''),
                                    }),
                                    menu: (provided) => ({
                                      ...provided,
                                      width: '89%',
                                    }),
                                    container: (provided) => ({
                                      ...provided,
                                      marginLeft: 0,
                                    }),
                                  }}
                                  options={field?.options}
                                  onChange={(e) => {
                                    if (e === null) {
                                      if (field?.name === 'sectorPathway') {
                                        setEditedState((prevState) => ({
                                          ...prevState,
                                          [field?.name]: null,
                                          absoluteReduction: NaN,
                                        }));
                                      }
                                      setEditedState((prevState) => ({
                                        ...prevState,
                                        [field?.name]: null,
                                      }));
                                    } else if (field?.name === 'sectorPathway') {
                                      setEditedState((prevState) => ({
                                        ...prevState,
                                        [field?.name]: e.value,
                                        absoluteReduction: absoluteTargets.get(e.value),
                                      }));
                                    } else {
                                      setEditedState((prevState) => ({
                                        ...prevState,
                                        [field?.name]: e.value,
                                      }));
                                    }
                                  }}
                                  value={
                                    field?.name === 'targetCoverage'
                                      ? field?.options?.find(
                                          (o) => JSON.stringify(o.value) === JSON.stringify(editedState['targetCoverage'])
                                        )
                                      : field?.options?.find((o) => o.value === editedState[field?.name])
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className='SBT-adornment'>
                            {field?.adornment}{' '}
                            {/*<div
                              style={{
                                display:
                                  field?.name === 'baseYear' && editedState?.['baseYear'] && dashboardData ? 'flex' : 'none',
                              }}>
                               <FormControlLabel
                                control={
                                  <Switch
                                    checked={!useData}
                                    onChange={() => {}}
                                    sx={{
                                      '& .MuiSwitch-switchBase.Mui-checked': {
                                        color: primaryButton, // Color for the circle when switched on
                                      },
                                      '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                        backgroundColor: primaryButton, // Color for the track when switched on
                                      },
                                    }}
                                  />
                                }
                                label='Use Organisation Data for this year'
                                sx={{
                                  '& .MuiFormControlLabel-label': {
                                    fontFamily: 'inherit',
                                    fontWeight: 'bold',
                                  },
                                }}
                              /> 
                            </div>*/}
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
              <div className='Section 1.2' style={{ width: '100%' }}>
                <h5 style={{ marginLeft: 10, marginTop: 20 }}>
                  <b>Section 1.2. Absolute Target Results</b>
                </h5>
                <Divider style={{ marginBottom: 10 }} />
                <table style={{ width: '100%' }}>
                  <thead>
                    <tr>
                      <th></th>
                      <th style={{ textAlign: 'center' }}>Base Year ({editedState?.['baseYear']})</th>
                      <th style={{ textAlign: 'center' }}>Target Year ({editedState?.['targetYear']})</th>
                      <th style={{ textAlign: 'center' }}>% Absolute Reduction</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td
                        style={{
                          width: '25%',
                          padding: '5px',
                          borderRadius: '3px',
                          paddingLeft: '20px',
                          paddingBottom: '15px',
                        }}>
                        {'Company | ' + (targetCovLabel ? targetCovLabel : '') + ' tCO2e'}
                      </td>
                      <td style={{ width: '25%', paddingBottom: '15px' }}>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                          <input
                            className='form_input'
                            type={'number'}
                            value={editedState?.['total']?.toFixed(2)}
                            disabled={true}
                            style={{
                              textAlign: 'center',
                              height: '32px',
                              width: '80%',
                              cursor: 'text',
                            }}></input>
                        </div>
                      </td>
                      <td style={{ width: '25%', paddingBottom: '15px' }}>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                          <input
                            className='form_input'
                            key={`target-${formKey}`}
                            type={'number'}
                            value={Number.isNaN(editedState?.['totalTarget']) ? '' : editedState?.['totalTarget'].toFixed(2)}
                            disabled={true}
                            style={{
                              textAlign: 'center',
                              height: '32px',
                              width: '80%',
                              cursor: 'text',
                            }}></input>
                        </div>
                      </td>
                      <td style={{ width: '25%', paddingBottom: '15px' }}>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                          <input
                            className='form_input'
                            key={`reduction-${formKey}`}
                            type={'number'}
                            value={
                              isNaN(editedState?.['absoluteReduction']) ? '' : editedState?.['absoluteReduction']?.toFixed(2)
                            }
                            disabled={true}
                            style={{
                              textAlign: 'center',
                              height: '32px',
                              width: '80%',
                              cursor: 'text',
                            }}></input>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ paddingTop: 10, paddingLeft: 20, width: '25%' }}>Long Term SBT Formulation</td>
                      <td colSpan={3}>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                          <input
                            className='form_input'
                            type={'text'}
                            value={
                              formulation
                                ? `${orgDetails?.name} commits to reduce ${targetCovLabel} emissions ${editedState?.[
                                    'absoluteReduction'
                                  ]?.toFixed(2)}% by ${editedState?.['targetYear']} from a ${
                                    editedState?.['baseYear']
                                  } base year`
                                : ''
                            }
                            disabled={true}
                            style={{
                              textAlign: 'center',
                              height: '32px',
                              width: '93%',
                              cursor: 'text',
                            }}></input>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div style={{ display: canSave ? 'flex' : 'none', justifyContent: 'center' }}>
                  <AddButton
                    style={{ marginTop: 20 }}
                    onClick={() => {
                      showLoader(true);
                      console.log('Save', editedState);
                      saveTargets({ ...editedState, useData: true });
                    }}
                    disabled={loader}>
                    {loader ? <i className='fa fa-spin fa-spinner'></i> : <i className='fa-solid fa-save'></i>} Save
                  </AddButton>
                </div>
              </div>
              <div style={{ marginTop: 20 }}>
                {formulation && editedState?.['total'] ? (
                  <NetZeroChart
                    targetYear={editedState?.['targetYear']}
                    baseYear={editedState?.['baseYear']}
                    reduction={editedState?.['absoluteReduction']}
                    targetCoverage={editedState?.['targetCoverage']}
                    data={editedState?.['total']}
                    useData={true}
                    multiYearData={multiYearData}
                  />
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const currentYear = new Date().getFullYear();
  const baseYears = Array.from({ length: currentYear - 2019 + 1 }, (_, i) => 2019 + i);
  const targetYears = Array.from({ length: 11 }, (_, i) => 2040 + i);

  const sectorPathways = [
    { label: 'Cross-sector pathway', value: 'Cross-sector pathway' },
    { label: 'FLAG (Agriculture only)', value: 'FLAG', isDisabled: true },
    { label: 'Iron and steel', value: 'Iron and steel' },
    { label: 'Cement', value: 'Cement' },
    { label: 'Services - Buildings', value: 'Services - Buildings' },
    { label: 'Residential - Buildings', value: 'Residential - Buildings' },
    { label: 'Power generation', value: 'Power generation', isDisabled: true },
  ];

  const targetCoverage = [
    { label: 'Scope 1', value: ['scope1'] },
    { label: 'Scope 1 & 2', value: ['scope1', 'scope2'] },
    { label: 'Scope 3', value: ['scope3'] },
    { label: 'Scopes 1, 2 & 3', value: ['scope1', 'scope2', 'scope3'] },
  ];

  const absoluteTargets = new Map();
  absoluteTargets.set('Cross-sector pathway', 90);
  absoluteTargets.set('FLAG (Agriculture only)', 72);
  absoluteTargets.set('Iron and steel', 92.99);
  absoluteTargets.set('Cement', 94.95);
  absoluteTargets.set('Services - Buildings', 99.6);
  absoluteTargets.set('Residential - Buildings', 97.89);

  const fields = [
    {
      type: 'select',
      name: 'targetCoverage',
      label: 'Target Coverage',
      required: true,
      options: targetCoverage,
    },
    {
      type: 'input',
      format: 'text',
      name: 'targetSettingMethod',
      label: 'Target Setting Method',
      value: 'Absolute Contraction',
      disabled: true,
      adornment: 'To calculate intensity targets, please use Section 2.1. Input data (Intensity targets)',
    },
    {
      type: 'select',
      name: 'baseYear',
      label: 'Base Year',
      required: true,
      options: baseYears?.map((y) => {
        return { label: y.toString(), value: y };
      }),
    },
    {
      type: 'select',
      name: 'targetYear',
      label: 'Target Year',
      required: true,
      options: targetYears?.map((y) => {
        return { label: y.toString(), value: y };
      }),
    },
    {
      type: 'select',
      name: 'sectorPathway',
      label: 'Sector Pathway',
      required: true,
      options: sectorPathways,
      adornment: 'Please select sector pathway',
    },
    {
      name: 'scope1',
      type: 'input',
      format: 'number',
      label: 'Scope 1 Emissions',
      required: true,
      adornment: 'tCO2e',
      copy: true,
    },
    {
      name: 'scope2',
      type: 'input',
      format: 'number',
      label: 'Scope 2 Emissions',
      required: true,
      adornment: 'tCO2e',
      copy: true,
    },
    {
      name: 'scope3',
      type: 'input',
      format: 'number',
      label: 'Scope 3 Emissions',
      required: true,
      adornment: 'tCO2e',
      copy: true,
    },
    {
      name: 'total',
      type: 'input',
      format: 'number',
      label: 'Total Emissions in * (tCO2e)',
      disabled: true,
      adornment: 'tCO2e',
    },
  ];
  return {
    orgDetails: state?.organisation?.details,
    currentOrg: state.currentOrganisation,
    SBTExcel: state?.whiteLabelAdmin?.scienceBasedTarget,
    branding: state?.branding?.colors,
    fields,
    targetCoverage,
    absoluteTargets,
    dashboardData: state?.dashboard?.data,
    currentYear,
    multiYearData: state.dashboard?.data?.multiYear,
    loader: state?.showLoader,
  };
};

const mapDispatchToProps = (dispatch) => ({
  //sumValues: (data, year, org) => dispatch(DASHBOARDACTIONS.sumValues(data, year, org)),
  saveTargets: (targets) => dispatch(ORGANISATIONACTIONS.saveTargets(targets)),
  showLoader: (show) => dispatch(showLoader(show)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NetZero);
