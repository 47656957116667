import React from 'react';
import { Actions } from '../../store/actions/facilityActions';
import { connect } from 'react-redux';

import { RowBtn } from '../../styledComponents/GlobalStyle';

const FacilityRow = ({ facilityDetails, showFacilitiesDeleteModal }) => {
  return (
    <tr>
      <td width='20%' style={{ textAlign: 'left' }}>
        {facilityDetails?.name}
      </td>
      <td width='20%'>{facilityDetails?.isPrimary === true ? 'Yes' : 'No' || 'N/A'}</td>
      <td width='20%'>{facilityDetails?.postCode}</td>
      <td width='20%'>{facilityDetails?.externalId}</td>
      <td>
        <RowBtn id='Facility-view-edit' to={`/facilities/${facilityDetails.id}`} className='view-edit-button'>
          View/Edit
        </RowBtn>
      </td>
      <td>
        <i
          id='deleteFacilityBtn'
          style={{ cursor: 'pointer' }}
          onClick={() => showFacilitiesDeleteModal(true, facilityDetails)}
          className='fa fa-trash-o fa-lg'
        />
      </td>
    </tr>
  );
};

const mapStateToProps = (state) => {
  return {
    facilityModal: state.facility?.facilitiesDeleteModal,
  };
};

const mapDispatchToProps = (dispatch) => ({
  showFacilitiesDeleteModal: (status, item) => dispatch(Actions.showFacilitiesDeleteModal(status, item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FacilityRow);
