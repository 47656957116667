import React from 'react';
import { Card, Divider } from '@mui/material';
import { Actions } from '../../store/actions/dashboardActions';
import { connect } from 'react-redux';

const DashboardCard = ({ title, value, unit, userAdded, id, removeComponent, setDashboardModal }) => {
  return (
    <Card
      className='dashboard-card'
      variant='outlined'
      style={{
        borderRadius: '20px',
      }}>
      <div style={{ display: userAdded ? 'flex' : 'none', position: 'absolute', right: 0, top: 0 }}>
        <button
          style={{ border: '0px', background: 'inherit', padding: '8px 15px' }}
          onClick={() => {
            removeComponent(id);
          }}>
          <i className='fa-solid fa-xmark' style={{ fontSize: '14px' }}></i>
        </button>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
        <h4 className='card-title'>
          <b>{title}</b>
        </h4>
      </div>
      <Divider />
      <div style={{ display: 'flex', justifyContent: 'center', margin: 10, alignItems: 'center' }}>
        <h3 style={{ display: 'flex', alignItems: 'center', alignContent: 'center' }} className='card-value'>
          <b>{value}</b>
        </h3>
        <h6 style={{ display: 'flex', alignItems: 'center', alignContent: 'center' }} className='card-unit'>
          <b>{unit}</b>
        </h6>
      </div>
      <div
        style={{
          display: userAdded ? 'flex' : 'none',
          position: 'absolute',
          right: 0,
          bottom: 0,
        }}>
        <button
          style={{ border: '0px', background: 'inherit', padding: '8px 15px' }}
          onClick={() => {
            setDashboardModal({ show: true, id: id });
          }}>
          <i class='fa-solid fa-pencil' style={{ fontSize: '15px' }}></i>
        </button>
      </div>
    </Card>
  );
};

const mapStateToProps = (state) => {};

const mapDispatchToProps = (dispatch) => {
  return {
    removeComponent: (id) => dispatch(Actions.removeComponent(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardCard);
