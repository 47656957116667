import axios from 'axios';
import { showLoader, toggleAlertMessage } from '../action';
import createSignedRequest from './createSignedRequest';
const { apiURL } = require('./apiURL');

const Types = {
  SAVE_COMPONENT: 'DASHBOARD_SAVE_COMPONENT',
  REMOVE_COMPONENT: 'DASHBOARD_REMOVE_COMPONENT',
  EDIT_COMPONENT: 'DASHBOARD_EDIT_COMPONENT',
  SAVE_SUM: 'DASHBOARD_SAVE_SUM',
  STORE_TARGETS: 'DASHBOARD_STORE_TARGETS',
};

const saveComponent = (component) => ({
  type: Types.SAVE_COMPONENT,
  payload: component,
});

const removeComponent = (component) => ({
  type: Types.REMOVE_COMPONENT,
  payload: component,
});

const editComponent = (component) => ({
  type: Types.EDIT_COMPONENT,
  payload: component,
});

const saveSum = (sum) => ({
  type: Types.SAVE_SUM,
  payload: sum,
});

const dashboardDataCache = new Map();

const sumValues = (component, year, org, dataType) => {
  return async (dispatch, getState) => {
    const state = getState();
    dispatch(showLoader(true));

    const url = `${apiURL}/my/dashboard/data`;

    let data;
    function generateKey(year, org, dataType) {
      return `${org}_${year}_${dataType}`; // You can choose any delimiter
    }

    try {
      console.log('cache:', dashboardDataCache);
      if (dashboardDataCache.has(generateKey(year, org, dataType))) {
        data = dashboardDataCache.get(generateKey(year, org, dataType));

        dispatch(saveSum(data));
        dispatch(showLoader(false));
      } else {
        const response = await axios(
          await createSignedRequest('POST', url, JSON.stringify(component), {
            Organisation: String(state.currentOrganisation),
          })
        );

        data = response?.data;

        if (response?.data) {
          dashboardDataCache.set(generateKey(year, org, dataType), data);
        }

        dispatch(saveSum(data));
        dispatch(showLoader(false));
      }

      return data;
    } catch (error) {
      dispatch(showLoader(false));
      dispatch(toggleAlertMessage(true, 'Error fetching data', 'Error'));
    }
  };
};

const storeTargets = (targets) => ({
  type: 'DASHBOARD_STORE_TARGETS',
  payload: targets,
});

const getTargets = () => {
  return async (dispatch, getState) => {
    const state = getState();
    dispatch(showLoader(true));

    const url = `${apiURL}/my/organisation/targets`;

    try {
      const response = await axios(
        await createSignedRequest('GET', url, null, {
          Organisation: String(state.currentOrganisation),
        })
      );

      dispatch(storeTargets(response?.data));
      dispatch(showLoader(false));
    } catch (error) {
      dispatch(showLoader(false));
      dispatch(toggleAlertMessage(true, 'Error fetching data', 'Error'));
    }
  };
};

const Actions = {
  saveComponent,
  removeComponent,
  editComponent,
  saveSum,
  sumValues,
  getTargets,
};

export { Actions, Types };
