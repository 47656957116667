import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Breadcrumb from '../Common/Breadcrumb';
import { AddButton } from '../../styledComponents/GlobalStyle';
import { Actions as USAGEACTIONS } from '../../store/actions/usageActions';
import InvoiceModal from './InvoiceModal';
import { Waveform } from '@uiball/loaders';
import { Divider, Tooltip } from '@mui/material';
import { BetaTag, NoPermissions } from '../Common/ReusableComponents/Reusable';

const ImportInvoices = ({
  invoices,
  showInvoiceModal,
  invoiceModal,
  currentOrganisation,
  getInvoices,
  facilities,
  branding,
  fetchInvoice,
  features,
  hasAccess,
}) => {
  const [electricityHeaders, setElectricityHeaders] = useState([]);
  const [gasHeaders, setGasHeaders] = useState([]);
  const [loading, setLoading] = useState({ status: false, id: null });
  const { btnTextColor, primaryButton } = branding;
  const { textColor } = branding;

  useEffect(() => {
    if (currentOrganisation) {
      getInvoices();
    }
  }, [currentOrganisation]);

  useEffect(() => {
    if (invoices) {
      setElectricityHeaders(tableHeaders(invoices?.filter((invoice) => invoice?.type === 'Electricity')));
      setGasHeaders(tableHeaders(invoices?.filter((invoice) => invoice?.type === 'Gas')));
    }
  }, [invoices]);

  function toShowCase(str) {
    return str
      ?.replace(/([a-z])([A-Z])/g, '$1 $2') // Add space before each uppercase letter
      ?.replace(/^./, (str) => str.toUpperCase());
  }

  const tableHeaders = (invoices) => {
    // Initialize an empty set to collect unique keys
    const uniqueKeys = new Set();

    // Loop through each object in the array and add keys to the set
    invoices?.forEach((invoice) => {
      Object.keys(invoice)?.forEach((key) => {
        if (key !== 'id' && key !== 'organisation' && key !== 'key') {
          // Exclude these keys
          uniqueKeys.add(key);
        }
      });
    });

    // Convert the set to an array and map over it to render <th> elements
    return [...uniqueKeys]?.sort((a, b) => a.localeCompare(b))?.map((key) => <th key={key}>{toShowCase(key)}</th>);
  };

  const invoiceButton = (invoice) => {
    return (
      <td>
        {loading.status == true && loading.id == invoice?.id ? (
          <i className='fa fa-spin fa-spinner' style={{ marginRight: 5 }} />
        ) : (
          <Tooltip sx={{ fontSize: 20 }} title={<p style={{ fontSize: 12, marginBottom: 0 }}>Open Invoice</p>}>
            <i
              className='fa fa-file-text'
              style={{ fontSize: '1.5rem' }}
              onClick={async () => {
                setLoading({ status: true, id: invoice?.id });
                window.open(await fetchInvoice(invoice['key']));
                setLoading({ status: false, id: null });
              }}></i>
          </Tooltip>
        )}
      </td>
    );
  };

  const actionButtons = (invoice) => {
    return (
      <td>
        <Tooltip sx={{ fontSize: 20 }} title={<p style={{ fontSize: 12, marginBottom: 0 }}>Edit Invoice</p>}>
          <button
            className='btn btn-xs'
            style={{ marginRight: 5, backgroundColor: primaryButton, color: btnTextColor }}
            onClick={() => {
              showInvoiceModal(true, 'edit', invoice);
            }}>
            <i className='fa fa-pencil' style={{ color: 'black' }}></i>
          </button>
        </Tooltip>
        <Tooltip sx={{ fontSize: 20 }} title={<p style={{ fontSize: 12, marginBottom: 0 }}>Delete Invoice</p>}>
          <button
            className='btn btn-danger btn-xs'
            onClick={() => {
              showInvoiceModal(true, 'delete', invoice);
            }}>
            <i className='fa-solid fa-trash' style={{ color: 'black' }}></i>
          </button>
        </Tooltip>
      </td>
    );
  };

  if (!hasAccess && !features?.find((f) => f.featureKey === 'INVOICE_IMPORT')?.enabledByDefault) {
    return <NoPermissions />;
  } else {
    return (
      <>
        <Breadcrumb
          items={[
            { label: 'Usage', link: '/usage' },
            { label: 'Import Invoices', link: `/usage/invoices` },
          ]}
        />
        <div className='row'>
          <div className='col-lg-12'>
            <section className='panel'>
              <header className='panel-heading'>
                <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    Imported Invoices
                    {features?.find((f) => f.featureKey === 'INVOICE_IMPORT')?.isBeta && (
                      <BetaTag backgroundColor={textColor} color={'#fff'} />
                    )}
                  </div>
                  <div>
                    <AddButton onClick={() => showInvoiceModal(true, 'add')}>
                      <i className='fa-solid fa-file-import' style={{ marginRight: 8 }}></i>
                      {'Upload Invoice'}
                    </AddButton>
                  </div>
                </div>
              </header>
              {!invoices && (
                <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                  <Waveform size={40} lineWeight={3.5} speed={1} color='black' />
                </div>
              )}
              {invoices?.length === 0 && (
                <div class='alert-message' style={{ display: 'flex', alignItems: 'center' }}>
                  <i class='fa-solid fa-triangle-exclamation'></i>
                  <span>No Invoices Found</span>
                </div>
              )}
              <div className='panel-body' style={{ overflow: 'auto' }}>
                <div style={{ overflowX: 'auto' }}>
                  {invoices && invoices?.length > 0 && electricityHeaders?.length > 0 && (
                    <>
                      <h3 style={{ marginTop: 0, display: 'flex', justifyContent: 'center' }}>Electricity Invoices</h3>
                      <table
                        className='table table-hover table-responsive'
                        id='Electricity Table'
                        style={{ overflow: 'auto' }}>
                        <thead>
                          <tr>
                            <th>Invoice</th>
                            {electricityHeaders}
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {invoices
                            ?.filter((i) => i?.type === 'Electricity')
                            ?.map((invoice, index) => (
                              <tr key={index}>
                                {invoiceButton(invoice)}
                                {electricityHeaders?.map((header, index) => (
                                  <td key={index}>
                                    <div style={{ overflow: 'auto', maxHeight: '300px' }}>
                                      {header?.key === 'facility' ? (
                                        facilities?.find((f) => f.id === invoice[header?.key])?.name ?? 'N/A' // For 'facility', find the facility name from the list of facilities
                                      ) : header?.key !== 'meterReadings' ? (
                                        invoice[header?.key] ?? 'N/A'
                                      ) : // For 'meterReadings', loop through readings and dynamically display all key-value pairs
                                      invoice[header?.key]?.length > 0 ? (
                                        invoice[header?.key]?.map(
                                          (reading, idx) =>
                                            reading?.billedUnits != 0 ? ( // Check if billedUnits is not 0
                                              <div key={idx}>
                                                {Object.keys(reading)?.map((key) => (
                                                  <p key={key}>
                                                    <strong>{toShowCase(key)}:</strong> {reading[key] ?? 'N/A'}
                                                  </p>
                                                ))}
                                              </div>
                                            ) : null // If billedUnits is 0, skip the reading
                                        )
                                      ) : (
                                        <p>No readings available</p>
                                      )}
                                    </div>
                                  </td>
                                ))}
                                {actionButtons(invoice)}
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </>
                  )}
                </div>
                <div style={{ overflowX: 'auto' }}>
                  {invoices && invoices?.length > 0 && gasHeaders?.length > 0 && <Divider style={{ marginBottom: 20 }} />}
                  {invoices && invoices?.length > 0 && gasHeaders?.length > 0 && (
                    <>
                      <h3 style={{ display: 'flex', marginTop: 0, justifyContent: 'center' }}>Gas Invoices</h3>
                      <table className='table table-hover table-responsive' id='Gas Table' style={{ overflow: 'auto' }}>
                        <thead>
                          <tr>
                            <th>Invoice</th>
                            {gasHeaders}
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {invoices
                            ?.filter((i) => i?.type === 'Gas')
                            ?.map((invoice, index) => (
                              <tr key={index}>
                                {invoiceButton(invoice)}
                                {gasHeaders?.map((header, index) => (
                                  <td key={index}>
                                    <div style={{ overflow: 'auto', maxHeight: '300px' }}>
                                      {header?.key === 'facility' ? (
                                        facilities?.find((f) => f.id === invoice[header?.key])?.name ?? 'N/A'
                                      ) : header?.key !== 'readings' &&
                                        invoice?.[header?.key] &&
                                        typeof invoice?.[header?.key] === 'object' ? (
                                        Object.keys(invoice?.[header?.key])?.map((key) => (
                                          <p key={key}>
                                            <strong>{toShowCase(key)}:</strong> {invoice[header?.key][key] ?? 'N/A'}
                                          </p>
                                        ))
                                      ) : header?.key !== 'readings' ? (
                                        invoice[header?.key] ?? 'N/A'
                                      ) : invoice[header?.key]?.length > 0 ? (
                                        invoice[header?.key]?.map((reading, idx) =>
                                          reading?.billedUnits != 0 ? (
                                            <div key={idx}>
                                              {Object.keys(reading)?.map((key) => (
                                                <p key={key}>
                                                  <strong>{toShowCase(key)}:</strong> {reading[key] ?? 'N/A'}
                                                </p>
                                              ))}
                                            </div>
                                          ) : null
                                        )
                                      ) : (
                                        <p>No readings available</p>
                                      )}
                                    </div>
                                  </td>
                                ))}
                                {actionButtons(invoice)}
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </>
                  )}
                </div>
              </div>
            </section>
            {invoiceModal && <InvoiceModal />}
          </div>
        </div>
      </>
    );
  }
};

const mapStateToProps = (state) => ({
  invoices: state?.invoices || null,
  invoiceModal: state?.invoiceModal?.status,
  currentOrganisation: state?.currentOrganisation,
  facilities: state?.facility?.list,
  branding: state?.branding?.colors,
  features: state?.features,
  branding: state?.branding?.colors,
  hasAccess: state.organisation?.details?.features?.includes('INVOICE_IMPORT') || false,
});

const mapDispatchToProps = (dispatch) => ({
  showInvoiceModal: (status, action, item) => dispatch(USAGEACTIONS.showInvoiceModal(status, action, item)),
  getInvoices: () => dispatch(USAGEACTIONS.getInvoices()),
  fetchInvoice: (key) => dispatch(USAGEACTIONS.fetchInvoice(key)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImportInvoices);
