import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, FormControlLabel, Switch, Typography } from '@mui/material/';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Actions as AUTHENTICATIONACTIONS } from '../../store/actions/authenticationActions';
import { Actions as PROFILEACTIONS } from '../../store/actions/profileActions';
import { Actions as SIDEBARACTIONS } from '../../store/actions/sidebarActions';
import { SidebarLink, SidebarMailto } from '../../styledComponents/GlobalStyle';
import AdminPortalSideBar from './AdminPortalSideBar';
import { BetaTag } from './ReusableComponents/Reusable';

const Sidebar = ({
  show,
  branding,
  cyfAdmin,
  whiteLabelAdmin,
  isLocal,
  toggleAdminProfile,
  showAdminPortal,
  sidebarOpt,
  openModal,
  features,
  availableFeatures,
}) => {
  const [state, setState] = useState({ admin: true, whiteLabelAdmin: false });
  const [adminView, setAdminView] = useState(false);

  const { sidebarBackground, sidebarLinkColor, sidebarLinkHover, btnTextColor } = branding.colors || {};

  const sidebarClass = `nav-collapse ${!show && `hide-left-bar`}`;

  if (adminView) return <AdminPortalSideBar adminView={adminView} setAdminView={setAdminView} />;

  return (
    <aside>
      <div id='sidebar' className={sidebarClass}>
        <div className='leftside-navigation'>
          <ul className='sidebar-menu admin-view' id='nav-accordion' style={{ backgroundColor: sidebarBackground }}>
            <div>
              {Object.keys(sidebarOpt)
                .filter((opt) => {
                  if (whiteLabelAdmin) {
                    return sidebarOpt[opt].exclusiveFor?.includes(whiteLabelAdmin) || !sidebarOpt[opt].exclusiveFor;
                  } else {
                    return sidebarOpt[opt].exclusiveFor?.includes(cyfAdmin) || !sidebarOpt[opt].exclusiveFor;
                  }
                })
                .filter((opt) => {
                  //check if feature is enabled by default, if not check if user has access to the feature
                  if (sidebarOpt[opt]?.feature) {
                    let feature = features?.find((f) => f.featureKey === sidebarOpt[opt]?.feature);
                    if (feature?.enabledByDefault === true) {
                      return sidebarOpt[opt];
                    } else if (feature?.enabledByDefault === false && availableFeatures?.includes(feature?.featureKey)) {
                      return sidebarOpt[opt];
                    }
                  } else {
                    return sidebarOpt[opt];
                  }
                })
                .map((key, index) => {
                  const type = sidebarOpt[key].type;
                  const feature = sidebarOpt[key]?.feature;
                  const isBeta = features?.find((f) => f.featureKey === feature)?.isBeta;
                  const betaTag = feature && isBeta === true && <BetaTag />;
                  return (
                    <li key={index}>
                      {type === 'link' && (
                        <SidebarLink
                          key={index}
                          id={sidebarOpt[key].id}
                          to={sidebarOpt[key].to}
                          style={sidebarOpt[key].style}>
                          {sidebarOpt[key].icon}
                          <span>{sidebarOpt[key].title}</span>
                          {betaTag}
                        </SidebarLink>
                      )}

                      {type === 'mailto' && (
                        <SidebarMailto key={index} href={sidebarOpt[key].href} target='_blank' style={sidebarOpt[key].style}>
                          {sidebarOpt[key].icon}
                          <span>{sidebarOpt[key].title}</span>
                        </SidebarMailto>
                      )}
                      {type === 'accordion' && (
                        <Accordion className={'sidebar-accordion'} key={index}>
                          <AccordionSummary
                            className={'sidebar-label list'}
                            expandIcon={
                              <ExpandMore
                                sx={{
                                  marginRight: 4,
                                  '--sidebar-accordion-icon-color': sidebarLinkColor,
                                }}
                              />
                            }>
                            <Typography
                              className={'sidebar-label'}
                              sx={{
                                '--sidebar-accordion-label-color': sidebarLinkColor,
                              }}>
                              {sidebarOpt[key]?.icon}
                              <span style={{ marginLeft: 5, alignItems: 'center' }}>{sidebarOpt[key]?.title}</span>
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            {sidebarOpt[key]?.options.map((option, index) => {
                              if (option.type === 'mailto') {
                                return (
                                  <SidebarMailto key={index} href={option.href} target={'_blank'} style={option.style}>
                                    <div
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                      }}>
                                      {option.icon}
                                      <span>{option.title}</span>
                                    </div>
                                  </SidebarMailto>
                                );
                              } else if (option.type === 'accordion') {
                                return (
                                  <Accordion className={'sidebar-accordion'} key={index}>
                                    <AccordionSummary
                                      className={'sidebar-label list'}
                                      expandIcon={
                                        <ExpandMore
                                          sx={{ marginRight: 4, '--sidebar-accordion-icon-color': sidebarLinkColor }}
                                        />
                                      }>
                                      <Typography
                                        className={'sidebar-label'}
                                        sx={{ '--sidebar-accordion-label-color': sidebarLinkColor }}>
                                        {option?.icon}
                                        <span style={{ marginLeft: 5, alignItems: 'center' }}>{option?.title}</span>
                                      </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      {option?.options.map((innerOption, index) => {
                                        return (
                                          <SidebarLink
                                            key={index}
                                            id={innerOption.id}
                                            to={'/#'}
                                            onClick={() => openModal(innerOption.to)}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                              {innerOption.icon}
                                              <span>{innerOption.title}</span>
                                            </div>
                                          </SidebarLink>
                                        );
                                      })}
                                    </AccordionDetails>
                                  </Accordion>
                                );
                              } else if (option.type === 'video') {
                                return (
                                  <SidebarLink key={index} id={option.id} onClick={() => openModal(option.link)} to='/#'>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                      {option.icon}
                                      <span>{option.title}</span>
                                    </div>
                                  </SidebarLink>
                                );
                              } else {
                                return (
                                  <SidebarLink to={option.to} key={index} id={option.id}>
                                    <div style={{ display: 'flex' }}>
                                      {option.icon}
                                      <span>{option.title}</span>
                                    </div>
                                  </SidebarLink>
                                );
                              }
                            })}
                          </AccordionDetails>
                        </Accordion>
                      )}
                    </li>
                  );
                })}
            </div>
            <div>
              {isLocal && (
                <li style={{ borderTop: '1px solid rgba(255,255,255,0.05)', display: 'flex' }}>
                  <FormControlLabel
                    className={'sidebar-toggle'}
                    control={
                      <Switch
                        name='admin'
                        checked={state.admin}
                        onClick={(e) => {
                          setState({ ...state, [e.target.name]: e.target.checked });
                          toggleAdminProfile(!state.admin, 'cyfAdmin');
                        }}
                      />
                    }
                    label={<Typography className={'sidebar-label'}>Admin User</Typography>}
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        name='whiteLabelAdmin'
                        checked={state.whiteLabelAdmin}
                        onClick={(e) => {
                          setState({ ...state, [e.target.name]: e.target.checked });
                          toggleAdminProfile(!state.whiteLabelAdmin, 'whiteLabelAdmin');
                        }}
                      />
                    }
                    label={<Typography className={'sidebar-label'}>White Label Admin</Typography>}
                  />
                </li>
              )}
              <li style={{ borderTop: '1px solid rgba(255,255,255,0.05)', display: showAdminPortal ? 'block' : 'none' }}>
                <FormControlLabel
                  className={'sidebar-toggle'}
                  control={<Switch checked={adminView} onClick={() => setAdminView(!adminView)} />}
                  label={
                    <Typography sx={{ '--sidebar-toggle-color': sidebarLinkColor }} className={'sidebar-label'}>
                      Admin Portal
                    </Typography>
                  }
                />
              </li>
            </div>
          </ul>
        </div>
      </div>
    </aside>
  );
};

const mapStateToProps = (state) => {
  const { cyfAdmin, whiteLabelAdmin, consultantAdmin, greenElementUser, permissions } = state.profile?.details || {};
  const { orgMissingCompulsoryData } = state.organisation || {};
  const { hasSubscriptionActive, features } = state.organisation?.details || {};
  const wLSettings = state.whiteLabelAdmin;
  const furtherWL = window.location.hostname.includes('furthr');
  const worldlandtrust = window.location.hostname.includes('worldlandtrust');
  const showAdminPortal = permissions?.portal?.includes('VIEW_ADMIN');
  const isWhiteLabelApp = state.isWhiteLabelApp;
  const blockSiderBarBtn = !cyfAdmin && (!hasSubscriptionActive || orgMissingCompulsoryData);

  const sidebarOpt = {};
  sidebarOpt.dashboard = {
    type: 'link',
    id: '',
    title: 'Dashboard',
    to: '/',
    icon: <i className='fa fa-dashboard' />,
  };
  sidebarOpt.organisations = {
    type: 'link',
    id: 'orgsSidebarLink',
    title: 'Organisations',
    to: '/organisations',
    icon: <i className='fa fa-building' />,
  };
  sidebarOpt.facilities = {
    type: 'link',
    id: 'facilitiesSidebarLink',
    title: 'Facilities',
    to: '/facilities',
    icon: <i className='fa fa-map-marker' />,
    style: { pointerEvents: blockSiderBarBtn ? 'none' : 'auto' },
  };
  sidebarOpt.usage = {
    type: 'link',
    id: 'usageSidebarLink',
    title: 'Usage Data',
    to: '/usage',
    icon: <i className='fa-regular fa-chart-bar' />,
    style: { pointerEvents: blockSiderBarBtn ? 'none' : 'auto' },
  };
  sidebarOpt.reports = {
    type: 'link',
    id: 'reportsSidebarLink',
    title: 'Reports',
    to: '/reports',
    icon: <i className='fa fa-file-text' />,
    style: { pointerEvents: blockSiderBarBtn ? 'none' : 'auto' },
  };
  sidebarOpt.users = {
    type: 'link',
    id: 'usersSidebarLink',
    title: 'Users',
    to: '/users',
    icon: <i className='fa fa-users' />,
    style: { pointerEvents: blockSiderBarBtn ? 'none' : 'auto' },
  };
  sidebarOpt.profile = {
    type: 'link',
    id: 'profileSidebarLink',
    title: 'Your Profile',
    to: '/profile',
    icon: <i className='fa fa-user' />,
  };
  sidebarOpt.developer = {
    type: 'link',
    id: 'developerSidebarLink',
    title: `Developer Dashboard`,
    to: '/developer',
    icon: <i className='fa fa-code' />,
    feature: 'API_ACCESS',
  };
  sidebarOpt.targets = {
    type: 'link',
    id: 'targetsSidebarLink',
    title: 'Science-Based Targets',
    to: '/netZero',
    icon: <i className='fa-solid fa-seedling' />,
    feature: 'SCIENCE_BASED_TARGETS',
  };

  sidebarOpt.resources = {
    type: 'accordion',
    id: 'resourcesSidebar',
    title: 'Resources and Support',
    icon: <i className='fa-solid fa-screwdriver-wrench' />,
    options: [
      worldlandtrust // WLT have their own video tutorial and thus do no need the dropdown
        ? {
            type: 'video',
            title: 'Tutorial',
            link: wLSettings?.tutorialURL, //'https://www.youtube.com/watch?v=Jgv4rhRRXQU',
            icon: <i className='fa fa-info' />,
            style: { display: !furtherWL ? 'block' : 'none', cursor: 'pointer' },
          }
        : isWhiteLabelApp // White labels are shown the tutorial videos in a dropdown
        ? {
            type: 'accordion',
            title: 'Tutorial Videos',
            icon: <i className='fa fa-video' />,
            options: [
              {
                type: 'link',
                title: 'Organisational Set Up Tutorial',
                to: wLSettings?.organisationSetUpTutorial,
                icon: <i className='fa fa-play' />,
              },
              {
                type: 'link',
                title: 'Create Facilities Tutorial',
                to: wLSettings?.facilitiesTutorial,
                icon: <i className='fa fa-play' />,
              },
              {
                type: 'link',
                title: 'Adding Usage Data Tutorial',
                to: wLSettings?.usageDataTutorial,
                icon: <i className='fa fa-play' />,
              },
              {
                type: 'link',
                title: 'Using Import Templates Tutorial',
                to: wLSettings?.importTemplatesTutorial,
                icon: <i className='fa fa-play' />,
              },
              {
                type: 'link',
                title: 'Creating Reports & Results Tutorial',
                to: wLSettings?.reportsTutorial,
                icon: <i className='fa fa-play' />,
              },
            ],
          }
        : {
            //Cyf Users see Knowledge hub instead of videos dropdown
            type: 'mailto',
            title: 'Knowledge Hub',
            href: wLSettings?.knowledgeHub,
            icon: <i className='fa-solid fa-qrcode'></i>,
            style: {
              pointerEvents: !cyfAdmin && !hasSubscriptionActive ? 'none' : 'auto',
              cursor: 'pointer',
              display:
                wLSettings?.knowledgeHub === undefined ||
                wLSettings?.knowledgeHub === '' ||
                wLSettings?.knowledgeHub === null
                  ? 'none'
                  : 'flex',
            },
          },
      {
        type: 'mailto',
        title: 'Data Helper Tool',
        href: wLSettings?.dataHelperTool,
        icon: <i className='fa fa-book' />,
        style: {
          pointerEvents: !cyfAdmin && !hasSubscriptionActive ? 'none' : 'auto',
          cursor: 'pointer',
          display:
            wLSettings?.dataHelperTool === undefined ||
            wLSettings?.dataHelperTool === '' ||
            wLSettings?.dataHelperTool === null
              ? 'none'
              : 'flex',
        },
      },
      {
        type: 'mailto',
        title: 'Usage Data Guide',
        href: wLSettings?.usageDataGuide,
        icon: <i className='fa-regular fa-chart-bar' />,
        style: {
          pointerEvents: !cyfAdmin && !hasSubscriptionActive ? 'none' : 'auto',
          cursor: 'pointer',
          display:
            wLSettings?.usageDataGuide === undefined ||
            wLSettings?.usageDataGuide === '' ||
            wLSettings?.usageDataGuide === null
              ? 'none'
              : 'flex',
        },
      },
      {
        type: 'mailto',
        title: 'Industries & Facility Types Guide',
        href: wLSettings?.industriesAndFacilitiesTypesGuide,
        icon: <i className='fa-solid fa-hotel' />,
        style: {
          pointerEvents: !cyfAdmin && !hasSubscriptionActive ? 'none' : 'auto',
          cursor: 'pointer',
          display:
            wLSettings?.industriesAndFacilitiesTypesGuide === undefined ||
            wLSettings?.industriesAndFacilitiesTypesGuide === '' ||
            wLSettings?.industriesAndFacilitiesTypesGuide === null
              ? 'none'
              : 'flex',
        },
      },
      {
        type: 'mailto',
        title: 'Activity Data Inventory file',
        href: wLSettings?.activityDataInventoryFile,
        icon: <i className='fa-regular fa-clipboard'></i>,
        style: {
          pointerEvents: !cyfAdmin && !hasSubscriptionActive ? 'none' : 'auto',
          cursor: 'pointer',
          display:
            wLSettings?.activityDataInventoryFile === undefined ||
            wLSettings?.activityDataInventoryFile === '' ||
            wLSettings?.activityDataInventoryFile === null
              ? 'none'
              : 'flex',
        },
      },
      {
        type: 'mailto',
        title: 'Science-Based Net-Zero Target Tool',
        href: wLSettings?.scienceBasedTarget,
        icon: <i className='fa-solid fa-seedling' />,
        style: {
          pointerEvents: !cyfAdmin && !hasSubscriptionActive ? 'none' : 'auto',
          cursor: 'pointer',
          display:
            wLSettings?.scienceBasedTarget === undefined ||
            wLSettings?.scienceBasedTarget === '' ||
            wLSettings?.scienceBasedTarget === null
              ? 'none'
              : 'flex',
        },
      },
      {
        type: 'link',
        title: 'Unit Converter',
        to: '/unitConverter',
        icon: <i className='fa-solid fa-rotate' />,
      },
      {
        type: 'mailto',
        title: 'Methodology',
        href: wLSettings?.methodology,
        icon: <i className='fa-solid fa-circle-info' />,
        style: {
          pointerEvents: !cyfAdmin && !hasSubscriptionActive ? 'none' : 'auto',
          cursor: 'pointer',
          display:
            wLSettings?.methodology === undefined || wLSettings?.methodology === '' || wLSettings?.methodology === null
              ? 'none'
              : 'flex',
        },
      },
      {
        type: 'mailto',
        title: 'FAQs',
        href: wLSettings?.faq,
        icon: <i className='fa fa-question' />,
        disabled: wLSettings?.faq === undefined || wLSettings?.faq === '',
        style: {
          display: wLSettings?.faq === undefined || wLSettings?.faq === '' || wLSettings?.faq === null ? 'none' : 'flex',
        },
      },
      {
        type: 'mailto',
        title: 'Contact Support',
        href: `mailto:${wLSettings?.supportEmail}`,
        icon: <i className='fa fa-envelope fa-2x' />,
        style: { cursor: 'pointer' },
      },
    ],
  };

  return {
    isLocal: state.isLocalDevelopment,
    show: state.sidebar.show,
    branding: state.branding,
    hasSubscriptionActive: state.organisation?.details?.hasSubscriptionActive,
    cyfAdmin: cyfAdmin && 'cyfAdmin',
    whiteLabelAdmin: whiteLabelAdmin && 'whiteLabelAdmin',
    showAdminPortal,
    wLSettings,
    furtherWL,
    sidebarOpt,
    features: state.features,
    availableFeatures: features,
  };
};

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(AUTHENTICATIONACTIONS.logout()),
  toggleAdminProfile: (status, admin) => dispatch(PROFILEACTIONS.toggleAdminProfile(status, admin)),
  toggleSidebar: () => dispatch(SIDEBARACTIONS.toggleSidebar()),
  showBillingDetails: (status) => dispatch(SIDEBARACTIONS.showBillingDetails(status)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
