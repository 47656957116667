import axios from 'axios';
import { showLoader, toggleAlertMessage } from '../action';
import createSignedRequest from './createSignedRequest';
const { apiURL } = require('./apiURL');

// types of action
const Types = {
  LIST: 'FACILITY_LIST',
  TYPES: 'FACILITY_TYPES',
  METRICS: 'FACILITY_METRICS',
  ADD_METRIC: 'FACILITY_ADD_METRIC',
  SAVE_METRIC: 'FACILITY_SAVE_METRIC',
  SAVE: 'FACILITY_SAVE',

  FACILITY_METRIC_DELETE_MODAL: 'FACILITY_METRIC_DELETE_MODAL',
  FACILITY_METRIC_DATA_DELETE: 'FACILITY_METRIC_DATA_DELETE',

  FACILITY_DELETE_MODAL: 'FACILITY_DELETE_MODAL',
  FACILITY_DATA_DELETE: 'FACILITY_DATA_DELETE',

  FACILITY_IMPORT_SHOW_MODAL: 'FACILITY_IMPORT_SHOW_MODAL',
  FACILITY_IMPORT: 'FACILITY_IMPORT',
  FACILITY_PROCESSING_IMPORTING: 'FACILITY_PROCESSING_IMPORTING',
  FACILITY_ANALYSIS_IMPORT_MODAL: 'FACILITY_ANALYSIS_IMPORT_MODAL',
  FACILITY_RESET_ANALYSIS_IMPORT: 'FACILITY_RESET_ANALYSIS_IMPORT',
  FACILITY_ADD_FACILITY_MODAL: 'FACILITY_ADD_FACILITY_MODAL',
  FACILITY_GET_HIERARCHY_FACILITIES: 'FACILITY_GET_HIERARCHY_FACILITIES',
};

const updateFacilityTypes = (response) => ({
  type: Types.TYPES,
  payload: response,
});

const getFacilityTypes = () => {
  return async (dispatch, getState) => {
    try {
      const response = await axios(await createSignedRequest('GET', apiURL + `/impact/facilityTypes`, null));

      dispatch(updateFacilityTypes(response.data));
    } catch (e) {
      console.log('getFacilityTypes Error:', e);
    }
  };
};

const updateFacilities = (response) => ({
  type: Types.LIST,
  payload: response,
});

const getFacilities = () => {
  return async (dispatch, getState) => {
    const state = getState();
    dispatch(showLoader(true));

    try {
      const response = await axios(
        await createSignedRequest('GET', apiURL + `/my/facilities`, null, {
          Organisation: String(state.currentOrganisation),
        })
      );

      console.log('getFacilities', response);

      dispatch(updateFacilities(response.data));
      dispatch(showLoader(false));
    } catch (e) {
      //dispatch(showLoader(false));
      console.log('getFacilities Error:', e);
    }
  };
};

const saveHierarchyFacilities = (response) => ({
  type: Types.FACILITY_GET_HIERARCHY_FACILITIES,
  response,
});

const getHierarchyFacilities = (id) => {
  return async (dispatch, getState) => {
    const state = getState();

    const currentOrganisation = state.currentOrganisation;
    try {
      const response = await axios(
        await createSignedRequest('GET', apiURL + `/my/facilities/hierarchy`, null, {
          Organisation: String(currentOrganisation),
        })
      );

      console.log('getHierarchyFacilities', response);

      dispatch(saveHierarchyFacilities(response.data));
    } catch (e) {
      console.log('getHierarchyFacilities Error:', e);
    }
  };
};

const addFacilityMetric = (facility) => ({
  type: Types.ADD_METRIC,
  facility,
});

const updateFacilityMetrics = (response) => ({
  type: Types.METRICS,
  payload: response,
});

const getFacilityMetrics = () => {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios(
        await createSignedRequest('GET', apiURL + `/my/facilities/metrics`, null, {
          Organisation: String(state.currentOrganisation),
        })
      );

      dispatch(updateFacilityMetrics(response.data));
    } catch (e) {
      console.log('getFacilityMetrics Error:', e);
    }
  };
};

const savedFacilityMetric = (response, metric) => ({
  type: Types.SAVE_METRIC,
  payload: response,
  metric,
});

const saveFacilityMetric = (metric) => {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios(
        await createSignedRequest('POST', apiURL + `/my/facilities/metrics`, JSON.stringify(metric), {
          Organisation: String(state.currentOrganisation),
        })
      );

      if (!response.data.success) {
        dispatch(toggleAlertMessage(true, response.data.message, 'error'));
        return;
      }

      console.log('saveFacilityMetric', response.data);
      dispatch(savedFacilityMetric(response.data, metric));
      dispatch(getFacilityMetrics());
    } catch (e) {
      console.log('saveFacilityMetric Error:', e);
    }
  };
};

const savedFacility = (response, facility) => ({
  type: Types.SAVE,
  payload: response,
  facility,
});

const saveFacility = (facility, history) => {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios(
        await createSignedRequest('POST', apiURL + `/my/facility`, JSON.stringify(facility), {
          Organisation: String(state.currentOrganisation),
        })
      );

      if (!response.data.success) {
        dispatch(toggleAlertMessage(true, response.data.message, 'error'));
        history.push(`/facilities`);
        return;
      }

      if (!facility.id && history && response.data.id) {
        history.push(`/facilities/${response.data.id}`);
      }

      dispatch(savedFacility(response.data, facility));
    } catch (e) {
      console.log('savedFacility Error:', e);
    }
  };
};

const showFacilityMetricDeleteModal = (status, item) => ({
  type: Types.FACILITY_METRIC_DELETE_MODAL,
  status,
  item,
});

const dispatchDeleteFacilityData = (response, id) => ({
  type: Types.FACILITY_METRIC_DATA_DELETE,
  payload: response,
  id,
});

const deleteFacilityData = (id) => {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios(
        await createSignedRequest('DELETE', apiURL + `/my/facility/metric/${id}`, '{}', {
          Organisation: String(state.currentOrganisation),
        })
      );

      console.log('deleteFacilityData', response);
      dispatch(dispatchDeleteFacilityData(response, id));
    } catch (e) {
      console.log('deleteFacilityData Error:', e);
    }
  };
};

const showFacilitiesDeleteModal = (status, item, errorMsg) => ({
  type: Types.FACILITY_DELETE_MODAL,
  status,
  item,
  errorMsg,
});

const dispatchDeleteFacilities = (response, id) => ({
  type: Types.FACILITY_DATA_DELETE,
  payload: response,
  id,
});

const deleteFacilities = (id) => {
  return async (dispatch, getState) => {
    const state = getState();
    dispatch(showLoader(true));

    try {
      const response = await axios(
        await createSignedRequest('DELETE', apiURL + `/my/facility/${id}`, '{}', {
          Organisation: String(state.currentOrganisation),
        })
      );

      console.log('deleteFacilities', response.data);

      if (!response.data.success) {
        dispatch(showFacilitiesDeleteModal(true, null, response.data.message));
        dispatch(showLoader(false));
        return;
      }

      dispatch(dispatchDeleteFacilities(response.data, id));
      dispatch(showLoader(false));
    } catch (e) {
      console.log('deleteFacilities Error:', e);
    }
  };
};

const importingFacilities = (importing) => ({
  type: Types.FACILITY_PROCESSING_IMPORTING,
  importing,
});

const showFacilityImportModal = (status) => ({
  type: Types.FACILITY_IMPORT_SHOW_MODAL,
  status,
});

const processingFacilitiesUpload = (response) => ({
  type: Types.FACILITY_IMPORT,
  payload: response,
});

const processFacilitiesUpload = (file) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { supportEmail } = state?.whiteLabelAdmin || '';
    let errorMessage = `There was an error while importing your facilities, please contact ${supportEmail}`;

    dispatch(importingFacilities(true));

    const importUploadKey = state.importUploadKey;
    const importUploadLink = state.importUploadLink;

    // step 1 upload to AWS
    fetch(importUploadLink, {
      credentials: 'include',
      method: 'PUT',
      body: file,
      headers: { Accept: 'application/json', 'Content-Type': file.type },
    })
      // Step 2 process import data
      .then(async () => {
        try {
          const response = await axios(
            await createSignedRequest('POST', apiURL + `/my/import/facilities`, JSON.stringify({ key: importUploadKey }), {
              Organisation: String(state.currentOrganisation),
            })
          );
          console.log('processFacilitiesUpload', response.data);
          await dispatch(processingFacilitiesUpload(response));
          await dispatch(showAnalysisImportModal(true));
        } catch (e) {
          if (e.response?.data?.message === 'Invalid Template') {
            // Wrong Template Provided
            errorMessage = `You've uploaded an invalid file, please try again with the facilities_template`;
          }
          dispatch(toggleAlertMessage(true, errorMessage, 'error', 3500));
        } finally {
          // Stop loader
          dispatch(importingFacilities(false));
        }
      })
      .catch((e) => {
        dispatch(importingFacilities(false));
        dispatch(toggleAlertMessage(true, 'There was an error while uploading the file!', 'error'));
        console.log('Error Uploading File to S3', e);
      });
  };
};

const showAnalysisImportModal = (status) => ({
  type: Types.FACILITY_ANALYSIS_IMPORT_MODAL,
  status,
});

const showFacilityModal = (status) => ({
  type: Types.FACILITY_ADD_FACILITY_MODAL,
  status,
});

const Actions = {
  getFacilities,
  getFacilityMetrics,
  addFacilityMetric,
  saveFacility,
  saveFacilityMetric,
  getFacilityTypes,
  showFacilityMetricDeleteModal,
  deleteFacilityData,
  showFacilitiesDeleteModal,
  deleteFacilities,
  showFacilityImportModal,
  processFacilitiesUpload,
  showAnalysisImportModal,
  showFacilityModal,
  saveHierarchyFacilities,
  getHierarchyFacilities,
};

export { Actions, Types };
