import React, { useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { connect } from 'react-redux';
import Select from 'react-select';

const NetZeroChart = ({ orgTargets, multiYearData }) => {
  const targetOptions = orgTargets?.map((target) => ({ value: target?.id, label: String(target?.targetYear) }));
  const [orgTarget, setOrgTarget] = useState(orgTargets?.[0]);
  let currentEmissionsData = [];
  if (!orgTarget?.useData) {
    currentEmissionsData = Object.keys(multiYearData)
      ?.filter((year) => Number(year) >= Number(orgTarget?.baseYear))
      ?.map((year) => {
        const yearData = multiYearData[year];

        const filteredData = Object.keys(yearData)
          ?.filter((key) => orgTarget?.targetCoverage.includes(key))
          ?.reduce((sum, key) => {
            sum += yearData[key];
            return sum;
          }, 0);

        return filteredData;
      }, {});
  } /* else {
    currentEmissionsData = Object.entries(multiYearData)
      .filter(([year]) => Number(year) >= Number(baseYear)) // Keep only years >= baseYear
      .map(([, item]) => item.total);
  } */

  const netZero = orgTarget?.useData
    ? orgTarget?.total * (1 - orgTarget?.absoluteReduction / 100)
    : currentEmissionsData[0] * (1 - orgTarget?.absoluteReduction / 100); // Calculate the net zero target

  /* const predictEmissions = (data, startYear, endYear) => { //Function to predict future emissions using linear regression
    const initialYears = data.map((_, index) => startYear + index);
    const initialLength = data.length;

    // Calculate sum of X, Y, XY, and XX for linear regression
    const sumX = initialYears.reduce((acc, year) => acc + year, 0);
    const sumY = data.reduce((acc, value) => acc + value, 0);
    const sumXY = initialYears.reduce((acc, year, index) => acc + year * data[index], 0);
    const sumXX = initialYears.reduce((acc, year) => acc + year * year, 0);

    // Calculate slope (m) and intercept (b) for the linear equation y = mx + b
    const slope = (initialLength * sumXY - sumX * sumY) / (initialLength * sumXX - sumX * sumX);
    const intercept = (sumY - slope * sumX) / initialLength;

    const predictedData = [...data]; // Start with the initial data points

    // Predict values for years beyond the initial data points
    for (let year = startYear + initialLength; year <= endYear; year++) {
      const predictedValue = slope * year + intercept;
      predictedData.push(predictedValue);
    }

    return predictedData;
  }; */

  function calculateDataPoints(currentData, startYear, endYear) {
    if (orgTarget?.useData) {
      const totalYears = endYear - startYear;
      const firstValue = orgTarget?.total;
      const decrement = (firstValue - netZero) / totalYears;

      let resultData = [orgTarget?.total];

      for (let i = 0; i < totalYears; i++) {
        let value = resultData[resultData.length - 1] - decrement;
        resultData.push(value);
      }

      resultData[resultData.length - 1] = netZero;

      return resultData;
    } else {
      const initialLength = currentData?.length;
      const totalYears = endYear - startYear + 1;
      const remainingYears = totalYears - initialLength;

      // Calculate the decrement step to ensure the last value is 10
      const firstValue = currentData[currentData.length - 1];
      const decrement = (firstValue - netZero) / remainingYears;

      // Initialize the result array with the current data
      let resultData = [...currentData];

      // Generate data points from the next year after the initial data to endYear
      for (let i = 0; i < remainingYears; i++) {
        let value = resultData[resultData.length - 1] - decrement;
        resultData.push(value);
      }

      // Ensure the last value is exactly netZero (to account for floating point precision)
      resultData[resultData.length - 1] = netZero;

      return resultData;
    }
  }

  //const predictedEmissions = predictEmissions(currentEmissionsData, startYear, endYear);
  const netZero2040 = orgTarget?.useData
    ? calculateDataPoints(orgTarget?.total, orgTarget?.baseYear, orgTarget?.targetYear)
    : calculateDataPoints(currentEmissionsData, orgTarget?.baseYear, orgTarget?.targetYear);

  const options = {
    credits: false,
    chart: {
      type: 'areaspline',
      borderRadius: 15,
      spacing: [20, 20, 20, 20],
      height: 405,
    },
    title: {
      text: '',
      style: {
        fontSize: '0px',
        fontFamily: 'Open Sans',
        fontWeight: '600',
        color: '#130834',
      },
    },
    yAxis: {
      title: {
        text: 'Emissions (tCO2e)',
      },
      labels: {
        style: {
          fontSize: '0.9em',
        },
      },
      plotLines: [
        {
          color: '#FF0000',
          dashStyle: 'Dash',
          value: netZero, // Position of the line
          width: 2,
          label: {
            align: 'left',
            style: {
              color: '#FF0000',
              fontWeight: 'bold',
              fontSize: '13px',
            },
            text: 'Net Zero',
            x: 15,
          },
          zIndex: 10,
        },
      ],
    },
    xAxis: {
      accessibility: {
        rangeDescription: 'Years',
      },
      categories: Array.from({ length: orgTarget?.targetYear - orgTarget?.baseYear + 1 }, (_, i) =>
        (orgTarget?.baseYear + i).toString()
      ),
    },
    plotOptions: {
      series: {},
    },
    legend: {
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'bottom',
    },
    tooltip: {
      useHTML: true, // Enable HTML to use custom styling
      formatter: function () {
        // Return the formatted tooltip text
        return (
          `<span style="font-size: 12px;">Year: <b>${this.x}</b></span><br/>` +
          `<span style="font-size: 12px;"><span style="color:${this.color};">\u25CF</span> ${
            this.series.name
          }: <b>${this.y.toFixed(2)}</b></span>`
        );
      },
    },
    series: [
      /* {
        name: 'Actual Emissions',
        data: orgTarget?.useData ? orgTarget?.total : [...currentEmissionsData],
        zIndex: 5,
        lineWidth: 1,
        fillOpacity: 1,
        marker: {
          enabled: false,
          symbol: 'circle',
        },
        color: '#1F9B38',
        type: 'areaspline',
      }, */
      {
        name: `Net Zero Target ${orgTarget?.targetYear}`,
        data: netZero2040,
        marker: {
          enabled: false,
          symbol: 'circle',
        },
        zIndex: 4,
        fillOpacity: 1,
        lineWidth: 1,
        color: '#33C2EA',
        type: 'areaspline',
      },
      /* {
        name: 'Predicted Emissions',
        data: [...predictedEmissions],
        marker: {
          enabled: false,
          symbol: 'circle',
        },
        dashStyle: 'Dash',
        zIndex: 2,
        fillOpacity: 1,
        lineWidth: 3,
      }, */
    ],
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 600,
          },
          chartOptions: {
            legend: {
              layout: 'horizontal',
              align: 'center',
              verticalAlign: 'bottom',
            },
          },
        },
      ],
    },
  };

  return (
    <div style={{ background: 'white', borderRadius: '15px' }}>
      <div
        style={{
          marginTop: '10px',
          zIndex: 3,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
          flexGrow: 2,
        }}>
        <span
          style={{
            fontSize: '18px',
            fontFamily: 'Open Sans',
            fontWeight: '600',
            color: '#130834',
          }}>
          Net Zero Targets By {orgTargets?.length < 2 ? orgTarget?.targetYear : ''}
        </span>
        <div style={{ display: orgTargets?.length > 1 ? 'flex' : 'none' }}>
          <Select
            styles={{
              container: (provided) => ({
                ...provided,
                float: 'right',
                marginLeft: 0,
                paddingBottom: 0,
              }),
              control: (base) => ({
                ...base,
                height: '35.69px',
                minHeight: 30,
                width: 72,
                borderColor: 'white',
                fontWeight: '600',
                fontSize: '17px',
                boxShadow: 0, // Customize focus border color
                '&:hover': {
                  borderColor: 'white', // Customize hover border color
                },
                fontFamily: 'Open Sans',
              }),
              placeholder: (defaultStyles) => {
                return {
                  ...defaultStyles,
                  fontFamily: 'inherit',
                };
              },
              indicatorsContainer: (provided) => ({
                ...provided,
                padding: 0, // Removes the space between the value and arrow
                borderColor: 'white',
              }),
              indicatorSeparator: (provided) => ({
                ...provided,
                display: 'none',
              }),
              dropdownIndicator: (provided) => ({
                ...provided,
                padding: 0,
              }),
              valueContainer: (provided, state) => ({
                ...provided,
                padding: '2px 5px',
              }),
            }}
            options={targetOptions}
            isSearchable={false}
            onChange={(selected) => {
              setOrgTarget(orgTargets?.find((o) => o?.id === selected?.value));
            }}
            value={targetOptions?.find((option) => option.value === orgTarget?.id)}
          />
        </div>
      </div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    multiYearData: state.dashboard?.data?.multiYear,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(NetZeroChart);
